import styled from 'styled-components'

const Rw = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    position: relative;
`;

export const StyledFlexRow = styled.div`
    display: flex;
    
    align-items: center;
    justify-content: center;

    img {
        width: 100px;
        height: auto;
    }

    div {
        padding: 5px;
        position: relative;
    }
`;

export const RelativeImageWrapper = styled.div`
    padding: 7px 3px 3px 6px;
    height: 60px;
    width: 60px;
    display: block;
    position: relative;

`;

export default Rw;