import styled from 'styled-components'

export const ScrollCard = styled.div`
    width: 100%;
    position: relative;
    top:0;
    height: auto;
    max-height: ${props => props.height ? `${props.height}px` : `100%`};
    overflow-y: auto;
    margin-bottom: 15px;
    flex-grow: 1;


    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 6px;
      background-color: #F5F5F5;
    }
    
    &::-webkit-scrollbar
    {
      width: 8px;
      background-color: white;
    }
    
    &::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #86888B;
    }
`;

/**
 * Chat and notification. Support prop right to indicate left side.
 * Defaults to left;
 */
export const Bubble = styled.div`
    margin: 0px 3px 5px 3px;
    padding: ${props => props.right ? '0px 2px 0px 16px' : '0px 16px 0px 2px'};
    text-align: ${props => props.right ? 'right' : 'left'};
    
    
    div {
      border-radius: ${props => props.right ? '10px 10px 1px 10px' : '10px 10px 10px 1px' };
      color: #707070;
      display: inline-block;
      padding: 2px 5px;
      margin: 0;
      ${props => props.minWidth ? 'min-width: ' + props.minWidth : null };
      background-color: ${props => props.bg ? props.bg : props.right ? '#E4DADA' : '#D4D4D4'};
      border: ${props => props.emphasize ? `solid 2px #707070` : `solid 1px #707070`};

      p {
        font-size: calc(2px + 1vmax);
        display: block;
        word-wrap: normal;
        word-break: normal;
        text-align: left;
        margin: 3px;

        font-family: 'Roboto', sans-serif;
      }

      img {
        ${props => !props.contain ? props.right ? 'float: left;' : 'float: right;' : ""}
        border: solid 2px #707070;
        margin: 2px 5px 2px 1px;
        padding: 0;
        display: block;
      }

      button {
        float: ${props => props.right ? 'right' : 'left'};
        background-color: #D2D7DC;
        color: #545555;
        border-radius: 25%;
        border: 2px #707070 solid;
        padding: 5px;
      }
    }
`;