import styled from 'styled-components';


export const NotificationWrapper = styled.div`
  display: flex;
  background-color: #D4D4D4;
  border-radius: 10px;
  max-width: 400px;

`;

  
export const FeedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 90%;
  max-width: 768px;
  margin: 150px auto 0 auto;

  @media (max-width: 600px) {
    width: 100%;
  }
    
  `;