import React, {useState, useEffect} from 'react'
import styled, { useTheme } from 'styled-components'
import { ConfigurableButton } from 'lovemire-components'

// images
import GiftOutline from '../../../images/likes/gift-outline.png'
import Gift from '../../../images/likes/gift.png'
import HeartOutline from '../../../images/likes/myheart-outline.png'
import Heart from '../../../images/likes/myheart.png'
import ThumbsUpOutline from '../../../images/likes/thumbsup-outline.png'
import ThumbsUp from '../../../images/likes/thumbsup.png'

const StyledFlexBar = styled.div`
    display: flex;
    width: 100%;
    align-items: stretch;

    button {
        flex: 1 0 33%;
        text-align: center;
        padding: 2px 0;
        outline: none;
        border: none;
    }

    img {
        height: 65%;
        width: auto;
    }
`

const StyledSecretBar = styled.div`
    height: 60px;
    width: 100%;
    text-align: center;
    font-size: 1em;
    color: black;
`

const StyledImg = styled.img`

    user-select: none;
`

const LIKETYPES = ['free', 'double-admire', 'max-like']
const ButtonChildren = {
    'free': <StyledImg src={ThumbsUpOutline} />,
    'double-admire': <StyledImg src={HeartOutline} />,
    'max-like': <StyledImg src={GiftOutline} />
}

const SelectedChildren = {
    'free': <StyledImg src={ThumbsUp} />,
    'double-admire': <StyledImg src={Heart} />,
    'max-like': <StyledImg src={Gift} />
}

const SelectedBy = {
    'free': {        
    },
    'double-admire': {
        hold: 2, doubleClick: true, unSelectedable: false
    },
    'max-like': {
        hold: 2, doubleClick: true, unSelectedable: false
    }
}


const HoldLike = (props) =>  {

    const findNameIndex = (type, arr) => arr.findIndex( e => e.name === type)
    const theme = useTheme()

    const LikeButtons = LIKETYPES.map ( type => <ConfigurableButton
        key={type}
        style={ findNameIndex(type,props.current) !== -1 ?
            {backgroundColor: theme.colors?.likeBar} : {}}
        selected={ findNameIndex(type,props.available) === -1 }
        onSelected={ () => props.onSelected([],
            props.available.slice(0,findNameIndex(type,props.available)+1)
            ) }
        onUnSelect={ () => props.onSelected(props.current.slice(findNameIndex(type,props.current)))}
        selectedBy={ SelectedBy[type] }
        onSelectedChildren={SelectedChildren[type]}
        >

        {ButtonChildren[type]}

    </ConfigurableButton>)

    return <StyledFlexBar>
        {props.hidden && <StyledSecretBar>Secret</StyledSecretBar>}
        {!props.hidden && LikeButtons}
       </StyledFlexBar>
}

export default HoldLike