import styled from 'styled-components'

export const OkButton = styled.button`
    border: 2px #707070 solid;
    background-color: #CAB5B5;
    border-radius: 10px;
    font-size: 0.6em;
    font-family: 'Roboto', serif;
    font-weight: bold;
    color: #707070;
    padding: 8px 35px;
`;