const abstract = {
    primary: "#ebe6e6",
    secondary: "#707070",
    test: 'purple',
    light: "#CAB5B5",
    gold: '#FFE498'
}

export const Standard =  {
    colors: {
        // abstract
        primary: "#ebe6e6",
        secondary: "#707070",
        test: 'purple',
        gold: abstract.gold,
  
        // specific
        background: "#e8e8e8",
        secondaryBackground: "#f5f5f5",
        title: "#707070",
        button: "#CAB5B5",
        buttonSelected: "white",
        p: "#86888B",
        selected: "#cab5b5",
        unselected: "#707070",
        likeBar: 'rgba(202, 181, 181,0.32)',
        selectedHighlight: "#c9a5a5",
        unselectedHighlight: "white",
  
        // per element
        //inputBackground: this.colors.primary
    },
    borders: {
        primary: "2px #ebe6e6 solid",
        secondary: "1px #86888B solid",
        navButton: "2px gray solid",
        button: "2px gray outset",
        expandableMenu: "2px #707070 solid"
    },
    fonts: {
        title: "'Merriweather',serif",
        titleScript: "Ink Free, serif",
        subtitle: "'Segoe UI','Roboto',serif",
        bio: "'Roboto',serif",
        button: "'Roboto',serif",
        p: "'Roboto', serif"
    }
  }