import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButton = styled.button`
  margin: auto;
  border: #707070 2px solid;
  border-radius: 15px;
  background-color: #E4DADA;
  padding: 10px 65px;
  font-family: 'Roboto',serif;
  font-size: 1em;
  color: #707070;
  letter-spacing: 1px;
`

const ConnectButton = (props) => {

    return <StyledButton onClick={props.onClick}>
        CONNECT!
    </StyledButton>
}

export default ConnectButton