import React, { useState, useEffect } from 'react';
import ProfileBar from '../Profile/ProfileBar'
import PropTypes from 'prop-types';
import loveMireApi from '../lib/loveMireApi'
import PostMedia from './media/PostMedia'
import LikeButton from './media/LikeButton'
import LikeTypes from './media/data/LikeTypes'
import ToolTip from '../tooltip/tooltip'
import {getMediaLikes} from '../lib/LikesApi'
import {addLike, deleteLike} from '../lib/ConnectionsApi'
import { HideButton } from '../styles/Buttons';
import { Bubble } from '../styles/Cards';
import { PostFooter } from '../styles/feed/Cards'
import { toHowOld } from '../lib/TextLib'
import { FeedWrapper, PostWrapper } from '../styles/feed/Wrappers'
import { pickDims } from './media/bin/Media';
import { getFeed } from '../lib/FeedApi'
import FullModal from '../styles/Modals';
import PostMediaWithFind from '../MyMatches/components/PostMediaWithFind'
import HowOldLive from '../components/HowOldLive';
import {MatchesContext, DataContext, StateContext} from '../App'


/**
 * Main feed state management.
 * 
 * TODOS:
 * 
 * 1. limit pass down of media likes and my media likes to postMedia module ?
 * 2. Hide Api (updateMatches)
 * 3. Encapsulate styles and lib
 * 
 * @param {*} props 
 * @returns 
 */
const F = (props) => {

    // matches library
    const matches = React.useContext(MatchesContext)
    const [account,appSettings] = React.useContext(DataContext)
    const {profiles, connectionsList, myMatches} = React.useContext(StateContext)
    


    const [loading, setLoading] = useState(true);

    const [feed, setFeed] = useState([])    // this is the main structure and stores all data
                                            // passed down, used for render updates

    //const [profiles, setProfiles] = useState([])
    //const [profilesLoading, setProfilesLoading] = useState(true)



    const [mediaLikes, setMediaLikes] = useState([])
    const [myMediaLikes, setMyMediaLikes] = useState([])

    // tool tips
    const [tipName, setTipName] = useState("")

    // Full Modal (when profile thumb clicked)
    const [showPostMediaModal, setShowPostMediaModal] = useState(false)
    const [modalPostId, setModalPostId] = useState(null)

    const [mySettings, setMySettings] = useState({})        // preferences used for Post Request

    const [postRequest, setPostRequest] = useState({})

    let postRequestKeys = ["findByUsername", 
                        "ageMin", 
                        "ageMax", 
                        "lat", "lng", 
                        "addressString", 
                        "gender", 
                        "interestedIn"]

    /**
     * Makes an API call to get individual profiles.
     * 
     * This is called after the list of posts has been retrieved. Called
     * asynchronously with other data.
     * 
     * Individual Profiles include name, age, traits of the user who
     * posted. Front end will have a semi-profile on top of each post
     * to faciliate easy interaction. 
     * 
     * @param {*} profileList 
     
    const getProfiles = async (profileList) => {
        console.log("call getProfiles", profileList)
        if ( profileList.length > 0 ) {

            const profileString =
                profileList.join(",") // map to string csv
                //.replace(/,\s*$/, "")            // remove trailing comma

            // TODO: avoid reloading same profiles?

            console.log(profileString)
            loveMireApi.getJson('profiles', profileString)
                .then ( (response) => {
                    setProfiles(response)
                    setProfilesLoading(false)
                })
                .catch ( e => console.log(e) )
        }
    }*/

    /**
     * Called when the user pressed the like button.
     * The media Id and the type of like must be passed.
     * 
     * Since this is a cost associated function, some decisions will need
     * to be made about the handling.
     * 
     * Event Driven. Payment Service puts an event out. Front end updates
     * through websocket or polling.
     * 
     * @param {long} mediaId 
     * @param {String} likeType 
     */
    const postLike = (mediaId, likeType) => {

        const verifyLikeType = (lt) => {
            if (lt === 'none') return false
            let type = LikeTypes.filter ( l => l.name === lt)[0]

            if (type === undefined) return false

            return true
        }
      
        console.log("Post Like: ", mediaId, likeType)

        let likeTypeArgument = ""

        if (Array.isArray(likeType)) {

            let likeTypes = likeType.map ( lt => lt.name ).filter(lt => verifyLikeType(lt))
            if (likeTypes.length > 0) {
                let highestLike = likeTypes[likeTypes.length - 1]
                setTipName(`${highestLike.replace(/-/g,'_').replace("free","free_like")}`)
                likeTypeArgument = likeTypes.join(",")
            }


        } else {

            if(verifyLikeType(likeType)) {
                setTipName(`${likeType.replace(/-/g,'_').replace("free","free_like")}`)
                likeTypeArgument = likeType
            }

        }

        if(likeTypeArgument.length > 0) {

        loveMireApi.postJson('postLike', {}, `${mediaId}/${likeTypeArgument}`)
            .then ( (response) => {
                // This should be a 200 code which means we received a new like
                setFeed ( prevFeed => {
                    let newFeed = prevFeed.slice()

                    newFeed.forEach( post => {
                        post.mediaList.forEach( media => {
                            if ( media.mediaId === mediaId ) {
                                // add to userLike
                                try {
                                    media.user_likes = media.user_likes.concat(response)
                                } catch (e) {appSettings.error({error: e,response: response,post: post})}
                            }
                        })
                    })

                    return newFeed;
                })

            })
            .catch( e => {

                if (!check409(e)) {
                    appSettings.error(e)
                }

            })
        }

    }

    /**
     * Structurally same as postLike, need to DRY
     * @param {*} mediaId 
     * @param {*} likeType 
     * @returns 
     */
    const postDeleteLike = (mediaId, likeType) => {

        const verifyLikeType = (lt) => {
            if (lt === 'none') return false
            let type = LikeTypes.filter ( l => l.name === lt)[0]

            if (type === undefined) return false

            return true
        }

        //console.log("Delete Like: ", mediaId, likeType)

        let likeTypes = []
        let likeTypesArgument = ""

        if (Array.isArray(likeType)) {
            likeTypes = likeType.map( lt => lt.name).filter( lt => verifyLikeType(lt))
            likeTypesArgument = likeTypes.join(",")
            //console.log(likeTypes, likeType, LikeTypes)
        } else {

            if (!verifyLikeType(likeType)) return;
            likeTypesArgument = likeType;
            likeTypes = [likeType]
            //console.log(likeTypesArgument)
        }

        loveMireApi.deletePath('postLike', `${mediaId}/${likeTypesArgument}`)
            .then ( (response) => {
                // This should be a 200 code 
                console.log("found it", `${mediaId}/${likeTypesArgument}`, response)
                setFeed ( prevFeed => {
                    let newFeed = prevFeed.slice()

                    newFeed.forEach( post => {
                        post.mediaList.forEach( media => {
                            if ( media.mediaId === mediaId ) {
                                
                                media.user_likes = media.user_likes.filter (ul => {
                                    return !likeTypes.includes(ul.likeType.name)}
                                )
                            }
                        })
                    })

                    return newFeed;
                })

            })
            .catch( e => {
                if (!check409(e, "Likes Locked, hidden image viewed?")) {
                    console.log("caught 409")
                    appSettings.error(e)
                }
            })

    }

    const check409 = (e, message = "Server Responded Conflict: Reloading Likes") => {
        // Check for 409, if 409, reload fresh likes and give message of error and reloading
        if ( e.response && e.response.status === 409) {
        
            appSettings.message(message)

            getMediaLikes(feed)
                .then( newFeed => setFeed(newFeed))
            return true
        }
        return false;
    }

    /**
     * Passed from PostRequest. When user updates his search preferences, need
     * to change his settings.
     * 
     * Updating settings will fire the postRequest useEffect (if changes made)
     * which will cause a feed reload
     * 
     * @param {Object} newPostRequest 
     */
    const updatePostRequest = (newPostRequest) => {

        console.log(newPostRequest)
        matches.updateSettings(newPostRequest)
        matches.apiPutSettings()  // can't pass it here since may not be full settings

    }

    /**
     * Main feed retrieval. Hits only the post service.
     * After feed is retrieved, loading variable will fire a useeffect
     * for additional data.
     * 
     * This will be called once search preferences has been added
     */
    const callGetFeed = () => {
  
      // temporary only use ageMin and ageMax since post-service isn't updated
      //let payload = ( ({ageMin, ageMax, gender, interestedIn}) =>
      //   ({ageMin, ageMax, gender, interestedIn}))(postRequest)
      let payload = ( ({...postRequestKeys}) => ({...postRequestKeys}) )(postRequest)
      getFeed(payload)
        .then ( (response) => {
          setFeed(response)
          setLoading(false)
          console.log("%c FEED RESPONSE:", "color: purple" ,response, payload)

        })
        .catch ( e => console.log(e))
  
    }

    /**
     * updatePostRequest methods are fired when new data is retrieved that requires a new
     * feed request. Usually this will be on retriving a new mySettings.
     * 
     * If loading a single username feed for the profile. the postRequest info is passed
     * from props.
     * @returns bool
     */

    const updatePostRequestFromMySettings = () => {

        let updated = false;

        // filters mysettings to only use values listed in postRequestKeys
        Object.keys(mySettings).filter( k => postRequestKeys.includes(k) ).forEach ( k => {
           
            if( !(k in postRequest) || mySettings[k] !== postRequest[k]) {
                updated = true;
            }
        })

        if (updated) {
            setPostRequest( prev => {
                let newP = {...prev}
                Object.keys(mySettings).filter(k => postRequestKeys.includes(k) ).forEach ( k => {
                    newP[k] = mySettings[k]
                })
                console.log("%c UPDATING POSTREQUEST: ", "color: purple", newP, mySettings)
                return newP
            })
        }

        return updated;
    }

    const updatePostRequestFromSingle = () => {

        setPostRequest({"findByUsername": props.showSingle ? props.showSingle : ""})


    }
  
    /**
     * Received new Props
     * 
     * Loading is then used for additional api calls
     * 
     * (*) Set Loading
     * 
     */
    useEffect(() => {
        setLoading(true);
        console.log("Feed mounted")
        
        matches.registerSettingsCallBack( s => setMySettings(s), "feed")
        //callGetFeed();  ran by postRequest useEffect
        matches.apiGetSettings()

        return () => {
            matches.unRegisterSettingsCallBack("feed")
        }
    }, [])

    useEffect( () => {
        console.log("Feed received new Props", props)
    }, [props])

    useEffect( () => {
        //console.log("Updating account")
        matches.apiGetAccount()
    }, [feed])

    useEffect( () => {

        if (Object.keys(postRequest).length > 0) {

            setLoading(true)
            callGetFeed()
        }

    }, [postRequest])

    useEffect ( () => {

        // if mySettings is updated, it is either from PostRequest callback
        // or api. Either way, shouldn't update postRequest unless necessary
        // as it will cause a feed reload
        if (!props.showSingle) {
            updatePostRequestFromMySettings()
        } else {
            updatePostRequestFromSingle()
        }

    }, [mySettings])

    /**
     * useEffect for when feed loading has ended.
     * This populates the feed with additional data:
     * 
     * (*) Profiles
     * (*) Media Likes
     * 
     */
    useEffect(() => {
        if ( !loading && !!feed && feed.length > 0) {

            let profilesList = Array.from(new Set (feed.map ( f => f.account.username) ) );
            // Call get profiles to load the profiles into a different array
            //getProfiles(profilesList)

            // Updates Profile State
            matches.setProfilesArray(profilesList)
            matches.apiGetProfiles()
            
            // updates connection state
            matches.setProfilesList(profilesList)
            matches.apiGetConnections()
            matches.apiGetNotificationsList()

            // Updates media like state
            getMediaLikes(feed)
                .then( newFeed => setFeed(newFeed))

            // Using seperate state for matches. As adding to profile state would require synchronous request
            //getConnectionsReduced(profilesList)
            //    .then( matches => setMyMatches(matches))

        }
    }, [loading])

    useEffect( () => {

        // allows unseen messages to show on ProfileBar
        matches.apiGetNewMessagesList()
        
    },[connectionsList])

    const hidePost = (postId) => {

        const hideApi = (toggle) => {
            if (toggle) {
                loveMireApi.postJson('hide',{},postId)
                    .then ( response => console.log("Hidden: ", postId))
                    .catch ( e => console.log(e, e.response))
            } else {
                loveMireApi.deletePath('hide',postId)
                .then ( response => console.log("UnHidden: ", postId))
                .catch ( e => console.log(e, e.response))                    
            }
        }

        // set feed to hidden
        let newFeed = feed.map ( p => {
            if (p.id === postId) {
                // found post, now check hidden
                if( p.hidden === undefined) {
                    p['hidden'] = true;
                    hideApi(true)
                } else {
                    delete p['hidden']
                    hideApi(false)
                }
            }
            return p;
        })

        setFeed(newFeed)

    }



    //console.log(mySettings, postRequest, updatePostRequestFromMySettings())
    //console.log(feed, appSettings)
    /* START RENDERS
     */
    if (loading) {
        // Still loading Feed

        return (
        
            <div>
                <p>Greetings from Feed!</p>
                <p>Working on it!</p>
            </div>
        )
    } else {
        // Display Feed
        return (
            <FeedWrapper marginTop={ props.showSingle ? "10px" : undefined}>
                {showPostMediaModal && modalPostId &&
                <FullModal onClick={() => {setShowPostMediaModal(false);}}>
                    <PostMediaWithFind 
                        postId={modalPostId}
                        matches={matches} />
                    <HideButton onClick={() => setShowPostMediaModal(false)} />
                </FullModal>}
                
                {/*<PostRequest postRequest={postRequest}
                    disabled={loading}
                    handleSubmit={(p) => updatePostRequest(p)} />*/}
                <div>
                {feed.map( (p,i) => { 

                    // Media object (sometimes as child)
                    let postMedia = 
                        <PostMedia mediaList={p.mediaList}
                            self={p.account.username === matches.getUser()}
                            profile={profiles.find( pf => pf['username'] === p.account.username)}
                            dimensions={p.dimensions}
                            onLike={ (id, t) => postLike(id, t) }
                            onDelete={ (id, t) => postDeleteLike(id, t) } >
                                <HideButton onClick={ () => hidePost(p.id)} />
                        </PostMedia>

                    return (
                    <PostWrapper key={p.id} >
                    {!p.hidden && !props.showSingle && <ProfileBar 
                            dimensions={pickDims(p.dimensions)}
                            onMediaClick={(id) => {setShowPostMediaModal(true); setModalPostId(id);}}
                            
                            profile={profiles.find( pf => pf['username'] === p.account.username)}
                            myMatches={matches.findMatches(p.account.username, myMatches)}>
                        {postMedia}                           
                        </ProfileBar>}
                    {!p.hidden && props.showSingle && postMedia}
                    {!p.hidden && <PostFooter>
                        <PostFooter.Script>{!!p.addressString && p.addressString + ", "}<HowOldLive now={p.created} /></PostFooter.Script>
                        <PostFooter.P>{!!p.description && p.description}{"\"Description.\""}</PostFooter.P>
                    </PostFooter>}

                    {p.hidden && <Bubble right>
                        <div style={{padding: "20px"}}>
                            <p>Post Hidden.
                            <button onClick={ () => hidePost(p.id)}>Oops</button>
                            </p>                            
                        </div>
                                            
                    </Bubble>}
                    </PostWrapper>)
                })}
                </div>
            </FeedWrapper>
        )
    }
}

export default F;

F.propTypes = {
}