import styled from 'styled-components'

export const NewMessagesDiv = styled.div`
    background-color: rgba(166, 129, 129);
    display: inline-block;
    position: absolute;
    top: 0px;
    right: 0px;
    border: 1px solid black;
    border-radius: 5px;

    p {
        font-size: 0.6em;
        font-weight: bold;
        color: white;
        padding: 2px;
        margin: 1px;
    }
`;

export const NewChatMessagesDiv = styled.div`
    background-color: rgba(252, 248, 242);
    display: inline-block;
    position: absolute;
    bottom: 0px;
    right: 0px;
    border: 1px solid black;
    border-radius: 5px 5px 0 5px;

    p {
        font-size: 0.6em;
        font-weight: bold;
        color: black;
        padding: 2px 5px;
        margin: 1px;
    }

`;

export const ProfileNewMessagesDiv = styled.div`
    background-color: rgba(166, 129, 129);
    display: inline-block;
    border: 1px solid black;
    border-radius: 5px;
    margin: auto 3px;
    padding: 0;

    p {
        font-size: 0.6em;
        font-weight: bold;
        color: white;
        padding: 2px;
        margin: 1px;
    }

    span {
        font-size: 0.6em;
        font-weight: bold;
        padding: 3px;
        margin: 1px;
        display: block;
        text-align: center;
    }
`;

export const ProfileNewChatMessagesDiv = styled.div`
    background-color: rgba(252, 248, 242);
    display: inline-block;
    border: 1px solid black;
    border-radius: 5px 5px 0 5px;
    margin: auto 3px;
    padding: 0;
    
    p {
        font-size: 0.6em;
        font-weight: bold;
        color: black;
        padding: 2px 5px;
        margin: 1px;
    }

    span {
        font-size: 0.6em;
        font-weight: bold;
        padding: 3px;
        margin: 1px;
        color: black;
        display: block;
        text-align: center;
    }
`;