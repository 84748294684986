import loveMireApi from "../../../lib/loveMireApi"

const DEFAULTCROP = {"unit": "%", "x": 0, "y": 0, "width": 100, "height": 100}
const DEFAULTMEDIA = {}

/**
 * Must be passed a file. File can be URL. If media object is not supplied,
 * will create new media
 *
 * previewData must be blob already (TODO: fix this)
 * 
 * @param {any} file
 * @param {media} object
 * @param {crop} object
 * @param {previewData} object
 * @returns Promise
 */
export const apiSaveMedia = async (file,
        media = DEFAULTMEDIA, crop = DEFAULTCROP, previewData) => {

    console.log("apiSaveNewMedia called: ")

    // must have at least file or media+previewData
    if (!!file || (!!media && !!previewData)) {

        // If this method is supplied an object, it will use that to create payload

        let submitMedia = media;

        let request
        let formData = new FormData();

        // main media file
        // payload must have media or preview
        if (!!file) {

            if ( typeof file === 'string' && file.startsWith('http')) {
                // blob, probably object
                formData.append('file', new Blob( [await fetch(file).then(r=>r.blob())], {type: 'image/jpeg'}),'media' );
            } else {
                // must be file
                formData.append('file', new Blob( [file] , {type: 'image/jpeg'}),'media' )
            }
        }

        // preview data 
        if (!!previewData) {
            formData.append('preview', previewData, 'media');/*new Blob( [await fetch(previewData).then(r=>r.blob())]{type: 'image/jpeg'})*/
        }

        // crop, default crop is full image
        formData.append('crop', JSON.stringify(crop))
        
        // add media
        // if new media, use POST, update media, PUT
        formData.append('media', JSON.stringify(submitMedia))
        if ( !!submitMedia && submitMedia.mediaId > 0) {            
            request = loveMireApi.putForm('postMedia', formData)
        } else {
            request = loveMireApi.postForm('postMedia', formData)
        }
        
        return request
                .then ( response => {

                    response.mediaId = response.id;

                    // Preserves any extra data in submitMedia
                    return {...submitMedia,...response}

                })
         

    } // if check for truthy

} // End apiSaveMedia


/**
 * Expects media object from post.mediaList, must have mediaId
 * and dataId. Makes asynchronous call to media and data services
 * to build complete media object.
 * 
 * @param {*} media 
 * @returns 
 */
export const apiGetMedia = ( media ) => {

    if (!media.mediaId || !media.dataId) {
        throw new Error("MediaApi: Incomplete media object")
    }

    return Promise.all ( [
        loveMireApi.getJson('postMedia', media.mediaId)
            .then ( response => {
                return {...media,...response}
            }),
        loveMireApi.getPic('mediaData', media.dataId)
            .then ( response => {
                return {
                    file: response,
                    img: URL.createObjectURL(response)
                }
            })
    ]).then( ([m,f]) => {return {...m[0],...f,...media}})

}