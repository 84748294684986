import React, {useCallback} from 'react'
import styled from 'styled-components'
import {useDropzone} from 'react-dropzone';
import { getOrientation } from 'get-orientation'
import { SelectButton, HideButton } from '../../styles/Buttons';
import { getRotatedImage, getCroppedImg } from './CanvasUtils';
import {SelectButton as SelectButton2 } from 'lovemire-components'
// Profile context
import { PostUpdateContext } from './EditPost';
import PostMedia from '../../feed/media/PostMedia';

const MAX_UPLOAD_LENGTH = 20

const ORIENTATION_TO_ANGLE = {
    '4': 180,
    '3': 180,
    '6': 90,
    '8': -90,
    '7': 90,
    '5': -90
  }

const StyledWrapper = styled.div`
    width: 100%;
    max-width: 260px;
    box-sizing: border-box;
    margin: 5px auto;
    padding: 20px;
    display: block;
    position: relative;
    background-color: #f8f8ff;

`;


/**
 * Handles initial upload of files. Can accept multiple files.
 * 
 * 5/12/2022 - Goal is to refactor code so that images are never replaced
 * within ImageEdit / CropImage. The user can only delete and upload new
 * with this component.
 * 
 * @param {*} props 
 * @returns 
 */
const AddMedia = (props) => {

    const postUpdate = React.useContext(PostUpdateContext)

    /**
     * DROP ZONE
     * https://github.com/react-dropzone/react-dropzone
     * 
     */
    const onDrop = useCallback(acceptedFiles => {

        console.log("Drop initiated  on "+ acceptedFiles.length + " files.")

        if ( acceptedFiles.length > MAX_UPLOAD_LENGTH) {
            // TODO: error message
            console.log("WARNING: Upload length over maximum of " + MAX_UPLOAD_LENGTH)
            acceptedFiles.length = MAX_UPLOAD_LENGTH
        }

        acceptedFiles = acceptedFiles.map ( (file,i) => {
        
        // apply rotation if needed

            // TODO: Here we get all the EXIF data (to pass location up)
            return getOrientation(file)
                .then ( async orientation => {
                    const rotation = ORIENTATION_TO_ANGLE[orientation]                    
                    console.log(orientation, rotation)
                    if (rotation) {

                        let ni = await getRotatedImage(URL.createObjectURL(file), rotation)                
                        console.log("updating rotated")
                        return ni
                    } else {
                        console.log("Updating non-rotated", file)
                       return file
                    }

                })
        
        })

        Promise.all(acceptedFiles)
            .then ( f => postUpdate.addMedia(f))

        }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop: onDrop, accept: 'image/jpeg, image/png'})

    /***
     * End DROP ZONE
     */
    

    return <div style={{margin: 'auto'}} {...getRootProps()}>
            <input {...getInputProps()} />
            {
                <StyledWrapper>
                    { isDragActive ? <p>Drop here</p> :
                    <SelectButton2 noCheckBox><p><b>Add Pics</b></p>
                    <p>Drag 'n' drop, or click to select</p></SelectButton2> }
                </StyledWrapper>
            }
        </div>
}

export default AddMedia