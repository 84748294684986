// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app"
import {getMessaging, getToken} from 'firebase/messaging'
import loveMireApi from "../lib/loveMireApi";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDd0sO8dvBP5wNHd0ORobsQw9kjPr-7b3w",
  authDomain: "project-lovemire.firebaseapp.com",
  projectId: "project-lovemire",
  storageBucket: "project-lovemire.appspot.com",
  messagingSenderId: "1079404190726",
  appId: "1:1079404190726:web:8f5280c60778cab96a7787"
};

const key = 'BAYaX-iZ2tv0w4ei0HsCbXkf7EhHgRQxiN44UyPrEuDvmSNiv0L489zTJWBTIRwFVBF9s0TxoxcRDr-Bhto_sdg'
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const messaging = getMessaging();



getToken(messaging, { vapidKey: key }).then((currentToken) => {
  if (currentToken) {
    console.log("Registering Push Notifications: ", currentToken)

    loveMireApi.postJson('pushToken', {}, currentToken)
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});

