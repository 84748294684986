import loveMireApi from './loveMireApi'

/**
 * Returns a new media list with the like counts applied. Does not ever expect a 0 lenght mediaList
 * @param {} ml 
 */
export const getMediaLikes = (pl) => {
    let postList = [...pl] // make copy so caller doesn't have to
    console.log("call LikesApi.getMediaLikes", postList);

    if ( postList.length > 0) {
        const postString = postList.map( f => f.mediaList.map (ml => ml.mediaId))
            .join(",")

    return loveMireApi.getJson("mediaLikes", postString)
        .then( (response) => {
            if ( !!response['like_counts'] && !!response['user_likes']) {
                postList.forEach ( post => {
                post.mediaList && post.mediaList.forEach ( media => {
                    let likeData = response['like_counts'].find ( lc => lc.id === media.mediaId)
                    let userLikeData = response['user_likes'].filter ( ul => ul.mediaId === media.mediaId)
                    media['likeCount'] = Object.is(likeData,undefined) ? 0 : likeData.count;
                    media['likeCost'] = Object.is(likeData,undefined) ? 0 : likeData.cost;
                    if(response['hidden_media'].includes(media.mediaId)) media['hidden'] = true
                    if ( userLikeData.length > 0) media['user_likes'] = userLikeData;
                    else media['user_likes'] = []
                })})
            }

            return postList
        }).catch(e => console.log(e.response))
    } else {
        return Promise.resolve([])
    }
}

export const getHiddenViews = (dataId) => {

    return loveMireApi.getJson("hiddenViews", dataId)
        .then ( (response) => parseInt(response['message']))
}