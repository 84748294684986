import loveMireApi from './loveMireApi'

export const getConnectionsReduced = (potentials) => {

    if (!Array.isArray(potentials)) {
        potentials = potentials.split(",")
    }

    return loveMireApi.postJson('getConnectionsReduced', {potentials: potentials})
            .then ( response => (({ to, from}) => ({to, from}))(response) )
            .catch( e => console.log(e.response))

}

export const addLike = async (username) => {
    console.log(username)
    return loveMireApi.postJson('addLike', {}, username)
        .then ( response => username)
        .catch( e => console.log(e.response))

}

export const deleteLike = async (username) => {
    return loveMireApi.deletePath('addLike', username)
        .then ( response => username)
        .catch( e => console.log(e.response))
}