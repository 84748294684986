import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import loveMireApi from '../lib/loveMireApi';
import Feed from '../feed/Feed'

import Profile from './Profile'
import Flirt from './Flirt'
import Menu from './Menu'

/**
 * If this container is opened, the user has expanded the profilebar on top of
 * either a post in the feed or a match in MyMatches.
 * 
 * @param {object} props 
 * @returns 
 */
const ProfileContainer = (props) => {

    const [selection, setSelection] = useState( props.defaultSelection ? props.defaultSelection : "profile")
    const containerRef = useRef()

    useEffect( () => {
        // if we didn't get dimensions, we can scroll to top
        // This will eventually need to be updated to full screen when opened
        if(!props.dimensions || props.dimensions.length < 1) {
            window.scrollTo({ top:  -120 + window.pageYOffset + containerRef.current.getBoundingClientRect().top, left: 0,  behavior: 'smooth'})
        } else {
            console.log(props.dimensions)
        }

    },[])

    const menuSelections = [
        {
            name: "profile",
            title: "Profile",
            onClick: () => setSelection("profile"),
            content: <Profile {...props}/>
        },
        {
            name: "feed",
            title: "Feed",
            onClick: () => setSelection("feed"),
            content: 
            <div style={{overflow: "auto" /*feed always needs scroll, TODO: style/hide scroll bar*/}}> 
                <Feed showSingle={props.match ? props.match.name : ""} {...props} />
            </div>
        },
        {
            name: "flirt",
            title: "Flirt",
            onClick: () => setSelection("flirt"),
            content: <Flirt {...props} />
        }
    ]

    console.log(props)
    return ( /* This div is too big for here TODO */
        <div ref={containerRef} style={{
            height: props.fullScreen ? '100%' : (!!props.dimensions && !!props.dimensions[1]) ? `${props.dimensions[1] + 60}px` : `600px`,
            width: props.fullScreen ? '100%' : (!!props.dimensions && !!props.dimensions[0]) ? `${props.dimensions[0]}px` : `100%`,
            maxHeight: props.fullScreen ? '100%' : (!!props.dimensions && !!props.dimensions[1]) ? `${props.dimensions[1] + 60}px` : `100%`,
            display: 'flex', flexDirection: "column"}}>
        <Menu selections={menuSelections} selected={selection} />
        {menuSelections.find(e => e.name === selection).content}
    </div>)
}

export default ProfileContainer;

ProfileContainer.propTypes = {
    profile: PropTypes.object.isRequired,
    defaultSelection: PropTypes.string
}