import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import loveMireApi from '../lib/loveMireApi';
import {addLike} from '../lib/ConnectionsApi'
import { isCompositeComponent } from 'react-dom/test-utils';

import { ProfileFeedThumb, ProfileThumb, ProfileImageExpanded } from '../styles/profile/Images';
import { PostWrapper } from '../styles/post/Wrappers'
import { RightArrowButton, LeftArrowButton, DownArrowButton } from '../styles/Buttons'
import { RelativeImageWrapper } from '../styles/RelativeWrapper';
import { Verified, Developer } from '../styles/profile/Images'
import {ProfileNewMessagesDiv,ProfileNewChatMessagesDiv} from '../styles/Overlays'
import { useWindowSize } from '../lib/useWindowSize'

import OnlineStatus from '../MyMatches/components/OnlineStatus'

import ProfileContainer from './ProfileContainer'

import { StateContext } from '../App';

const StyledExpandableWrapper = styled.div`


    @media (max-width: 600px) {
        ${props => props.expanded ? `
            height: ${props.height? props.height : "100vh"};
            position: fixed;
            display: flex;
            flex-direction: column;
            top: 0;
            left: 0;
            z-index: 9;
            background-color: white;
            margin: auto 0;
            width: 100%;
        ` : ""}
    }

`

const StyledProfileBar = styled.div`
    width: 100%;
    font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    text-align: left;
    background-color: inherit;
    padding: 0;
    margin: 0;
    overflow: none; 
    display: flex;
    align-items: center;
    user-select: none;
    flex-grow: 0;
    ${props => props.expanded ? "" : `box-shadow: 2px 2px 5px 1px #777;`}    

    h2 {
        color: #707070;
        font-size: 1.2em;
        font-weight: normal;
        font-family: 'Segoe Print', serif;
        display: inline-block;
        margin: auto auto auto 15px;

        span {
            font-size: 0.5em;
            text-shadow: none;
            font-family: 'Segoe UI', 'Roboto', serif;
            margin-left: 10px;
            display: inline-block;
            color: #86888B;
        }
    }

    p {
        margin: 10px 10px 5px 10px;
        color: #282c34;
        font-size: 0.8em;
        font-weight: bolder;
        font-family: 'Roboto';
    }
 

    button {
    }

`

const ChildrenDiv = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    margin-left: 10px;
    height: 100%;
`

const ConnectionDiv = styled.div`
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    

    img {
        border: 1px solid #86888B;
        border-radius: 5px;
        object-fit: fill;
        height: 100%;
        width: 100%;
    }
`;

/**
 * Probably best component in here. Allows anything to be placed in expanded area.
 * 
 * Can use for a post or notifications?
 * 
 * @param {*} props 
 * @returns 
 */
const Pb = (props) => {

    //const matches = useContext(MatchesContext) not needed?
    const {onlineUsers, connectionsList, unSeenMessagesPerUser} = React.useContext(StateContext)

    const [expandedProfile, setExpandedProfile] = useState(false);
    const [contentExpanded, setContentExpanded] = useState(false)

    const [unSeenNotifications, setUnSeenNotifications] = useState(0)
    const [unSeenMessages, setUnSeenMessages] = useState(0)


    const windowSize = useWindowSize()

    const [profileImage, setProfileImage] = useState(null)

    const VERIFIED = ['UNVERIFIED', 'DIGITAL', 'IDENTIFICATION', 'FULL', 'PHYSICAL', 'DEVELOPER']

    const toggleExpandContent = (e) => {
        e.stopPropagation();
        if (contentExpanded) {
            // user wants all content closed
            setContentExpanded(false)
            setExpandedProfile(false)
            document.body.style.overflow = "auto"
        }
        else if (expandedProfile) {
            setExpandedProfile(false)
            document.body.style.overflow = "auto"
        } else {

            // User is expanding the content            
            // Where's my width 
            if (windowSize.width && windowSize.width < 601) {
                document.body.style.overflow = "hidden"
            }

            if(props.onExpand) props.onExpand()
            if (props.expandToProfile) {
                setExpandedProfile(true)
            } else {
                // not used for now (or ever again?)
                // this was used when the user could close and expand the post content
                //setContentExpanded(true)
                setExpandedProfile(true)
            }
        }
    }

    useEffect ( () => {

        /*
        Moved to Profile.js

        if (expandedProfile === true 
            && Object.keys(profilePostThumbs).length === 0 // haven't updated yet
            && props.profile.postLinks.length !== 0 // user has links
            ) {
            // do get for profile thumbs
            let postLinks = props.profile.postLinks.map( pl => pl.linkId).join(',')
            console.log(profilePostThumbs, props.profile, postLinks)
            loveMireApi.postJson( 'profileThumbs', {}, postLinks)
                .then ( (response) => {

                    if (response.length > 0) {
                        // get individual images
                        response.map ( post => {

                            loveMireApi.getPic('mediaData', post.mediaList[0].dataId)
                                .then ( pic => {
                                    setProfilePostThumbs ( prevData => {
                                        let newData = {...prevData}
                                        console.log("adding")
                                        newData[post.id] = URL.createObjectURL(pic);
                                        return newData;
                                    })
                                })
                                .catch(e => console.log(e.response))
                        })
                    }

                })
                .catch (e => console.log(e.response))
        } */

    }, [expandedProfile])

    /**
     * Component received new props, this is used to save profile image
     */
    useEffect ( () => {

        if ( !profileImage && !!props.profile && Object.keys(props.profile) !== 0) {

            loveMireApi.getPic('mediaData', props.profile.profileImageId)
                .then ( pic => {
                    setProfileImage(URL.createObjectURL(pic))
                })
                .catch(e => console.log(e.response, props.profile, props.profile.profileImageId))
        }
    }, [props])

    // updates new notification count
    useEffect ( () => {

        console.log("connectionsList useEffect: ")
        if (props.profile?.username) {
            let count = connectionsList.find ( e => e.name === props.profile.username)?.unseen

            if (count && count !== unSeenNotifications) {
                setUnSeenNotifications(count)
            }
        }

    }, [connectionsList])

    useEffect ( () => {

        // updates un seen messages which will cause a white bubble
        if (props.profile?.username && Object.keys(unSeenMessagesPerUser).includes(props.profile.username) &&
            unSeenMessagesPerUser[props.profile.username] !== unSeenMessages) {
                setUnSeenMessages(unSeenMessagesPerUser[props.profile.username])
        }

    }, [unSeenMessagesPerUser])
    
    let connectionStatus = {
        /*
         * These icons mess up the scroll on the ProfileBar. The whole profile image should be re engineered
         * to consider the icon. For now, i've put profilebar overflow to none */
        
        "NONE": {text: "",
            //xmark: <XMarkButton onClick={ () => console.log("Hide!")} />,
            //check: <CheckMarkButton onClick={ () => {
            //    props.likeApi(props.profile.username, 'LIKE')}} />,
            icon: <></>},
        "TO": {text: "Liked",
            //xmark: <XMarkButton onClick={ () => props.likeApi(props.profile.username, 'HIDE') } />,
            //check: <></>,
            icon: <img src="/images/match-sent.jpg" alt="Request Sent!" />},
        "FROM": {text: "Likes me",
            //xmark: <XMarkButton onClick={ () => console.log("Hide!")} />,
            //check: <CheckMarkButton onClick={ () => props.likeApi(props.profile.username, 'LIKE')} />,
            icon: <img src="/images/match-request.jpg" alt="Match Requested!" />},    
        "CONNECTION": {text: "Connected",
            //xmark: <XMarkButton onClick={ () => props.likeApi(props.profile.username, 'HIDE')} />,
            //check: <></>,
            icon: <img src="/images/connection.jpg" alt="Connected!" />}    
    }

    const InteractionButtons = 
        <>
            {connectionStatus[props.myMatches].xmark}
            {connectionStatus[props.myMatches].check}
        </>
    
    if ( !!props.profile && Object.keys(props.profile) !== 0 ) {

        return (
            <StyledExpandableWrapper height={windowSize.height + 'px'} expanded={ contentExpanded || expandedProfile}>
            <StyledProfileBar onClick={ toggleExpandContent} 
                expanded={!props.expandable || contentExpanded || expandedProfile}>
                <RelativeImageWrapper>
                    {profileImage && <ProfileThumb style={{cursor: "pointer"}}
                        src={profileImage} />}
                    {props.profileHeadChildren && props.profileHeadChildren.length > 0 &&
                        props.profileHeadChildren}
                    {!!props.profile && VERIFIED.findIndex( e => e === props.profile.verified) > 0 && 
                    (VERIFIED.findIndex( e => e === props.profile.verified) <= 3 ? <Verified />
                    : <Developer />)}
                    <ConnectionDiv>{connectionStatus[props.myMatches].icon}</ConnectionDiv>
                </RelativeImageWrapper>
                <h2><a style={{cursor: "pointer"}}
                    onClick={ () => setExpandedProfile(!expandedProfile)}>{props.profile.title}</a>
                    <span>{props.profile.addressString ? props.profile.addressString : 'World'}</span>
                </h2>
                    

                <ChildrenDiv>
                    <OnlineStatus {...onlineUsers[props.profile.username]} />
                    {unSeenNotifications > 0 && <ProfileNewMessagesDiv><span>
                        {`${unSeenNotifications}`}
                        </span></ProfileNewMessagesDiv>}
                    {unSeenMessages > 0 && <ProfileNewChatMessagesDiv><span>
                        {unSeenMessages}</span></ProfileNewChatMessagesDiv>}
                    {props.notificationChildren && props.notificationChildren.length > 0 && 
                        props.notificationChildren}
                </ChildrenDiv>
                {/*<p>{connectionStatus[props.myMatches].text}</p>*/}

                {props.expandable && <>
                    {(contentExpanded || expandedProfile) && <DownArrowButton
                        onClick={toggleExpandContent} />}
                    {(!contentExpanded && !expandedProfile) && <LeftArrowButton
                        onClick={toggleExpandContent} />}
                    <p></p>
                </>}
            </StyledProfileBar>

            {expandedProfile && 
            <PostWrapper dimensions={props.dimensions}>
                <ProfileContainer {...(({...p}) => 
                    ({fullScreen: contentExpanded || expandedProfile, ...p}))(props)} />
            </PostWrapper>
            }

            {!expandedProfile && (!props.expandable || contentExpanded) && props.children}
        </StyledExpandableWrapper>
        )
    } else { // loading
        return (
            <div>
                <StyledProfileBar>
                    <p>Loading Profile...</p>
                </StyledProfileBar>
                
                {props.children}
            </div>
        )
    }
}

export default Pb;

Pb.propTypes = {
}