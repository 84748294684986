import loveMireApi from '../lib/loveMireApi'
import {getConnectionsReduced, addLike, deleteLike} from '../lib/ConnectionsApi'
import {getNotificationRequest, notificationRequest, getFlirt,getUnseenMessagesPerUser} from '../lib/NotificationsApi'
import { getSettings, saveSettings } from '../lib/SettingsApi'

// TODO:    docallbacks should always be seperate
//          Many of them are inside api calls
//          Seperating them would allow setting and doing callbacks without api
class Matches {

    /**
     * 
     * @param {*} user 
     */
    constructor (user = "") {

        console.log("Constructing Matches Data Object")
        this.user = user;   // Username of user

        // These are used to set callbacks
        this.connections = []       // info on match matching
        this.profiles = []          // Profile info on various users. (Can get large)
        this.hiarchyNotifications = {}  // Notifications arranged by user
        this.notificationsList = [] // List in chronological order of matches with notifications
        this.unseenMessagesPerUser = {} // From chat server, key = user, set with list
        this.onlineList = {}        // List from notification server, usually set with profileslist
        this.account = {}           // Basic auth informaiton
        this.settings = {}          // User Preferences (search, notifications, etc)

        // These are incoming variables, probably only used in api calls
        this.profilesList = ""    // used for tertiary calls (my matches -> connections)
        this.profilesStore = ""   // used for main call, profile list management in app.js
        this.notifications = []

        // Call backs
        // Calls backs are arrays of functions passed from components
        // When an api call is complete, the response is passed through all array elements
        this.connectionsCallBack = {}
        this.profilesCallBack = {}
        this.onlineCallBack = {}
        this.accountsCallBack = {}
        this.notificationsCallBack = {}
        this.unseenMessagesCallBack = {}
        this.settingsCallBack = {}

        // Error
        // Used to call the same error modal in every error
        this.errorCallBack = {}

        // Console colors
        this.consoleInfo = "color: black, background-color: #d7d9f7"
        this.consoleError =  "color: black, background-color: #fadedc"

    }

    /*****
     * Setters & Getters
     */
    setUser (user) {
        this.user = user
    }

    // Transitory list, used to set an api call
    setProfilesList (profilesList) {
        this.profilesList = profilesList
    }

    // sets profiles List from array
    // ***** IMPORTANT TODO
    // TODO: Update this to check duplicates (duplicate check done), have a fifo and limit in size
    // *****
    // some of this is already done in addProfilesStore
    // all components should share profiles
    setProfilesArray (profilesArray) {
        let profileStoreArray = this.profilesStore.split(',')
        this.setProfilesStore(Array.from(new Set (profileStoreArray.concat(profilesArray))).join(","))
    }

    setProfilesStore (profilesStore) {
        profilesStore = profilesStore.replace(/^,/,'')
        profilesStore = profilesStore.replace(/,$/,'')

        this.profilesStore = profilesStore
    }

    setNotifications (nList) {
        this.notifications = nList
    }

    getUser () {
        return this.user
    }

    getConnections() {
        return this.connections
    }

    getProfiles() {
        return this.profiles
    }

    getAccount() {
        return this.account
    }

    getNotifications() {
        return this.notifications
    }

    /******
     * Set Callbacks
     */

    /**
     * We may require tag to insure only one call back is ever registered.
     * But not sure if necessary so long as registration of callbacks confined
     * to useeffect([])
     * @param {*} callback 
     * @param {*} tag 
     */
    registerConnectionsCallBack ( callback, tag ) {
        if (!!tag) {
            this.connectionsCallBack[tag] = callback;
            console.log("connection callback registered: ", callback)
        }

    }

    unRegisterConnectionsCallBack ( tag ) {
        if (!!tag) {
            delete this.connectionsCallBack[tag]
            console.log("connection callback unregistered: ", tag)
        }
    }

    doConnectionsCallBacks (response) {
        Object.values(this.connectionsCallBack).forEach ( e => {
            console.log("Matches calling: ", e)
            e(response)
        })
    }

    registerNotificationsCallBack ( callback, tag ) {
        if (!!tag) {
            this.notificationsCallBack[tag] = callback;
            console.log("notification callback registered: ", callback)
        }
    }

    unRegisterNotificationsCallBack ( tag ) {
        if (!!tag) {
            delete this.notificationsCallBack[tag]
            console.log("notification callback unregistered: ", tag)
        }
    }

    registerUnSeenMessagesCallBack ( callback, tag ) {
        if (!!tag) {
            this.unseenMessagesCallBack[tag] = callback;
            console.log("messages callback registered: ", callback)
        }
    }

    unRegisterUnSeenMessagesCallBack ( tag ) {
        if (!!tag) {
            delete this.unseenMessagesCallBack[tag]
            console.log("messages callback unregistered: ", tag)
        }
    }

    /**
     * Unlike the others, notifications is a dual object callback.
     */
    doNotificationsCallBacks() {
        Object.entries(this.notificationsCallBack).forEach ( ([k,e]) => {
            console.log("Notifications calling: ", k, e, this.notificationsList)
            e(this.hiarchyNotifications, this.notificationsList)
        })
    }

    doUnseenMessagesCallBacks() {
        Object.entries(this.unseenMessagesCallBack).forEach ( ([k,e]) => {
            console.log("Messages Calling: ", k)
            e(this.unseenMessagesPerUser)
        })
    }

    registerOnlineCallBack( callback, tag) {
        if(!!tag) {
            this.onlineCallBack[tag] = callback
            console.log("online callback registered: ", tag)
        }
    }

    unRegisterOnlineCallBack( tag ) {
        if (!!tag) {
            delete this.onlineCallBack[tag]
            console.log("online callback unregistered: ", tag)
        }
    }

    doOnlineCallBacks(response = null) {
        Object.entries(this.onlineCallBack).forEach ( ([k,e]) => {
            console.log("Online calling: ", k, e)
            e( response ? response : this.onlineList)
        })
    }

    registerProfilesCallBack ( callback, tag ) {
        if (!!tag) {
            this.profilesCallBack[tag] = callback
            console.log("profile callback registered: ", callback)
        }
    }

    unRegisterProfilesCallBack ( tag ) {
        if (!!tag) {
            delete this.profilesCallBack[tag]
            console.log("profile callback unregistered: ", tag)
        }
    }

    registerErrorCallBack ( callback, tag ) {
        if (!!tag) {
            this.errorCallBack[tag] = callback
            console.log("Error callback registered: ", callback)
        }
    }

    registerAccountsCallBack ( callback, tag ) {
        if (!!tag) {
            this.accountsCallBack[tag] = callback
            console.log("Account callback registered: ", callback)
        }
    }

    unRegisterAccountsCallBack ( tag ) {
        if (!!tag) {
            delete this.accountsCallBack[tag]
            console.log("%c account callback unregistered: ",this.consoleInfo, tag)
        }
    }

    registerSettingsCallBack ( callback, tag ) {
        if (!!tag) {
            this.settingsCallBack[tag] = callback
            console.log("%c Settings Callback registered: ", this.consoleInfo, tag, callback)
        }
    }

    unRegisterSettingsCallBack ( tag ) {
        if (!!tag) {
            delete this.settingsCallBack[tag]
            console.log("%c Settings Callback unregistered: ", this.consoleInfo, tag)
        }
    }

    doSettingsCallBacks () {
        Object.entries(this.settingsCallBack).forEach ( ([k,e]) => {
            console.log("Settings calling: ", k)
            e(this.settings)
        })
    }

    /**
     * Allows passing a new account in this method which will set this.account
     * if usernames are equal. This is used mainly when tokens are changed via
     * an api call (reloads)
     * 
     * @param {*} a 
     */
    doAccountCallBacks (a = null) {
        if (a !== null && a.username === this.user) {
            this.account = a
        }
        Object.values(this.accountsCallBack).forEach ( e => {
            console.log("Accounts calling: ", e)
            e(this.account)
        })
    }

    /****
     * API Retrieval Calls
    */

    apiGetConnections () {
        getConnectionsReduced(this.profilesStore)
            .then ( response => {
                // send response to every registered callback
                console.log("Matches received response: ", this.profilesStore, response)
                this.connections = response;

                console.log("Doing Callbacks: ", this.connectionsCallBack)
                this.doConnectionsCallBacks(response)
            })
            .catch ( e => this.handleError(e))
    }

    apiGetProfiles () {

        // check if we need to do another api call
        // This is a rudimentary implementation to avoid unnecessary api calls
        // Fifo on the profiles list should increase performance
        // with this current method, profiles list could grow quite large
        let pl = this.profiles.map(p => p.username)
        if (this.profilesStore.split(",").filter( u => !pl.includes(u)).length === 0) {
                // if profiles includes entries for all profileStore, just do callbacks
                Object.entries(this.profilesCallBack).forEach ( ([k,e]) => {
                    console.log("Stored calling: ", k)
                    e(this.profiles)
                })
            }
        else if (this.profilesStore.length > 0) {
        loveMireApi.getJson('profiles', this.profilesStore)
            .then ( (response) => {
                //console.log("Profiles received response: ", response)
                this.profiles = response;
                //console.log("Doing Callbacks: ", this.profilesCallBack)
                //console.log(this.profilesStore.split(","),pl, this.profilesStore.split(",").filter( u => !pl.includes(u)))
                Object.entries(this.profilesCallBack).forEach ( ([k,e]) => {
                    console.log("Profiles calling: ", k)
                    //console.log(response)
                    e(response)
                })                

            })
            .catch ( e => this.handleError(e))
        }

    }

    apiGetOnlineUsers (userList = null) {

        
        // Either use supplied list or update from this.profiles
        // This could fail to update if profiles hasn't updated yet
        userList = userList ? userList : this.profiles.map(p => p.username)

        // this.onlineList may need a FIFO along with profiles
        // alternatively, we could store online data in profiles.
        // Would we ever need this data seperately?

        if (userList.length > 0) {
            loveMireApi.postJson('online', userList)
                .then ( (response) => {
                    if(!Array.isArray(response))
                        throw new Error("Online Users Response malformed")

                    response.forEach ( u => {
                        this.onlineList[u.name] = u
                    })

                    console.log("Online User CallBacks: ", Object.keys(this.onlineCallBack))
                    Object.entries(this.onlineCallBack).forEach ( ([k,e]) => {
                        console.log("Online Users Calling: ", k)
                        e(this.onlineList)
                    })
                })
                .catch( e => this.handleError(e))
        }

    }

    /**
     *   This is always called since updating the balance is a necessary operation
        likely only ever one callback at the top.

        But having this in the matches module makes it easy for every component to
        ask for new balance.

        Takes a single callback parameter that is called only once. 
        Use this.registerAccountsCallBack for persistant

     */
    apiGetAccount (singleCallBack) {

        loveMireApi.getJson('account')
            .then ( (response) => {
                if (!response['name']) response['name'] = response['message'] // deprecated ?

                // Always checks user is set. This really is only done once
                if(this.user !== response['name']) this.setUser(response['name'])
            
                this.account = response

                this.doAccountCallBacks()

                if ( singleCallBack && typeof singleCallBack === 'function') {
                    singleCallBack(response)
                }
            })
            .catch( e => this.handleError(e))

    }

    apiGetNotifications () {

        loveMireApi.getJson('getAllNotifications')
            .then ( response => {
                this.notifications = response            

                this.hiarchyNotifications = this.arrangeNotifications(response)

                this.doNotificationsCallBacks()

                // Update profile list from these notifications
                this.setProfileListFromNotificationList(response)
                this.apiGetProfiles()
            })
            .catch (e => this.handleError(e))
    }

    /**
     * Adds a user to the notifications list. Rather than completely refreshing
     * the hiarchyNotifications and notificationsList, this requests a single user (or users)
     * and updates the lists.
     * 
     * @param {String,array} user 
     */
    apiAddNotifications ( user ) {

        user = Array.isArray(user) ? user : [user]

        getFlirt(user, this.getUser())
            .then ( response => {

                let newHiarchy = this.arrangeNotifications(response)

                user.forEach ( u => this.hiarchyNotifications[u] = newHiarchy[u])

                // update connections list
                // so far, don't see a use case for updating the list
                // A call here is likely outside the list or with a recent call
                // Connections list should only need to updated if a new notificaiton
                // or message is received.

                this.doNotificationsCallBacks()

                this.setProfileListFromNotificationList(response)
                this.apiGetProfiles()

            })
            .catch ( e => this.handleError(e))

    }

    /**
     * Gets a new list of notifications for specific references
     * 
     * Merges the results into the existing hiarchyNotifications
     * 
     * This is used for view activity on specific media.
     * Feed/MediaNotifications
     * @param {array | number} refId 
     */
    apiAddReferenceNotifications ( refId ) {

        let refIds = Array.isArray(refId) ? refId : [refId]

        let request = {
            referenceIds: refIds
        }

        getNotificationRequest(request)
            .then ( response => {

                let newHiarchy = this.arrangeNotifications(response)
                this.mergeHiarchyNotifications(newHiarchy)
                this.doNotificationsCallBacks()

            })
    }

    apiGetNotificationsList (request) {

        let payload = (!!request) ? request : {}

        notificationRequest(payload, this.getUser())
            .then ( response => {

                this.notificationsList = response

                this.doNotificationsCallBacks()

                this.setProfileListFromNotificationList(response, true)
                this.apiGetProfiles()

            })
            .catch( e => this.handleError(e))

    }

    /**
     * This must be called after notificationslist and connections
     * TODO: some data should be called on app load (connections/matches only?)
     */
    apiGetNewMessagesList () {

        let userList = this.notificationsList
            .filter( e => this.findMatches(e.name) === "CONNECTION")
            .map(e => e.name)
            .join(",")

        getUnseenMessagesPerUser(userList)
            .then ( response => {
                this.unseenMessagesPerUser = response
                this.doUnseenMessagesCallBacks()
            })
            .catch ( e => this.handleError(e))

    }

    apiGetSettings () {

        getSettings()
            .then ( response => {
                this.settings = response                
                this.doSettingsCallBacks()
            })
            .catch ( e => this.handleError(e))

    }

    /************************
     * API Put methods     * 
     */


    apiPutSettings (settings) {

        // use this.settings if no argument
        if (!settings) {
            settings = this.settings
        }

        saveSettings(settings)
            .then ( response => {
                this.settings = response;
                this.doSettingsCallBacks()
            })
            .catch ( e => this.handleError(e))

    }

    /****************
     * Data modification methods
     */

    /**
     * Adds a notification and does call backs.
     * 
     * TODO: add to connectionslist, build method to add to newMessages on new message 
     * 
     * @param {object} notification 
     */
    addNotification ( notification ) {

        console.log("Adding notification")

        // Set reference Id if exists.
        // If doesn't exist, probably a message.
        let referenceId = !!notification.referenceId ? notification.referenceId :
            notification.type + "-" + notification.id

        // Set key to the match. notifications either have toId/fromId or match
        let key = !!notification.match ? notification.match :
            notification.toId === this.user ? notification.fromId : notification.toId;

        if (!this.notificationExists(notification)) {
                        
            if(!Object.keys(this.hiarchyNotifications).includes(key)) {
                // brand new user
                this.hiarchyNotifications[key] = {}
                this.hiarchyNotifications[key][referenceId] = [notification]
            } else if (!Object.keys(this.hiarchyNotifications[key]).includes(referenceId)) {
                // not new user but missing reference
                this.hiarchyNotifications[key][referenceId] = [notification]
                console.log("not new and missing: ", notification, this.hiarchyNotifications)

            } else {
                this.hiarchyNotifications[key][referenceId].push(notification)
            }

            this.addNotificationToLists( notification, key, !notification.match )

            this.doNotificationsCallBacks()

        } else {
            this.modifyNotification(notification)
        }
    }


    /**
     * private method
     * 
     * Adds the notification to connectionsList or unSeenMessagesPerUser
     * @param {*} notification 
     * @key {String} corresponds to key in connectionsList etc (match)
     * @isNotification {boolean} true if using connectionsList.unseen
     */
    addNotificationToLists( notification, key, isNotification ) {

        if ( isNotification ) {
            this.notificationsList.find(e => e.name === key).unseen++
            this.notificationsList.find(e => e.name === key).date = notification.date
        } else {
            if (Object.keys(this.unseenMessagesPerUser).includes(key)) {
                this.unseenMessagesPerUser[key]++
            } else {
                this.unseenMessagesPerUser[key] = 1
            }
            this.doUnseenMessagesCallBacks()
            // calling method doesn't do unseen callbacks
        }

        console.log(this.notificationsList, this.unseenMessagesPerUser, key, this.notificationsList.find(e => e.name === key))
    }

    /**********
     * HELPER METHODS
     * 
     * These are provided for encapsulation purposes.
     * Each method will first look for data in arguments, before reverting to data
     * held in this class
     * 
     * NOTE: These access data stored in this class. It could outrun a setState, so
     * calling these functions should always account for the data not having been
     * updated yet.
     */


    /**
     * Returns true if notification already exists in hiarchyNotifications.
     * 
     * @param {object} notification 
     * @returns 
     */
    notificationExists(notification) {
        return (Object.values(this.hiarchyNotifications)
            .flatMap ( match => Object.values(match))
            .flat()
            .filter( e => e.id === notification.id && e.type === notification.type).length > 0)
    }

    modifyNotification(notification) {
        console.log("TODO: modify existing notification (chat message hidden)")
    }

    /**
     * Marks all notifications in hiarchyNotifications as seen form
     * a particular user
     * @param {String} user 
     */
    markSeenFromUser(user) {

        // If user doesn't exist in hiarchy, there are no notifications
        if (!!this.hiarchyNotifications[user]) {

            let modified = false
            Object.keys(this.hiarchyNotifications[user]).forEach (k => {
                
                this.hiarchyNotifications[user][k] = 
                this.hiarchyNotifications[user][k].map ( notification => {
                    if(notification.seen===false && notification.fromId === user && notification.seen === false) {
                        notification.seen=true
                        modified = true
                    } 
                    return notification
                })
            })

            this.notificationsList = this.notificationsList.map ( e => {
                
                if (e.name === user && e.unseen !== 0) {
                    e.unseen = 0
                    modified = true
                }
                return e
            })

            if (modified) {
                this.doNotificationsCallBacks()
            }
        } 

    }


    /**
     * Accepts matches object which contains TO: and FROM:
     * 
     * If match object not provided, looks at local matches
     * 
     * Then finds the user and returns ENUM (TODO enum?)
     * @param {*} user 
     * @param {*} matches 
     * @returns 
     */
    findMatches ( user, matches ) {
        let data
        if (!!matches) data = matches;
        else data = this.connections;

        if (!!data && Array.isArray(data.to) && Array.isArray(data.from)) {
            let toExists = data.to.includes(user)
            let fromExists = data.from.includes(user)

            if ( toExists && fromExists ) return "CONNECTION"
            else if (toExists) return "TO"
            else if (fromExists) return "FROM"
            else return "NONE"
        }
        return "NONE"
    }

    /**
     * Returns the profile object with username matching
     * 
     * @param {String} match 
     * @returns 
     */
    findUser (match) {

        if(!!match && this.profiles.length > 0) {
            return this.profiles.filter( p => p.username === match)[0]
        } else {
            return undefined
        }
    }

    /**
     * Smart method. If a user is received here, it either adds
     * them to TO, removes from TO (if not exists), or Hides
     * them
     * @param {*} user 
     * @param {"LIKE", "UNLIKE"} direction
     */
     updateMatches ( user, direction ) {

        try {
            if ( direction === "LIKE") {

                addLike(user).then ( u => {
                    let newMatches = {...this.connections}
                    if (!newMatches.to.includes(u)) {
                        newMatches.to.push(u)
                        this.connections = newMatches
                        
                        console.log("Doing Callbacks: ", this.connectionsCallBack)
                        this.doConnectionsCallBacks(this.connections)
                    }
                })
            } else if ( direction === 'HIDE') {

                let newMatches = {...this.connections}
                if (newMatches.to.includes(user)) {
                    // delete like
                    deleteLike(user).then ( u => {
                        newMatches.to = newMatches.to.filter( e => e !== u)
                        this.connections = newMatches
                        
                        console.log("Doing Callbacks: ", this.connectionsCallBack)
                        this.doConnectionsCallBacks(this.connections)
                    })
                } else {
                    // TODO: do Hide
                }

            } else throw new Error("illegal argument: updateMatches");

        } catch ( e ) {
            console.log(e)
        }
    }    

    /**
     * Extracts all users from either to or from
     * 
     * This is called from MyMatches.
     * 
     * Expects an array of objects with a "fromId" and "toId" field. Extracts all unique
     * values from either field and sets the profileStore
     * 
     * isMatchList sets it to look for a single "name" field in each object
     * 
     * @param {*} nList 
     * @returns 
     */
    setProfileListFromNotificationList (nList, isMatchList = false) {
        let newProfiles = (isMatchList) ?
            nList.map(e => e.name) :
            Array.from(new Set (nList.map ( f => f.toId).concat(nList.map( f=> f.fromId)) ) )

        this.setProfilesArray(newProfiles)
    }

    setProfileListFromLikeList ( lList ) {
        let newProfiles =
            Array.from ( new Set (lList.map ( l => l.owner) ) )
        
        this.setProfilesArray(newProfiles)
    }

    /**
     * Can take array or string. Adds to the profiles Store.
     * 
     * @param {*} newProfiles 
     */
    addProfilesStore( newProfiles ) {

        let newProfilesString = ""
        if (Array.isArray(newProfiles) ) {
            newProfilesString = newProfiles.length > 0 ? this.profilesStore + "," + newProfiles.join(",") : this.profilesStore;
        } else {
            // is string?
            if (newProfiles.length > 0) {
                newProfilesString = this.profilesStore + ',' + newProfiles
            } else {
                newProfilesString = this.profilesStore;
            }
        } 

        // remove duplicates if exist
        newProfilesString = [...new Set(newProfilesString.split(","))].join(',')
        this.setProfilesStore(newProfilesString)
    }

    /**
     * Takes a notification list and transforms it to a hiarchy list.
     * Returned list has keys for each profile, which contains an object
     * with keys for each reference ID, which contains an array of the notifications.
     * 
     * @param {Object} nList 
     * @returns Object
     */
    arrangeNotifications (nList) {
        let profileObjects = {}
        nList.forEach( n => {

            let key = ""
            // some notifications have match, some have toId/fromId
            if (n.match) {
                key = n.match
            } else {
                key = n.toId === this.user ? n.fromId : n.toId;    // Return the username that isn't user
                                                                    // Every notification will have the user in it
            }

            if (Object.keys(profileObjects).includes(key)) {
                profileObjects[key].push(n)
            } else {
                profileObjects[key] = [n]
            }
        })

        // arrange by referenceId
        Object.keys(profileObjects).forEach ( k => {
            profileObjects[k] = profileObjects[k].reduce ( (obj, v) => {
                if(!v.referenceId) {
                    // has no reference Id, group by type-id
                    // This is usually messages
                    obj[`${v.type}-${v.id}`] = [v]
                } else if(!obj[v.referenceId]) {
                    // Any notifications that should be grouped have reference ID
                    obj[v.referenceId] = [v]
                } else {
                    obj[v.referenceId].push(v)
                }
                return obj;
            },{} )
        })
        
        
        return profileObjects;
    }

    /**
     * Merges a new hiarchynotifications into existing
     * expects parameter to be properly formatted. 
     * 
     * Probably called after arrangeNotifications
     * @param {obj} newHiarchy 
     */
    mergeHiarchyNotifications ( newHiarchy ) {

        // existing user keys in hiarchy
        let hKeys = Object.keys(this.hiarchyNotifications)

        Object.keys(newHiarchy).forEach ( user => {
            if ( hKeys.includes(user) ) {
                Object.keys(newHiarchy[user]).forEach ( refId => {
                    if ( this.hiarchyNotifications[user][refId]) {
                        let idList = this.hiarchyNotifications[user][refId].map (e => e.id)
                        this.hiarchyNotifications[user][refId] =
                            [...this.hiarchyNotifications[user][refId],
                            ...newHiarchy[user][refId].filter( e => !idList.includes(e.id))
                            ]
                    } else {
                        this.hiarchyNotifications[user][refId] = 
                            newHiarchy[user][refId]
                    }
                })
            } else {
                this.hiarchyNotifications[user] = newHiarchy[user]
            }
        })

    }

    /**
     * Takes a match that corresponds to the key in the hiarchyNotifications and returns
     * an array of arrays of notifications by reference Id in sorted order by created date.
     * 
     * Note: It is assumed that hiarchyNotification keys are in order by the way they
     * are added, but this is likely not true when individual notifications are added.
     * 
     * @param {string} match 
     * @returns 
     */
    notificationsSortedByMatch = (match) => {

        if (!!this.hiarchyNotifications && match in this.hiarchyNotifications) {
            return Object.values(this.hiarchyNotifications[match]).sort ( (a,b) => {
                let aDate = a.map(e => Date.parse(e.created)).reduce( (ea,eb) => ea > eb ? ea : eb)
                let bDate = b.map(ee => Date.parse(ee.created)).reduce ( (eea,eeb) => eea > eeb ? eea : eeb)
                
                return bDate - aDate
            })
        } else return {}
    }

    /**
     * Selectively updates this.settings with the keys contained in parameter.
     * 
     * Useful for cases where the module doesn't use all the settings keys
     * @param {Object} newSettings 
     */
    updateSettings (newSettings) {

        Object.keys(newSettings).forEach ( k => {
            this.settings[k] = newSettings[k]
        })

    }

    /**********
     * ERROR HANDLING
     **********/

    handleError(e) {
        console.log(e)
    }

}

export default Matches