import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {SettingsButtonFlex} from '../../styles/Buttons'
import styled from 'styled-components'

const StyledTopMenu = styled.div`
    position: block;
    margin: 0 auto;
    display: flex;
    width: auto;
    
    align-items: center;
    justify-content: center;
`;

const StyledDropDown = styled.div`
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: column;
    z-index: 100;
    background-color: white;
    border-radius: 0 0 10px 10px;
    min-width: 300px;
`

const CatchAllDiv = styled.div`
    position: fixed;
    z-index: 100;
    left: -1000px;
    top: -1000px;
    right: -1000px;
    bottom: -10000px;
`;

const TopMenu = (props) => {

    const [isExpanded, setExpanded] = useState(false)

    return (
        <>
        
        <StyledTopMenu>            
            {props.topBar}
            <SettingsButtonFlex
                onClick={() => setExpanded(!isExpanded)} />
            
        </StyledTopMenu>
        {isExpanded && <StyledDropDown>
            { /* doesn't work <CatchAllDiv onClick={() => setExpanded(false)} /> */ }
            {props.dropDown}
        </StyledDropDown>}
        </>
        )

}

TopMenu.propTypes = {
    topBar: PropTypes.object.isRequired,
    dropDown: PropTypes.object.isRequired // can be {}
    /* deleteMediaFromPost: PropTypes.func.isRequired isn't needed, can put X on editpost thumbs */
}
export default TopMenu;