import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LikeTypes from './data/LikeTypes';

const StyledLikeButtonWrapper = styled.div`
    position: absolute;
    z-index: 2;
    bottom: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
`

const StyledLikeButton = styled.button`
    position: absolute;
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    outline: none;
    background-color: ${props => {
        let color = "rgba(233, 128, 128, 0.88)";  // max like
        if (props.likeScore === 0) {
            color = "rgba(221, 221, 221, 0.82)";
        } else if (props.likeScore < 2) {
            color = "rgba(233, 128, 128, 0.38)";
        } else if (props.likeScore < 10) {
            color = "rgba(233, 128, 128, 0.68)";
        }

        return color;
    }};
    &:active {
        border-style: outset;
    }
`

const StyledDeleteLikeButton = styled.button`
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    margin-right: -5px;
    margin-top: -5px;
    width: 20px;
    height 20px;
    border-radius: 50%;
    outline: none;
    border: 0.5px solid gray;
    background-color: white;
`;

/**
 * This uses a supplied object in LikeTypes.js to calculate possible likes.
 * This should be changed to get the likes from server.
 * 
 * @param {*} props 
 * @returns 
 */
const Lb = (props) => {

    const [likeScore, setLikeScore] = useState(0);
    const [likeButtonPressed, setLikeButtonPressed] = useState(false); // keeps double clicks from happening
    const [deleteButtonPressed, setDeleteButtonPressed] = useState(false);

    const maxScore = LikeTypes.reduce ( (a,b) => a + b.cost, 0)

    
    const likePressed = (e) => {
        e.stopPropagation();
        if (!likeButtonPressed) {
            setLikeButtonPressed(true);
            props.onClick();
        }
    }

    const deletePressed = (e) => {
        e.stopPropagation();
        if (!deleteButtonPressed) {
            setDeleteButtonPressed(true);
            props.onDeleteClick();
        }
    }

    useEffect ( () => {
        // on any props change, update score
        if ( props.user_likes !== undefined ) {
            let newScore = props.user_likes.reduce (
                (a,b) => a + b.likeType.costIncoming
            , 0)
            setLikeScore(newScore)
        }
        setLikeButtonPressed(false)
        setDeleteButtonPressed(false)
    }, [props])

    if (likeScore === maxScore) {
        // No further likes
        return <StyledLikeButtonWrapper>
            <StyledLikeButton disabled={likeButtonPressed} maxScore={maxScore} likeScore={maxScore}>
            *</StyledLikeButton>
            <StyledDeleteLikeButton disabled={deleteButtonPressed}
                onClick={deletePressed}>U</StyledDeleteLikeButton>
        
        </StyledLikeButtonWrapper>
    } else if (likeScore === 0) {
        // Has no like (no need for delete button)
        return <StyledLikeButtonWrapper><StyledLikeButton disabled={likeButtonPressed} likeScore={likeScore}
                onClick={likePressed}>L</StyledLikeButton>
                </StyledLikeButtonWrapper>
    } else {
        return <StyledLikeButtonWrapper>
            <StyledLikeButton disabled={likeButtonPressed} likeScore={likeScore}
                onClick={likePressed}>L</StyledLikeButton>
                <StyledDeleteLikeButton disabled={deleteButtonPressed}
                    onClick={deletePressed}>U</StyledDeleteLikeButton>
            
        </StyledLikeButtonWrapper>
    }
}

Lb.propTypes = {
    onClick: PropTypes.func,
    onDeleteClick: PropTypes.func
}

export default Lb;