import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components'
import PropTypes from 'prop-types';
import { StyledFineP, StyledBoldP } from '../../../styles/Text';
import { SelectButton } from 'lovemire-components';
import { StyledFormFlexContainer } from '../../../styles/profile/Wrappers';

const HiddenDiv = styled.div`
  right: 0;
  margin: 2px auto;
`;

/**
 * The API saves for hidden requirements ended up a bit weird.
 * 
 * This component passed up the form refs and lets ImageEdit control
 * the inputs. But since a user needs to be able to toggle secret
 * with one click, it now does an api save with every update (which
 * may work fine until implementing the reference-id config).
 * 
 * Preview image is a seperate component which also lets ImageEdit
 * control the image. When the image is updated, it makes the same
 * API call.
 * 
 * 
 * @param {Object} props 
 * @returns 
 */
const Hb = (props) => {

    const hiddenCheckBox = useRef(null)

    const [expanded, setExpanded] = useState(false)

    useEffect ( () => {
        props.selected && setExpanded(true)
        !props.selected && setExpanded(false)
    },[props])

    return (
        <HiddenDiv>     
            <SelectButton selected={props.selected}
                onClick={ () => {
                    props.update(!props.selected)
                }
                }>
                <StyledBoldP>Secret Image</StyledBoldP>
            </SelectButton>
            {expanded&& props.hiddenRequirements && <StyledFormFlexContainer>
                <li>
                    <label>
                        <p>Connections only</p>
                    </label>
                    <input name="requiredConnection"
                        type="checkbox"
                        checked={props.hiddenRequirements.requiredConnection}
                        onChange={ e => props.update(e)} /> 
                </li>
                <li>
                    <label>
                        <p>Score needed</p>
                    </label>
                    <input name="requiredScore" 
                        type="text" 
                        value={props.hiddenRequirements.requiredScore + " (default: 1 max like)"} disabled />
                </li>
                <li>
                    <label>
                        <p>Reference Ids</p>
                    </label>
                <input name="referenceIds" 
                    type="text" value={props.hiddenRequirements.referenceIds.join() + " (in dev)"}
                    disabled />
                </li>
                
            </StyledFormFlexContainer>}
        </HiddenDiv>)
}

Hb.propTypes = {
    selected: PropTypes.bool.isRequired,
    hiddenRequirements: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired
}

export default Hb;