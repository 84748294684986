import styled from 'styled-components'


export const PostFooter = styled.div`

    margin: 0;
    padding: 2px 0;
`;

const PostFooterP = styled.p`
    font-family: 'Roboto', sans-serif;
    font-style: bolder;
    font-weight: 350;
    color: black;
    font-size: 0.6em;
    padding: 4px;
    margin: 0;
    text-align: center;
`;

const PostFooterScript = styled.p`
    font-family: 'Freestyle Script', cursive;
    color: rgba(0,0,0,0.8);
    font-size: 0.7em;
    text-align: left;
    margin: 5px 0 0 5px;
`;

PostFooter.P = PostFooterP
PostFooter.Script = PostFooterScript