import React from 'react'
import Modal from '../styled/MediaModal'

export default function one (e) {

    const dems = {

    }
    return <Modal>
        <Modal.H2>Gift Sent!</Modal.H2>
        <Modal.P centered>Cost: 25</Modal.P>
        <Modal.P>{e.profile.title} will receive a gift once opened!</Modal.P>
        {e.children}
    </Modal>

}