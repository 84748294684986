import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import { toHowOld } from './TextLib';

const sseUrl = process.env.NODE_ENV === "development" ?
`http://staging:8083/subscribe` : "https://lovemire.com/notificationsApi/subscribe";

export const Sse = (matches) => {

    console.log("%c Constructing Sse Subscribe: ", 'color: green')

    const token = window.localStorage.getItem("t")

    const sse = new EventSourcePolyfill(sseUrl, {
         headers: {
             'Authorization': 'Bearer ' + token
    } });

    sse.onmessage = (e) => console.log(e)
    sse.onopen = (e) => console.log(e)
    sse.addEventListener( "notification", result => {
        let notification = JSON.parse(result.data)
        console.log(`%c Received Notification: ${toHowOld(notification.created)}`, 'color: purple', notification)
        matches.addNotification(notification)
    })

    sse.onerror = err => {
        console.log('EventSource error: ', err)
    }
}