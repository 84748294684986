import React, {useState, useEffect} from 'react'
import styled from 'styled-components'

const DeleteLikeButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2%;
    border-radius: 50%;
    color: black;
    z-index: 8;
    border: rgba(0, 0, 0, 0.7) 0.5px solid;
    background-color: rgba(166, 156, 161, 0.5);
    &:after {content: "⟲";}

`;

const Dlb = (props) => {

    const deleteLike = (e) => {
        e.stopPropagation();
        setDisabled(true);
        props.onClick();
    }

    const [isDisabled, setDisabled] = useState(false)
    const [showDeleteButton, setShowDeleteButton] = useState(true)

    useEffect( () => {
        setDisabled(false)
        
        if (props.user_likes) {
            setShowDeleteButton(
                props.user_likes.filter( e => !e.locked).length > 0 ?
                true : false );
        }
        
    }, [props])

    return (
    <>
        {showDeleteButton && <DeleteLikeButton 
            disabled={isDisabled}
            onClick={deleteLike}
            />}
    </>

    )
}

export default Dlb;
