import React, {useState, useEffect, useRef} from "react";
import {BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import styled from 'styled-components'
import {Sp, StyledNewNav, StyledSkinnyNav, StyledNav, LiDiv} from './styles/StyledNavigation'
import {useScrollPosition} from '../lib/ScrollPosition'
import {useLocation, useHistory} from 'react-router-dom'
import { useWindowSize } from "../lib/useWindowSize";

import { NavButton } from "lovemire-components";
import LoveMireWide from '../images/logo-wide.png'
import LoveMireSquare from '../images/logo-square.png'
import FeedIcon from '../images/menu/feed.png'
import MatchesIcon from '../images/menu/matches.png'
import ProfileIcon from '../images/menu/profile.png'

const NavWrapper = styled.div`
    display: inline-block;
    width: 100%;
    position: fixed;
    background-color: white;
    right: 0;
    top: 0;
    z-index: 9;
    visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
    transition: all 200ms ${props => (props.isVisible ? 'ease-in' : 'ease-out')};
    transform: ${props => (props.isVisible ? 'none' : 'translate(0, -100%)')};
`;

const SubMenu = styled.ul`
    justify-content: top;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    align-items: top;
    flex: 1;
    list-style-type: none;
    flex-wrap: wrap;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;

    li {
        margin: 0;
        flex-basis: 20%;

        @media (max-width: 600px) {
            flex-basis: 100%;
        }
    }
`;

const TopMenu = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: nowrap;

    div {
        flex-wrap: nowrap;
    }
`



  const Navbar = (props) => {

    const [isVisible, setVisible] = useState(true)
    const [isScrolled, setScrolled] = useState(false)

    const windowSize = useWindowSize()
    const routeLocation = useLocation();
    const history = useHistory();
    const rendersCount = useRef(0)

    const SKINNYBARROUTES = [/^\/MyProfile\/AddPost/]
    const SKINNYBARONSCROLLROUTES = [/^\/Feed/, /^\/MyMatches/]

    const CELL3EXCLUDEROUTES = [/^\/MyProfile\/AddPost/]

    const CELL2MODULES = {
        'PostRequest': {
            'include': [/^\//],
            'exclude': [/^\/MyProfile/,/^\/MySettings/,/^\/MyMatches/]
        }
    }
    
    const CELL3MODULES = {
        'AccountBar': {
            'include': [/^\//],
            'exclude': CELL3EXCLUDEROUTES
        }
    }

    useScrollPosition( ({prev, curr}) => {
        //console.log(prev, curr,routeLocation)
        if (curr.y > -250) {
            // within 150px from top
            if (!isVisible) setVisible(true)
            if (isScrolled) setScrolled(false)
        } else {
            let isScrollUp = curr.y > prev.y
            if (isScrollUp !== isVisible) setVisible(isScrollUp)
            if (!isScrolled) setScrolled(true)
        }
    }, [isVisible], false, false, 500)


    // Navbar configs based on path
    const useSkinnyBar = () => {
        return SKINNYBARROUTES.filter(e => routeLocation.pathname.match(e)).length > 0
            || (isScrolled && SKINNYBARONSCROLLROUTES.filter(e => routeLocation.pathname.match(e)).length > 0)
    }

    /**
     * Modules object contains keys corresponding to the __TYPE prop in a child passed to this component.
     * Each key has an object containing two keys ['include', 'exclude']
     * The route supplied by react router must be included in the include array and not in the exclude array.
     * Include is tested first. If either has an empty array, it defaults to pass.
     * 
     * @param {Object} MODULES 
     * @returns 
     */
    const cell = (MODULES) => {
        let loc = routeLocation.pathname
        return Object.keys(MODULES)
            .filter( k => MODULES[k].include.length === 0 || MODULES[k].include.filter( rg => loc.match(rg)).length > 0)
            .filter( k => MODULES[k].exclude.length === 0 || MODULES[k].exclude.filter( rg => loc.match(rg)).length === 0)
            .map( k => React.Children.toArray(props.children).find( e => Object.keys(MODULES).includes(e.props.__TYPE)) || <></> ) 
    }

    const cell3 = () => {
        return CELL3EXCLUDEROUTES.filter( e => routeLocation.pathname.match(e)).length == 0 &&
            React.Children.toArray(props.children).filter( e => CELL3MODULES.includes(e.props.__TYPE))
    }

    const isRoute = (route) => {        
        return routeLocation.pathname.match(route)
    }

    /*const scrolling = () => {

        let newScrollTop = document.body.scrollTop || 
        document.documentElement.scrollTop

        if (newScrollTop > 150) {
            if(!isScrolled) setScrolled(true)
            if (newScrollTop < scrollTop) {
                console.log("up", newScrollTop, scrollTop, isVisible)
                if (!isVisible) {
                    console.log("setting true")
                    setVisible(true)
                }
                scrollTop = newScrollTop
            } else {
                console.log("down", newScrollTop, scrollTop,isVisible)
                if (isVisible) {
                    console.log("setting false")
                    setVisible(false)
                } else {
                    console.log("Not setting false - ", isVisible)
                }
                scrollTop = newScrollTop
            }
        } else {
            console.log("else - ", newScrollTop)
            setScrolled(false)
            setVisible(true)
        }
    }

    useLayoutEffect(() => {   
        // to revisit:
        // https://codesandbox.io/s/use-scroll-position-8nfin?fontsize=14
        // https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
        
        //window.addEventListener("scroll", () => scrolling());
        //return () => 
        //   window.removeEventListener("scroll", scrolling); 
      }, [])
    */

    useEffect( () => {
        
    }, [isVisible])

    let cell1 = React.Children.toArray(props.children).filter( e => [].includes(e.props.__TYPE))

    
    return (<NavWrapper isVisible={isVisible}>
        {!useSkinnyBar() && <><TopMenu style={{maxHeight: '4vh'}}>
                <div onClick={ () => history.push("/Feed") } style={{flex: '0 1 33%', maxHeight: '100%'}}><img style={{objectFit: 'scale-down', height: '100%', width: '100%'}} src={LoveMireWide} /></div>
                <div style={{flex: '3 0 50%', overflow: 'hidden', alignSelf: 'center', height: '100%'}}>{cell(CELL2MODULES)}</div>
                <div onClick={ () => history.push("/MySettings") } style={{flex: '0 1 17%', alignSelf: 'center', height: '100%'}}>{cell(CELL3MODULES)}</div>
            </TopMenu>
        <StyledNewNav>
            <ul>
                
                <li onClick={ () => { history.push("/MyProfile")}}>
                        <NavButton selected={isRoute('MyProfile')}>My Profile</NavButton>
                </li>
                
                <li onClick= { () => {history.push("/Feed")} }>
                    <NavButton selected={isRoute('Feed')}>Feed</NavButton>
                </li>
                <li onClick= { () => {history.push("/MyMatches")}}>
                    <NavButton selected={isRoute('MyMatches')}>My Matches</NavButton>
                </li>
            </ul>
        </StyledNewNav>
        </>}
        {useSkinnyBar() && (
        <StyledSkinnyNav>
                <StyledSkinnyNav.Logo>
                    <StyledSkinnyNav.Icon  onClick= { () => {history.push("/Feed")} } src={LoveMireSquare} />
                    <StyledSkinnyNav.Icon onClick={ () => { history.push("/MyProfile")}} selected={isRoute('MyProfile')} src={ProfileIcon} />
                    <StyledSkinnyNav.Icon onClick= { () => {history.push("/Feed")} } selected={isRoute('Feed')} src={FeedIcon} />
                    <StyledSkinnyNav.Icon onClick= { () => {history.push("/MyMatches")}} selected={isRoute('MyMatches')} src={MatchesIcon} />
                </StyledSkinnyNav.Logo>
                <StyledSkinnyNav.Search>
                    {cell(CELL2MODULES)}
                </StyledSkinnyNav.Search>
                <StyledSkinnyNav.Settings>
                    <div onClick={ () => history.push("/MySettings") }
                        style={{marginLeft: 'auto'}}>{cell(CELL3MODULES)}</div>
                </StyledSkinnyNav.Settings>
        </StyledSkinnyNav>
        )}
        {/*<SubMenu>
             <li>
                {cell1}
            </li>
            <li style={{flexGrow: '1'}}>
                {cell(CELL2MODULES)}
            </li>
            <li>
                {cell(CELL3MODULES)}
            </li>
        </SubMenu>*/}
        </NavWrapper>
)

/* LoveMire Dev: May 7, 2022
*
    return (<NavWrapper isVisible={isVisible}>
            <StyledNav size={useSkinnyBar() ? 'min' : ""}>
                <ul>
                    
                    <li onClick={ () => { history.push("/MyProfile")}}>
                        <LiDiv>
                            <Link style={{textDecoration: 'none'}} to="/MyProfile">
                                {!useSkinnyBar() ? <p>{`{r: ${++rendersCount.current}-${windowSize.height}:${windowSize.width}}`}</p> : <></>}
                                <Sp>My Profile</Sp>
                            </Link>
                            
                        </LiDiv>
                    </li>
                    
                    <li onClick= { () => {history.push("/Feed")} }>
                    <LiDiv>
                        <Link style={{textDecoration: 'none'}} to="/Feed"><Sp>Feed</Sp></Link>
                        </LiDiv>
                    </li>
                    <li onClick= { () => {history.push("/MyMatches")}}>
                    <LiDiv>
                        <Link style={{textDecoration: 'none'}} to="/MyMatches"><Sp>My Matches</Sp></Link>
                        </LiDiv>
                    </li>
                </ul>
            </StyledNav>
            <SubMenu>
                 <li>
                    {cell1}
                </li>
                <li style={{flexGrow: '1'}}>
                    {cell(CELL2MODULES)}
                </li>
                <li>
                    {cell(CELL3MODULES)}
                </li>
            </SubMenu>
            </NavWrapper>

    )*/
}

export default Navbar;