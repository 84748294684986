import styled from 'styled-components'

export const PostRequestForm = styled.div`
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: #ded9db;
    padding: 2px 10px;
    border-radius: 2px 2px;
    ${props => !props.expanded && `height: 100%;`}
    ${props => props.expanded && `position: fixed; top: 0; left: 0;
        z-index: 9999; width: 100%; max-width: 600px;`}

    label {
        color: #86888B;
        margin: 0;
    }

    p {
        margin: 0 5px;
        padding: 0 5px;
        font-size: 0.8em;
    }

    h3 {
        z-index: 9999;
        max-width: 100%;
        box-sizing: border-box;
        height: 90%;
        font-family: 'Merriweather', serif;
        font-weight: lighter;
        font-size: 0.8em;
        margin: 0;
        padding: 0;
        display: flex;
        flex: 1;
        justify-content: space-evenly;
        align-items: center;
        overflow: hidden;

        span {
            background-color: white;
            border-radius: 5px;
            padding: 0 5px;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
        }
    }

    button {
        position: relative;
        background-color: #CAB5B5;
        outline: none;
        border: 1px #86888B solid;
        border-radius: 5px;
        -webkit-transition-duration: 0.4s; /* Safari */
        transition-duration: 0.4s;
        text-decoration: none;
        overflow: hidden;
        cursor: pointer;

        &:after {
            content: "";
            background: #86888B;
            display: block;
            position: absolute;
            padding-top: 300%;
            padding-left: 350%;
            margin-left: -20px!important;
            margin-top: -120%;
            opacity: 0;
            transition: all 0.8s
          }
          
        &:active:after {
            padding: 0;
            margin: 0;
            opacity: 1;
            transition: 0s
          }
    }
`;