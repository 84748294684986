import styled from 'styled-components'

export const PostWrapper = styled.div`
    position: relative;
    flex: 1;
    overflow: auto;
    padding: 0;
    margin: 0;
    ${props => !!props.dimensions ? `width: ${props.dimensions[0]}px;` : `width: 100%;`}
    ${props => !!props.dimensions ? `height: ${props.dimensions[1]}px;` : ``}
    overflow: hidden;
    user-select: none;
    user-drag: none;
    /*border: solid blue 1px;*/
`