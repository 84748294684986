import PortfolioChatApi from "./PortfolioChatApi"



export const getThreadByUser = (user) => {

    let newThreadExt = "newThreadToName/" + user

    return PortfolioChatApi.postForm(newThreadExt)
        .then ( response => {
            return response.id
        })
        .catch(e => {
            console.log(e)
            return -1;
        })

}