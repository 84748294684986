import React from "react";
import styled from 'styled-components'

const StyledNav = styled.nav`
    display: flex;
    min-height: ${props => props.size === 'min' ? "4vh" : "8vh"};
    margin: 0;

    ul {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        flex: 1;
        list-style-type: none;
        border: gray 1px solid;
        margin: 0;
        padding: 0;

        li {
            cursor: pointer;
            flex: 1;
            display: flex;
            text-align: center;
            padding: 0;
            margin: 0 auto;
            border: 2px gray solid;
            background-color: ${props => props.theme.colors.button};
        }
    }


`;

const StyledNewNav = styled.nav`
    display: flex;
    margin: 0;

    ul {
        display: flex;
        flex-direction: row;
        flex: 1;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            cursor: pointer;
            flex: 1;
            display: flex;
            text-align: center;
            padding: 0;
        }
    }


`;

const StyledSkinnyNav = styled.nav`
    display: flex;
    margin: 0;
    max-height: 3vh;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    flex-direction: row;
    list-style-type: none;

    ul {
        display: flex;

        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;
    }
`
StyledSkinnyNav.Logo = styled.li`
    display: flex;
    flex: 1 0 35%;
    flex-direction: row;
    align-items: center;
    justify-content: center;

`

StyledSkinnyNav.Search = styled.li`
    flex: 3 0 50%;
    max-width: 100%;
    overflow: hidden;
`

StyledSkinnyNav.Settings = styled.li`
    display: flex;
    flex 0 0 15%;
`
StyledSkinnyNav.Icon = styled.img`
    height: 100%;
    width: 45px;
    object-fit: scale-down;
    margin: auto;

    cursor: pointer;
    ${props => props.selected && 'filter: drop-shadow(0px 0px 4px '
         + props.theme.colors.primary + ');'}
`



const StyledLi = styled.li`
    flex: 1;
    display: flex;
    text-align: center;
    padding: 0;
    margin: 0 auto;
    border: 2px gray solid;
    background-color: ${props => props.theme.colors.button};
`

const StyledUl = styled.ul`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex: 1;
    list-style-type: none;
    border: gray 1px solid;
    margin: 0;
    padding: 0;
`

const Sp = styled.p`
    text-align: center;
    color: ${props => props.theme.colors.p};
    text-decoration: none;
    margin: 0;
`;

const LiDiv = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    margin: auto;
`


export {Sp, StyledNav, StyledSkinnyNav,
     StyledNewNav, StyledUl, StyledLi, LiDiv}