import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import NotificationTypes from '../data/NotificationTypes';
import { getPicByMediaId } from '../../lib/MediaApi';

import { StyledListP } from '../../styles/Text';
import { SquareThumbnail } from '../../styles/Image';
import { Bubble } from '../../styles/Cards';

import { toHowOld } from '../../lib/TextLib'
import HowOldLive from '../../components/HowOldLive';


/**
 * Handles formatting of Notification line.
 * 
 * Receives an array of events corresponding to a particular reference id, such as media or a user.
 * This collapses all the events and uses the most recent to post the line.
 * 
 * Events should only be passed here if not all events should be shown.
 * 
 * TODO:
 * 
 * Should allow expanding of the hidden events. For media, the total cost of the liking
 * may not be apparent because of the collapsed media. This could be solved by totaling all
 * events and allowing an expanded view
 * 
 *   
 * @param {*} props 
 */
const Nl = (props) => {

    const [mostRecent, setMostRecent] = useState({})
    const [type, setType] = useState("")
    const [thumb, setThumb] = useState(null)

    useEffect( () => {
        setMostRecent( props.events.reduce( (a,b) => Date.parse(a.created) > Date.parse(b.created) ? a : b ) )
        
    },[])

    useEffect( () => {
        setType (Object.values(NotificationTypes).find(e => e.name === mostRecent.type) || { name: "undefined"})
        if (props.showThumb) {
            getPicByMediaId(Number(mostRecent.referenceId))
            .then ( i => setThumb(URL.createObjectURL(i)))
            .catch( e => {
                if ( !!e.response && e.response.status === 418) {
                    setThumb('/images/s.jpg')
                } else {
                    console.log("Unknown error: ", e)
                }
            })
        }
    },[mostRecent])
    
    return (
        <Bubble 
            emphasize={!mostRecent.seen}
            right={mostRecent.fromId === props.user}
            minWidth={'40%'}> 
            <div>     
            <StyledListP>    
            {!type.noThumb && props.showThumb && <a onClick={() => props.onMediaClick(Number(mostRecent.referenceId))}><SquareThumbnail 
                src={thumb}
                width={30}
                height={30} /> </a>}
            
                {mostRecent.fromId === props.user && type.fromUserString}
                {mostRecent.fromId === props.matchUser.username && props.matchUser.title + " " + type.toUserString + ` {${props.referenceId}}`}                
                { false && `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of`}
               
            </StyledListP>
            <StyledListP>
                {mostRecent.created && <HowOldLive now={mostRecent.created} />}
                {(mostRecent.fromId === props.user && mostRecent.seen === true) && <> Seen: <HowOldLive now={mostRecent.seenTime} /></>}
            </StyledListP>
                        
            </div>
        </Bubble>
    )
}

Nl.propTypes = {
    events: PropTypes.array.isRequired,
    user: PropTypes.string.isRequired,
    showThumb: PropTypes.bool
}

export default Nl;