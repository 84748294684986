import React, {useState, useEffect, useRef} from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { PostRequestForm, PostWrapper } from '../../styles/Forms'
import { Slider } from '@material-ui/core';
import { LeftArrowButton, DownArrowButton, SelectButton} from '../../styles/Buttons'
import styled from 'styled-components'
import LocationSearch from './LocationSearch'
import loveMireApi from '../../lib/loveMireApi';

import { MatchesContext } from '../../App';

const PostR = (props) => {

    const matches = React.useContext(MatchesContext)


    const [updating, setUpdating] = useState(false)

    const [ageRange, setAgeRange] = useState([18,80])
    
    const [disabled, setDisabled] = useState(false)

    const [expanded, setExpanded] = useState(false)

    const [distance, setDistance] = useState(15000)

    const [mySettings, setMySettings] = useState({})
 
    const handleSubmit = (values) => {
        
        //values['addressString'] = addressString
        //values['lat'] = latlng[0]
        //values['lng'] = latlng[1]

        //console.log("%c PostRequest submitting: ", "color: green", values)
        //props.handleSubmit(values)

        console.log(values)
        matches.updateSettings(values)
        matches.apiPutSettings()  // can't pass it here since may not be full settings
    }

    const withDefaults = (v) => {
        let values = {...v}
        values.ageMin = values.ageMin === 0 ? 18 : values.ageMin;
        values.ageMax = values.ageMax === 0 ? 88 : values.ageMax;
        values.addressString = values.addressString === null ? "World" : values.addressString
        console.log(values)
        return values
    }



    /************
     * USE EFFECTS
     ************/

    useEffect( () => {

        
    }, [expanded])

    useEffect( () => {

        /*
        if ( "geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition( (p) => alert(p.coords.latitude + "," + p.coords.longitude) )
        }*/

        matches.registerSettingsCallBack( s => setMySettings(withDefaults(s)), "POSTREQUEST")
        //callGetFeed();
        matches.apiGetSettings()

        return () => {
            matches.unRegisterSettingsCallBack("feed")
        }

    },[])

    useEffect( () => {

        setAgeRange([mySettings.ageMin || 18, mySettings.ageMax || 80])
        console.log("%c POST REQUEST", "color: purple", mySettings, mySettings.ageMin || 18)
        setDisabled(props.disabled)

        /*if (props.postRequest.lat && props.postRequest.lng) {
            setLatlng([props.postRequest.lat, props.postRequest.lng])
        }

        if (props.postRequest.addressString) {
            setAddressString(props.postRequest.addressString)
        }*/
        
    }, [mySettings])

    return (<PostRequestForm expanded={expanded} onClick={() => {
            if (!expanded) {
                setExpanded(true)
            }}}>
            {!expanded && 
                <h3>
                    <span>{mySettings.addressString}</span>
                <LeftArrowButton onClick={() => setExpanded(true)} /></h3>
            }

            {expanded && <div style={{float: "right", marginRight: '10px'}}>
                <DownArrowButton onClick={() => setExpanded(false)} />
            </div> }

            {expanded && <Formik
                initialValues={mySettings}
                onSubmit={ (values, {setSubmitting}) => {

                    console.log(values, props)
                    handleSubmit(values)
                    setUpdating(true)
                }}>
                {
                    ({values, handleChange, handleBlur, setFieldValue, updating}) => (
                        <Form>
                            <LocationSearch
                                postRequest={values}
                                onChange={ (addressString, [lat, lng]) => {
                                    setFieldValue('addressString', addressString)
                                    setFieldValue('lat', lat)
                                    setFieldValue('lng', lng)
                                }}
                                />
                            <input type="hidden" name="gender" values={0} />
                            <input type="hidden" name="interestedIn" values={1} />

                            <label>                                
                                
                            </label>
                            <label>
                                <input 
                                    type="text" name="range" 
                                    size="10"
                                    defaultValue="15k miles" disabled />
                            </label>
                            <label>
                            <div style={{display: "flex", width: "80%"}}>
                                <p>{values['ageMin']}</p>
                            <Slider
                                value={[values.ageMin, values.ageMax]}
                                onChange={(e,v) => {
                                    setFieldValue('ageMin', v[0])
                                    setFieldValue('ageMax', v[1])
                                }}   
                                min={18} max={100}
                                step={1}
                                />
                                <p>{values['ageMax']}</p>
                            </div>
                            </label>
                            {false && <><label>
                                <input type="checkbox" name="gender" value="0" />
                                Male
                            </label>
                            <label>
                                <input type="checkbox" name="gender" value="1" />
                                Female
                            </label></>}

                            <button type="submit" disabled={updating || disabled}>New Search</button>
                        </Form>)}
                    </Formik>}
        </PostRequestForm>);
    
}

PostR.defaultProps = {
    __TYPE: "PostRequest"
}

export default PostR