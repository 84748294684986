import React from 'react'
import Modal from '../styled/MediaModal'

export default function one (e) {

    const dems = {

    }
    return <Modal>
        <Modal.H2>Double Liked!</Modal.H2>
        <Modal.P>Cost: 1</Modal.P>
        <Modal.P>Click again to Admire! User will receive a notification!</Modal.P>
        <Modal.P>Undo: Refund available for 24 hours.</Modal.P>
        {e.children}
    </Modal>

}