import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import LocationSearch from '../../feed/components/LocationSearch'
import { TextArea, SelectButton } from 'lovemire-components'

import { NewUserWizardWrapper } from '../NewUserWizard'

const DescriptionSelection = (props) => {

    useEffect( () => {
        // Set default location
        //props.onChange({addressString: "World"})
    },[])

    return <NewUserWizardWrapper>
        <NewUserWizardWrapper.Col>
            <h2>About You:  </h2>
            <p>Your hopes, dreams, and ambitions! (optional)</p>
            <TextArea onChange= { e => props.onChange({aboutMe: e})}>
                {props.profile.aboutMe}
            </TextArea>
            <h2>What are you looking for?  </h2>
            <p>Your ideal man... or just more about you! (optional)</p>
            <TextArea onChange= { e => props.onChange({lookingFor: e})}>
                {props.profile.lookingFor}
            </TextArea>
            <h2>Your location</h2>
            <p>Your home base. (optional)</p>
            <LocationSearch 
                postRequest={{addressString: props.profile.addressString ? props.profile.addressString : "World"}}
                onChange={ e => props.onChange ({ addressString: e }) } />
            {props.finished && <SelectButton
                selected={true}
                onClick={() => {
                    if (props.profile.addressString) props.doFinished()
                    else props.doFinished({addressString: "World"})
                }}
            >Ok!</SelectButton> }
        </NewUserWizardWrapper.Col>
    </NewUserWizardWrapper>


}

export default DescriptionSelection