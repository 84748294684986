import styled from 'styled-components';

export const LikeDiv = styled.div`
    border-radius: 5px;
    border: 1px black solid;
    position: absolute;
    top: 10px;
    left: 10px;
    outline: none;
    border: #707070 2px solid;
    border-radius: 10%;
    background-color: rgba(255, 255, 255, 0.85);
    padding: 10px 15px;

    p {
        font-family: 'Roboto', sans-serif;
        font-size: 0.8em;
        color: #8A8A8A;
        width: 100%;
        text-align: right;
        padding: 2px;
        margin: 0;
    }

    span {
        font-family: 'Segoe UI', sans-serif;                                                
        color: #C28383;
        font-size: 0.9em;
        text-align: left;
        clear: both;
        float: left;
        margin: 0 5px;
        padding: 0;
    }

    button {
        background-color: #E4DADA;
        border: #707070 solid 2px;
        border-radius: 8px;
        color: #8A8A8A;
        font-family: 'Roboto', sans-serif;
        margin: 0;
        padding: 5px;
    }
`;