import React from 'react'
import styled from 'styled-components'
import {SelectButton} from '../../../styles/Buttons'
import PropTypes from 'prop-types';

const Sb = (props) => {

    const aspectStrings = {
        1: "1:1",
        1.7778: "16:9",
        0.5625: "9:16",
        0.75: "9:14"
    }

    //console.log(props)
    return (
        <SelectButton {...props}>
            {aspectStrings[props.aspect]}
        </SelectButton>
    )
}

//Sb.propTypes = {
 //   aspect: PropTypes.number.isRequired
//}

export default Sb