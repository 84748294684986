import loveMireApi from './loveMireApi'
/**
 * Main feed retrieval. Hits only the post service.
 * Returns a Promise with the feed results
 */

export const getFeed = (payload) => {

    console.log("call getFeed:", payload)

    // here we update the payload as necessary
    const defaultPayload = {
        "username": "username",  // the requesting username (until JWT?)
    }

    payload = {...payload, ...defaultPayload}
    console.log(payload)

    return loveMireApi.postJson('feed', payload)


}
