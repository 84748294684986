import React, {useState, useEffect, useCallback} from 'react';
import Cropper from 'react-easy-crop'
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from '@material-ui/core/Slider'
import Grid from '@material-ui/core/Grid';
import { getRotatedImage, getCroppedImg } from './CanvasUtils';
import { SelectButton, HideButton } from '../../styles/Buttons';
import {StyledListP, StyledFineP} from '../../styles/Text';

import { PostUpdateContext } from './EditPost';

const initialCropSettings = {
    x: 0,
    y: 0
};

const ORIENTATION_TO_ANGLE = {
    '4': 180,
    '3': 180,
    '6': 90,
    '8': -90,
    '7': 90,
    '5': -90
  }

const StyledWrapper = styled.div`
    width: 100%;
    max-width: 260px;
    box-sizing: border-box;
    padding: 20px;
    height: 400px;
    display: block;
    position: relative;
    background-color: #f8f8ff;

    @media (max-width: 600px) {
        padding: 0;
        max-width: 100%;
    }

    @media (max-height: 800px) {
        height: 300px;
    }
`;

const StyledWrapperAuto = styled.div`
    width: 100%;
    max-width: 260px;
    box-sizing: border-box;
    padding: 20px;
    height: auto;
    display: block;
    position: relative;
    background-color: #f8f8ff;

    @media (max-width: 600px) {
        padding: 20;
        margin: auto;
    }
`;

/**
 * If we want no APi calls in this component, we will need to pass the image
 * back up to edit post. Seems like a good plan really, especially if we use
 * Canvas Utils in here. The EditPost wono't need to store crop information.
 * 
 * Probably need to pass up crop information though, so later if we go back to
 * serverside image cropping, a big change won't be needed here.
 * 
 * So this component should pass up an Image object with 
 * .src .img .crop .zoom .rotation
 * equals the state we will have here.
 * 
 * The Crop & Save Button won't submit, will only pass up
 * Should have a replace button as well.
 * 
 * Delete button will be in Edit Post, probably should put on thumbs
 * 
 * Possibly a HOC that handles the media object and passes up to posts.
 * 
 * 
 * @param {} props 
 * @returns 
 */
const CropImage = (props) => {

    const [image, setImage] = useState(null)
    const [completedImage, setCompletedImage] = useState(null)
    const [isActive, setActive] = useState(false)
    const [crop, setCrop] = useState(initialCropSettings)
    const [cropAreaPixels, setCropAreaPixels] = useState(null)
    const [cropAreaPercentage, setCropAreaPercentage] = useState(null)
    const [aspect, setAspect] = useState(1)
    const [zoom, setZoom] = useState(1.2)
    const [rotation, setRotation] = useState(0)
    const [debugMessage, setDebugMessage] = useState("")

    // context
    const postUpdate = React.useContext(PostUpdateContext)

    // end drop zone

    const onCropComplete = (c, cp) => {
        setCropAreaPercentage(c)
        setCropAreaPixels(cp)     
        //saveImage()   
    }

    /**
     * User hit save
     */
    const saveImage = () => {
        console.log(image, cropAreaPixels, rotation)
        //setDebugMessage("crop:" + JSON.stringify(image) + JSON.stringify(cropAreaPixels))
        getCroppedImg(image, cropAreaPixels, rotation)
            .then (ci => {
                setActive(true)
                if (!ci) alert("image is null")
                setCompletedImage(ci)        
            })
            .catch( e => {
                alert("cropped failed")
            })

    }

    // Update the image from a file
    // Reset zoom and rotation
    const updateImage = (file) => {
        console.log("Updating image from: ", file)
        setImage ( file )    
        setCrop(initialCropSettings)
        setRotation(0)
        setZoom(1)    
    }

    /**
     * Received new props. This is used to update the dims of the post.
     * As posts must have the same dems on all images.
     */
    useEffect ( () => {

        console.log("Cropper: received props", props)

        // TODO: not sure if this works, could cause state issues
        if (props.mediaData !== null) {
            updateImage(props.mediaData)
        } else {
            updateImage(null)
        }

    },[props])

    useEffect( () => {
        if(!!completedImage) props.updateMedia(completedImage, isActive)
    }, [completedImage])

    /**
     * When the image is uploaded, this is passed up to the Post Component
     * which will offer it as the dems for the post. The dems can be
     * changed in post.
     * 
     * @param {} image 
     */
    const passRecommendedDems = (image) => {

        const dems = {
            1: dem => dem > 0.6 && dem < 1.7,
            1.7778: dem => dem >= 1.7,
            0.5625: dem => dem <= 0.6
        }
        
        let recommendedDems =
         Object.keys(dems).filter( k => dems[k](image.width / image.height))[0]

         /*
         After headache...

         This is fired before the props useeffect. So anything here should be available to the 
         use effect. Not sure if it would always be that way or not.

         The point is to have the crop outline on the whole image if it is already cropped
         for the suggested aspect.

         Probably setting the crop info should always go here and not in useeffect

         */

        // pass to props so post component knows what to recommend.
        // NOTE: Though always passed, post component only pays attention on first image

        // TODO: pass here
        console.log("TODO: passRecommendedDems", recommendedDems)
    }


    //console.log("CropImage.js: ", crop, zoom, rotation)
    return (
        <>                    
        { !!image && <>
            
            <StyledWrapper>
            <HideButton onClick={postUpdate.finished} />
                <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={props.aspect}
                    restrictPosition={false}
                    rotation={rotation}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    onRotationChange={setRotation}
                    onMediaLoaded= { (mediaSize) => props.onMediaLoaded(mediaSize)} />
            </StyledWrapper>
            <StyledWrapperAuto>
                <Grid container spacing={2} alignItems="center">
                    {!!props.children && <Grid item>
                        {props.children}
                    </Grid>}
                    <Grid item>
                        <SelectButton
                            onClick={saveImage}>
                            {props.saveMessage ? props.saveMessage : `Save`}
                            </SelectButton>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <StyledFineP>Rotate</StyledFineP>
                    </Grid>
                    <Grid item xs>
                        <Slider
                            value={rotation}
                            min={0}
                            max={360}
                            step={.5}
                            onChange={(e, rotation) => setRotation(rotation)} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <StyledFineP>Zoom</StyledFineP>
                    </Grid>
                    <Grid item xs>                        
                        <Slider
                            value={zoom}
                            min={.4}
                            max={3}
                            step={0.02}
                            onChange={(e, zoom) => setZoom(zoom)} />
                    </Grid>
                </Grid>
                              
            </StyledWrapperAuto>
            </>         
                
            }
            {/*<div>{debugMessage}</div>
            {!!completedImage && <img style={{width: "400px", border: "1px blue solid"}} src={URL.createObjectURL(completedImage)} />}
            {!completedImage && <p>ci: {JSON.stringify(completedImage)}</p>}
            <img style={{width: "400px"}} src={image} />*/}
        </>
    ) 
}

CropImage.propTypes = {
    updateMedia: PropTypes.func.isRequired
}

export default CropImage;