import React, {useState, useEffect} from 'react';
import { StyledFormFlexContainer,
    StyledProfileRow,
    StyledProfileFlexContainer,
    StyledProfileRightColumn, 
    StyledProfileLeftColumn, 
    ProfileWrapper } from '../../styles/profile/Wrappers';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField, Select, MenuItem } from "@material-ui/core";
import { getSettings, saveSettings } from '../../lib/SettingsApi'
import CoverSave from '../../navigation/CoverSave'
import styled from 'styled-components'

import { CollaspableMenu, Switch } from 'lovemire-components'

import { DataContext, MatchesContext } from '../../App';
import { Spinner } from '../../styles/post/Images';

import { SettingsFlex } from '../MySettings'



/**
 * Due to adding email settings to server and ui long after notification settings,
 * there is some redundancy in this menu.
 * 
 * The email menu is copy and pasted from notifications with the settings variables
 * changed.
 * 
 * The server settings for email are simply the notification settings with 'email'
 * added in front
 * 
 * @param {*} props 
 * @returns 
 */
const Notifications = (props) => {

    const matches = React.useContext(MatchesContext)
    const [account,appSettings] = React.useContext(DataContext)


    const [settings, setSettings] = useState(null)
    const [isLoading, setLoading] = useState(true)

    const notificationsDisabled = ( email=false, all=false ) => !all ?
        !email ? !settings || (!settings.giftLevel && !settings.matchLevel && !settings.messagesLevel
        && !settings.systemLevel) ? true : false :
        !settings || (!settings.emailGiftLevel && !settings.emailMtchLevel && !settings.emailMessagesLevel
            && !settings.emailSystemLevel) ? true : false :
        !settings || (!settings.emailGiftLevel && !settings.emailMtchLevel && !settings.emailMessagesLevel
            && !settings.emailSystemLevel && !settings.giftLevel && !settings.matchLevel && !settings.messagesLevel
            && !settings.systemLevel) ? true : false
        

    useEffect( () => {

        matches.registerSettingsCallBack( s => {
            setSettings(s)
            setLoading(false)            
        }, "MYSETTINGS")

        matches.apiGetSettings()

        /*
        getSettings()
            .then ( response => {                
                setSettings(response)
                setLoading(false)
            })
            .catch (e => appSettings.error(e) )
        */

        return () => matches.unRegisterSettingsCallBack("MYSETTINGS")

    },[])

    useEffect( () => {

        if (!isLoading && !!settings) {
            setLoading(true)
            matches.apiPutSettings(settings)
        }
    },[settings])

    return (<div>
        <Formik
            enableReinitialize
            initialValues={settings ? settings : {}}
            onSubmit={ (values, { setSubmitting }) => {

            // update profile and set
            console.log("submitting", values)
            setSubmitting(false)

        }}        
        >
        {({values, handleChange, handleBlur, handleSubmit, profileUpdating, isSubmitting}) => (
            <Form>
                {/*<CoverSave show={isSubmitting || isLoading}>*/}
                <StyledFormFlexContainer>
                <SettingsFlex>
                <SettingsFlex.Row>
                    <li>
                        <label style={{flex: '1 0 120px'}}>
                            <h3>Notifications</h3>
                            {isLoading && <Spinner />}
                        </label>
                        <Switch selected={!notificationsDisabled(false, true)}
                            onClick={() => {
                                setSettings( prev => {
                                    let newSettings = {...prev}
                                    return Object.assign(newSettings , values && (values.giftLevel || values.matchLevel || 
                                        values.messagesLevel || values.systemLevel || values.emailGiftLevel || values.emailMatchLevel || 
                                        values.emailMessagesLevel || values.emailSystemLevel ) ? 
                                        {giftLevel: 0, matchLevel: 0, messagesLevel: 0, systemLevel: 0,
                                            emailGiftLevel: 0, emailMatchLevel: 0, emailMessagesLevel: 0, emailSystemLevel: 0} :
                                        {giftLevel: 1, matchLevel: 1, messagesLevel: 1, systemLevel: 1,
                                            emailGiftLevel: 1, emailMatchLevel: 1, emailMessagesLevel: 1, emailSystemLevel: 1} )                                    
                                }
                            )}} />
                    </li>
                </SettingsFlex.Row>
                </SettingsFlex>
                <SettingsFlex>
                <SettingsFlex.Col>
                    <li>
                        <label style={{flex: '1 0 120px'}}>
                            <h3>Mobile</h3>
                        </label>
                        {/* mui switch <Switch name="notification"
                            defaultChecked={!notificationsDisabled() ? true : false }
                            onBlur={handleBlur}
                            onChange={() => {
                                setSettings( prev => {
                                    let newSettings = {...prev}
                                    return Object.assign(newSettings , values && values.giftLevel && values.matchLevel && 
                                        values.messagesLevel && values.systemLevel ? 
                                        {giftLevel: 0, matchLevel: 0, messagesLevel: 0, systemLevel: 0} :
                                        {giftLevel: 1, matchLevel: 1, messagesLevel: 1, systemLevel: 1} )                                    
                                }
                            )}} /> */}
                        <Switch selected={!notificationsDisabled()}
                            onClick={() => {
                                setSettings( prev => {
                                    let newSettings = {...prev}
                                    return Object.assign(newSettings , values && values.giftLevel && values.matchLevel && 
                                        values.messagesLevel && values.systemLevel ? 
                                        {giftLevel: 0, matchLevel: 0, messagesLevel: 0, systemLevel: 0} :
                                        {giftLevel: 1, matchLevel: 1, messagesLevel: 1, systemLevel: 1} )                                    
                                }
                            )}} />
                        
                    </li>
                    <li>
                        <label>
                            <h4>Gifts</h4>
                        </label>
                        <Select name={"giftLevel"} 
                            value={values.giftLevel !== undefined ? values.giftLevel : 1}
                            onChange={ (e) => {
                                setSettings( prev => {
                                    let newSettings = {...prev}
                                    console.log(e)
                                    return Object.assign(newSettings, {giftLevel: e.target.value})
                                })
                            }} >
                            <MenuItem value={0}>None</MenuItem>
                            <MenuItem value={1}>All</MenuItem>
                            <MenuItem value={2}>Premium</MenuItem>
                        </Select>
                    </li>
                    <li>
                        <label>
                            <h4>Matches</h4>
                        </label>                        
                        <Select name={"matchLevel"} 
                            value={values.matchLevel !== undefined ? values.matchLevel : 1}
                            onChange={ (e) => {
                                setSettings( prev => {
                                    let newSettings = {...prev}
                                    console.log(e)
                                    return Object.assign(newSettings, {matchLevel: e.target.value})
                                })
                            }}                            >
                            <MenuItem value={0}>None</MenuItem>
                            <MenuItem value={1}>New Request</MenuItem>
                            <MenuItem value={2}>New Connection</MenuItem>
                        </Select>                        
                    </li>
                    <li>
                        <label>
                            <h4>Messages</h4>
                        </label>                        
                        <Select name={"messagesLevel"}
                            value={values.messagesLevel !== undefined ? values.messagesLevel : 1}
                            onChange={ (e) => {
                                setSettings( prev => {
                                    let newSettings = {...prev}
                                    console.log(e)
                                    return Object.assign(newSettings, {messagesLevel: e.target.value})
                                })
                            }}                            >
                            <MenuItem value={0}>None</MenuItem>
                            <MenuItem value={1}>All</MenuItem>
                        </Select>
                    </li>
                    <li>
                        <label>
                            <h4>System</h4>
                        </label>                        
                        <Select name={"systemLevel"}
                            value={values.systemLevel !== undefined ? values.systemLevel : 1}
                            onChange={ (e) => {
                                setSettings( prev => {
                                    let newSettings = {...prev}
                                    console.log(e)
                                    return Object.assign(newSettings, {systemLevel: e.target.value})
                                })
                            }}                            >
                            <MenuItem value={0}>None</MenuItem>
                            <MenuItem value={1}>All</MenuItem>
                        </Select>                                                
                    </li>
                </SettingsFlex.Col>
                <SettingsFlex.Col>
                    <li>
                        <label style={{flex: '1 0 120px'}}>
                            <h3>Email</h3>
                        </label>
                        <Switch selected={!notificationsDisabled(true)}
                            onClick={() => {
                                setSettings( prev => {
                                    let newSettings = {...prev}
                                    return Object.assign(newSettings , values && values.emailGiftLevel && values.emailMatchLevel && 
                                        values.emailMessagesLevel && values.emailSystemLevel ? 
                                        {emailGiftLevel: 0, emailMatchLevel: 0, emailMessagesLevel: 0, emailSystemLevel: 0} :
                                        {emailGiftLevel: 1, emailMatchLevel: 1, emailMessagesLevel: 1, emailSystemLevel: 1} )                                    
                                }
                            )}} />
                        
                    </li>
                    <li>
                        <label>
                            <h4>Gifts</h4>
                        </label>
                        <Select name={"giftLevel"} 
                            value={values.emailGiftLevel !== undefined ? values.emailGiftLevel : 1}
                            onChange={ (e) => {
                                setSettings( prev => {
                                    let newSettings = {...prev}
                                    console.log(e)
                                    return Object.assign(newSettings, {emailGiftLevel: e.target.value})
                                })
                            }} >
                            <MenuItem value={0}>None</MenuItem>
                            <MenuItem value={1}>All</MenuItem>
                            <MenuItem value={2}>Premium</MenuItem>
                        </Select>
                    </li>
                    <li>
                        <label>
                            <h4>Matches</h4>
                        </label>                        
                        <Select name={"matchLevel"} 
                            value={values.emailMatchLevel !== undefined ? values.emailMatchLevel : 1}
                            onChange={ (e) => {
                                setSettings( prev => {
                                    let newSettings = {...prev}
                                    console.log(e)
                                    return Object.assign(newSettings, {emailMatchLevel: e.target.value})
                                })
                            }}                            >
                            <MenuItem value={0}>None</MenuItem>
                            <MenuItem value={1}>New Request</MenuItem>
                            <MenuItem value={2}>New Connection</MenuItem>
                        </Select>                        
                    </li>
                    <li>
                        <label>
                            <h4>Messages</h4>
                        </label>                        
                        <Select name={"messagesLevel"}
                            value={values.emailMessagesLevel !== undefined ? values.emailMessagesLevel : 1}
                            onChange={ (e) => {
                                setSettings( prev => {
                                    let newSettings = {...prev}
                                    console.log(e)
                                    return Object.assign(newSettings, {emailMessagesLevel: e.target.value})
                                })
                            }}                            >
                            <MenuItem value={0}>None</MenuItem>
                            <MenuItem value={1}>All</MenuItem>
                        </Select>
                    </li>
                    <li>
                        <label>
                            <h4>System</h4>
                        </label>                        
                        <Select name={"systemLevel"}
                            value={values.emailSystemLevel !== undefined ? values.emailSystemLevel : 1}
                            onChange={ (e) => {
                                setSettings( prev => {
                                    let newSettings = {...prev}
                                    console.log(e)
                                    return Object.assign(newSettings, {emailSystemLevel: e.target.value})
                                })
                            }}                            >
                            <MenuItem value={0}>None</MenuItem>
                            <MenuItem value={1}>All</MenuItem>
                        </Select>                                                
                    </li>
                </SettingsFlex.Col>
                </SettingsFlex>
                </StyledFormFlexContainer>
                {/*</CoverSave>*/}
            </Form>
        )}
        </Formik>
        </div>
    )
}

export default Notifications;