

/**
 * Decides on the width and height of the Media in the feed.
 * For now uses a set width of 400, but this needs to be modified
 * to use screen info primarily.
 * 
 * @param {*} d 
 * @returns 
 */
 export const pickDims = (d) => {
    const dims = {
        1: [400,400],
        1.7778: [400, 225],
        0.5625: [400, 711],
        0.75: [400, 533]
    }

    let width = window.innerWidth > 480 ? 480 : window.innerWidth
    let calculatedHeight = dims[d] !== undefined ?
    width / d : width;

    return [width, calculatedHeight]
    //return dims[d] !== undefined ? dims[d] : dims[1]

}

