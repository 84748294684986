import React, {useState, useEffect, useCallback} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';
import {useDropzone} from 'react-dropzone';
import loveMireApi from '../../lib/loveMireApi';
import AspectButton from './components/AspectButton'
import {HideButton, SelectButton, StyledButtonRow, StyledButtonRowTopFixed, PlusButton, LeftButton, RightButton} from '../../styles/Buttons'

import { FixedGalleryWrapper, FixedPostEditorWrapper, StyledProfileFlexContainer } from '../../styles/profile/Wrappers';
import ToolTip from "../../tooltip/tooltip"
import CoverSave from "../../navigation/CoverSave"
import FullModal from '../../styles/Modals'
import PostMediaWithFind from '../../MyMatches/components/PostMediaWithFind'
import LocationSearch from '../../feed/components/LocationSearch';
import TopMenu from '../editpost/TopMenu'

import {
    useParams,
    Redirect
  } from "react-router-dom";
import ImageEdit from '../editpost/ImageEdit'
import Cs from '../../navigation/CoverSave';

import { MatchesContext, DataContext, StateContext } from '../../App';


// Post Context
// Post contains the object sent to post server
// Post Media contains array of Media object sent to Media Server
// Post Media is used to update Post object when post saved.
export const PostContext = React.createContext({})
export const PostMediaContext = React.createContext([])

const StyledFlexRow = styled.div`
    display: flex;
    border: gray 1px solid;
    align-items: center;
    justify-content: center;
`;

const StyledGalleryThumb = styled.img`
    object-fit: cover;
    overflow:hidden;
    width: 96%;
    height: 96%; 
    
`;

// If max-height is changed, see sizes below
const StyledGalleryDiv = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    max-height: 18vh;
    height: 100%;
    padding: 0 2px 0 2px;    
    
`;


/**
 * Props receives:
 * 
 * Post: Object corresponding to post dto from post service. If Post is not passed
 * or does not have an Id field, this component will first try to make a new inactive
 * post
 * 
 * NEEDS COMPLETE REWRITE!  
 * Mostly rewritten. Still need to handle the [post] useEffect better.
 * idea: create a isInitialLoad state that will fire after post is retrieved from server
 * which will then indicate the actions in current useEffect. Then can set a post useEffect
 * meant to update on any change to post (which wouldn't run if isInitialLoad) wtf crazy
 * 
 * What about seperate payload object that is used to send post updates. Then we can fire a complete
 * reload afterward. Reload all images again? blah
 * 
 * Update post state, send payload. If error in sending, reload all.
 * 
 * Api calls made when editing a post:
 * 
 * postJson('posts', {}, id) - gets the post information
 * putJson('post', {post}, id) - updates limited post information (dimensions, active)
 * postJson('newPost',{}) - creates new post and gets new id
 * getJson('postMedia', medialist) - gets complete media information from media server
 * getPic('mediaData', id) - gets the media
 * postJson('addPostMedia', media, post id) - Adds / modifies the media obj in a post (post server)
 * deletePostMedia('addPostMedia', 'post.id/media.id') - deletes media from a post
 * postJson('setActivePost', {}, post.id) - Makes post visible
 * putForm('postMedia', form data) / postForm('postMedia', form data) - Uploads image to media server (frontend then adds media to post)
 * 
 * @param {*} props 
 */
const EditPost = (props) => {

    const matches = React.useContext(MatchesContext)
    const [account, appSettings] = React.useContext(DataContext)

    const [loadingPost, setLoadingPost] = useState(true)
    const [isActive, setPostActive] = useState(true)
    const [post, setPost] = useState({})    // whole post
    const [selected, setSelected] = useState(-2)     // the media being edited
                                                    // corresponds to post.mediaList
                                                    // -2 means a new image from initial load
                                                    // -1 means a new image requested

    const [tipName, setTipName] = useState(null)
    const [aspects, setAspects] = useState ( {
        1: true,
        1.7778: false,
        0.5625: false,
        0.75: false
    })  // aspects, only one should be true. Used to set desired aspect ratio for whole post

    // Post Modal State
    const [showPostMediaModal, setShowPostMediaModal] = useState(true)
    const [modalMediaId, setModalMediaId] = useState(null)
    const [modalPostId, setModalPostId] = useState(null)

    const aspectRecommended = {
        1: dim => dim > 0.8 && dim < 1.7,
        1.7778: dim => dim >= 1.7,
        0.5625: dim => dim < 0.6,
        0.75: dim => dim >= 0.6 && dim <= 0.8
    }
    
    // Used for determining if post data, other than media list, has been modified
    // use effect will save it when modified, and a div will show saving
    const [isPostInfoModified, setPostInfoModified] = useState(false)


    let {id} = useParams()


    const toggleAspect = (dims) => {

        if (Number(getAspect()) !== Number(dims) && (selected > 0 || selected === -1)) {
            console.log(getAspect(), dims)
            setTipName("dims_change")
        }
        let k = Number(dims)
        const newAspect = {
            1: (k === 1),
            1.7778: (k === 1.7778),
            0.5625: (k === 0.5625),
            0.75: (k === 0.75)
        }
        setAspects(newAspect)
        setPostInfoModified(true)
        setPost( prev => {
            let newPost = {...prev}
            newPost.dimensions = k
            return newPost
        })
    }

    const getAspect = () => {
        return Object.entries(aspects).find ( ([k,v]) => v)[0]
    }

    

const thumbSize = () => {

    let bh = "17vh"

    const sizes = {
        1.7778: [`calc(0.8*${bh} * 1.7778)`, `calc(0.8*${bh})`],
        0.5625: [`calc(0.8*${bh} * 0.5625)`, `calc(0.8*${bh})`],
        0.75: [`calc(0.8*${bh} * 0.75)`, `calc(0.8*${bh})`],
        1: ['calc(0.8*19vh)', 'calc(0.8*19vh)']
    }

    let a = Number(getAspect())

    console.log(a, Object.keys(sizes).map(k => Number(k)))
    if (Object.keys(sizes).map(k => Number(k)).includes(a)) {
        return sizes[a]
    } else {
        return sizes[1]
    }
}

    /**
     * This updates the Post server with the new media after the media
     * is saved to media service. 
     * 
     * This should be replaced with event driven solution.
     */
    const addPostMedia = (media) => {

    console.log(post)
    loveMireApi.postJson('addPostMedia', media, post.id)
        .then ( response => {
            console.log(response)
            // since successful, add it to medialist
            setPostInfoModified(true) // tells post useffect to save to post server
            setPost( prevPost => {
                let newPost = {...prevPost}
                media.img = null;       // used to force a pic download
                if (prevPost.mediaList.find( m => m.id === media.id)
                    === undefined) { // add since doesn't exist (was new media)
                    newPost.mediaList.push(media)
                } else {
                    // replace media so update is filtered down
                    newPost.mediaList = newPost.mediaList.map ( ml => 
                        ml.mediaId === media.id ? {...media} : ml)
                }                
                return newPost;
            })

        })
        .catch( e => console.log(e.response))    
    }

    /**
     * Calls post service to delete media from the post.
     * 
     * @param {*} media 
     */
    const deletePostMedia = (media) => {

        console.log("Deleted media from post (" + post.id + ")")
        loveMireApi.deletePath('addPostMedia',`${post.id}/${media.mediaId}`)
            .then ( () => {
                setPost ( prevPost => {
                    let newPost = {...prevPost}
                    newPost.mediaList = newPost.mediaList.filter ( ml =>
                        ml.mediaId !== media.mediaId)
                    return newPost
                })
            })
            .catch ( e => console.log(e.response))
    }

    const setActive = () => {

        setPostInfoModified(true)
        setPost( prev => {
            let newPost = {...prev}
            newPost.active = !prev.active
            return newPost
        })
        
        
        /*console.log("Setting active: ", post)
        loveMireApi.postJson('setActivePost', {}, post.id)
            .then( response => {
                setPostActive(response.active)
            })
            .catch( e => console.log(e.response))
            */
    }


    /**
     * Grabs compplete media information so hidden requirements / etc are available
     * called by useeffect after post is grabbed
     * 
     *  **Don't send post from state to this method**
     */
    const updateMediaList = (newPost) => {

        let ml = newPost.mediaList.map( m => m.mediaId)

        // Grab new medialist
        if (ml.length > 0 ) {
        loveMireApi.getJson('postMedia',ml)
            .then ( response => {
                newPost.mediaList = newPost.mediaList.map ( ml => response.find ( e => e.mediaId === ml.mediaId))
                setPost(newPost)
                setLoadingPost(false)
            })
            .catch ( e => console.log(e,e.response))
        } else {
            newPost.mediaList = []
            setPost(newPost)
            setLoadingPost(false)
        }
    }

    const swapMediaPosition = (e, from, to) => {
        e.preventDefault()
        e.stopPropagation()

        if (from < post.mediaList.length && to < post.mediaList.length) {
            setPostInfoModified(true)
            setPost( prev => {
                let newPost = {...prev}
                newPost.mediaList = [...prev.mediaList]

                let temp = newPost.mediaList[to]
                newPost.mediaList[to] = newPost.mediaList[from]
                newPost.mediaList[from] = temp
                return newPost
            })
        }
    }

    const getMediaData = (dataId) => {

        let image = loveMireApi.getPic('mediaData', dataId)

        console.log(image)
    }

    /**
     * Receives an object from react-easy-crop that specifies the
     * natural width and height of a newly updated image.
     * 
     * s corresponds to the active media.
     * -2 is first image
     * -1 is a new image
     * 0+ is replacing an existing image.
     * 
     * If image is -2, post dims can be automatically set.
     * 
     * @param {number} s
     * @param {object} mediaSize 
     */
    const analyzeNewMediaDims = (s, mediaSize) => {

        console.log("%c Media Info: ", "color: green", mediaSize)
        let dims = aspectRecommended
        let recommendedDims =
         Object.keys(dims).filter( k => dims[k](mediaSize.naturalWidth / mediaSize.naturalHeight))[0]

        if (s===-2) {
            toggleAspect(recommendedDims)
        } 
    }


    /*******************************
     * USE EFFECTS
     *******************************/

    /**
     * On mount
     * 
     * Calls post server to get object. If props has post object, will
     * use Id to make call.
     * 
     * If props.post.id doesn't exist, will call newPost endpoint
     */
    useEffect ( () => {

        console.log("EditPost useEffect: ", id, post)
        if ( !!id ) {
            // try to get id from url
            loveMireApi.postJson('posts',{},id)
                .then ( response => {
                    let originalResponse = {...response[0]}
                    console.log('%c Post Response: ', 'background: #222; color: #bada55', originalResponse)
                    if (response[0].account.username === matches.getUser()) {
                        updateMediaList(response[0])
                        setPostActive(response[0].active)
                        toggleAspect(response[0].dimensions)                    
                    } else {
                        appSettings.error({message: `Attempt to Edit Post that user (${matches.getUser()}) doesn't own!`})
                    }
                })
        } else if (!props.post || !props.post.id) {
            // get new Media
            loveMireApi.postJson('newPost',{})
                .then ( response => {
                    console.log(response)
                    updateMediaList(response)
                    console.log('%c WARNING: ', 'background: black, color: red', "redirection", props.post)
                    window.location.href = window.location.href + '/' + response.id                    
                })
            .catch ( e => console.log(e,e.response))
        } 
    },[])

        
    /**
     * Used to update mediaData
     * loops through medialist downloads the image
     * if it doesn't exist
     * 
     * This could be fired twice when it updates post with the media data
     * but won't make an api call again if all media data is saved.
     */
    useEffect( () => {

        console.log("EditPost: useEffect [post] START", isPostInfoModified)

        // updates info. If post data is updated, must also update
        // isPostInfoModified in order to trigger an api update and
        // change UI to indicate saving
        if (isPostInfoModified) {
            // true: Save post info
            setPostInfoModified(false)
            let payload = {...post}
            payload.dimensions = getAspect()
            console.log("%c SENDING: ", "color: green", payload)
            loveMireApi.putJson('post', payload, `${post.id}`)
                .then ( response => {
                    console.log("%c updated: ", "color: purple", response)
                    appSettings.message(`Post updated!`)
                })
                .catch( e => appSettings.error(e))
        }


        let newPost = {...post}
        let updated = false;
        if (!!newPost.mediaList) {
            let requests = newPost.mediaList.map ( m => {
                console.log(m)
                if ( !m.img || m.img === null ) {
                    updated = true;
                    return loveMireApi.getPic('mediaData', m.dataId)
                        .then ( response => {
                            m['img'] = URL.createObjectURL(response)
                            return m;})
                }
                else return Promise.resolve(m)
            })

        Promise.all( requests )
            .then ( result => {
                if (updated) {
                    newPost.mediaList = result;
                    setPost(newPost)

                    // if selected is -1 and we updated. It means a new image was added
                    if (selected === -1) setSelected(newPost.mediaList.length -1)
                    // if selected is -2, it means we did initial load
                    if (selected === -2) setSelected(0)
                }
            })
        }

    }, [post])

    useEffect( () => {
        console.log("Aspected toggled on selected image: ", selected)
        console.log("TODO: Provider user tooltip.")
    }, [aspects])


    /**
     * Simple update for now.
     * 
     * TODO: handle failed api put
     */
    useEffect ( () => {

        console.log(post)


    }, [isPostInfoModified])

    /**
     * Setup edit post components. With a big screen, these are spread at the top.
     * On a small screen, they are placed in an expandable top menu.
     * 
     */

    let barComponents = (
            <StyledButtonRowTopFixed>
                {(!!post.mediaList && post.mediaList.length > 0) && <><SelectButton 
                    onClick={ () => {
                    setShowPostMediaModal(true);
                    setModalPostId(post.id);
                    }}>
                        <p>View Post</p>
                </SelectButton>
                <SelectButton selected={!post.active} onClick={() => setActive()}>
                    <p>{ post.active ? <p>Post Live!</p> : "Publish!" }</p></SelectButton>
                </>}
                <div>{isPostInfoModified ? <p style={{fontSize: "0.5em"}}>Saving...</p> : <></>}</div>
            </StyledButtonRowTopFixed>);

    let dropComponents = (
        <>
        <StyledButtonRow>
                {Object.entries(aspects).map ( ([k,v]) => {
                    return <div key={k} onClick={ (event) => toggleAspect(k) } >
                        <AspectButton                         
                        aspect={Number(k)}
                        selected={v}
                         /></div>
                })}
            </StyledButtonRow>
            
            
            
            <StyledFlexRow>
                <p>Location of Post: </p>
                <LocationSearch 
                    postRequest={{addressString: post.addressString}}
                    onChange={(as, lat, lng) => {setPost(prev => {
                        let newPost = {...prev}
                        newPost['addressString'] = as;
                        newPost['lat'] = lat
                        newPost['lng'] = lng
                        return newPost
                    })}} />
            </StyledFlexRow>
        </>
    )


    console.log(getAspect(), selected, post, isActive)
    if (false) {
        return (<>            <StyledButtonRow>
            {Object.entries(aspects).map ( ([k,v]) => {
                return <div key={k} onClick={ (event) => toggleAspect(k) } >
                    <AspectButton                         
                    aspect={Number(k)}
                    selected={v}
                     /></div>
            })}
        </StyledButtonRow>
            
        <ImageEdit aspect={aspects} media={{}} updatePost={ () => console.log("update test post")} /></>)
    }
    if (!loadingPost) {
        return (<>
            <FixedPostEditorWrapper> {/*div margintop: 120px for large screens*/}
            <ToolTip tipName={tipName}>
            <CoverSave show={isPostInfoModified}>
            {showPostMediaModal && (modalMediaId || modalPostId) &&
                    <FullModal onClick={() => {setShowPostMediaModal(false);}}>
                        <PostMediaWithFind 
                            postId={modalPostId}
                            mediaId={modalMediaId}
                            showDrafts />
                        <HideButton onClick={() => setShowPostMediaModal(false)} />
                    </FullModal>}

            {window.innerWidth > 600 && <>{barComponents}{dropComponents}</>}
            {window.innerWidth <= 600 && <TopMenu 
                topBar={barComponents}
                dropDown={dropComponents}
                />}
            

            
            <StyledFlexRow>
                {/*<CropImage 
                    media={ selected >=0 ? post.mediaList[selected] : {}}
                    post={post}
                    aspect={aspects}
                    updatePost={ (m) => addPostMedia(m)}
                deleteMediaFromPost= { (m) => deletePostMedia(m) } />                */}
                <ImageEdit 
                    media={ selected >=0 ? post.mediaList[selected] : {}}
                    selected={selected} // really just to force an update
                    post={post}             
                    aspect={aspects}
                    onMediaLoaded={(s, size) => analyzeNewMediaDims(s,size)}
                    updatePost={ (m) => addPostMedia(m)} />
            </StyledFlexRow>
            </CoverSave>
            </ToolTip>
            </FixedPostEditorWrapper>
            
            <FixedGalleryWrapper> {/*StyledFlexRow for large screens*/}
                {post.mediaList && post.mediaList.map ( (media,i) => 
                    <StyledGalleryDiv key={i + media.dataId} onClick={() => { setSelected(i); }}>
                        <div style={{width: thumbSize()[0], height: thumbSize()[1]}}>
                        <StyledGalleryThumb 
                            src={media.img} 
                            onClick={() => setSelected(i)} />                        
                        <HideButton onClick={ () => deletePostMedia(media)} />
                        </div>
                        {/*<div style={{display: "inline-block", verticalAlign: "top",  padding: 0, height: "20%"}}>
                            {i !== 0 && <LeftButton onClick={ (e) => swapMediaPosition(e,i,i-1)} />}
                            {i !== post.mediaList.length-1 && <RightButton onClick={ (e) => swapMediaPosition(e,i,i+1)} />}
                        </div>*/}
                    </StyledGalleryDiv>
                )}
                <StyledGalleryDiv 
                    aspect={Number(getAspect())}
                    onClick={() => {console.log("selected"); 
                    if(post.mediaList.length > 2) setTipName("suggest_hidden"); 
                        setSelected(-1);} }>
                    <div style={{margin: '1px 0 1px 0', height: '95%', width: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <PlusButton onClick={() =>  setSelected(-1)} />
                    </div>
                </StyledGalleryDiv>
            </FixedGalleryWrapper>
            
        </>

        )
    } else {
        // loading post
        // If new post, creating new post
        return (
            <p>Loading...</p>
        )
    }
}

export default EditPost