import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import likeTypes from './data/LikeTypes';

const StyledDotsPosition = styled.div`
    position: absolute;
    top: 10px;
    left: ${props => props.self ? `50%` : `5%`};
`
const StyledDot = styled.div`
    border-radius: 50%;
    background-color: hsl(329, ${props => {
        if (props.isHidden) return "0"
        else return props.likeCount > 0 ? Math.min((props.likeCount / 4) * 100, 100) : 0}
    }%,80%);
    border: ${props => {
        if (props.isHidden && props.active) return "2.4px solid green"
        else if (props.isHidden) return "0.9px solid green"
        else if (props.active) return "2.1px solid white"
        else return "0.5px solid white"
    }};

    display: inline-block;
    width: .8em;
    height: .8em;
    color: gray;
    font-size: 0.8em;
`;


const Sd = (props) => {

    const dots = props.mediaList.map ( (media,i) => {

        return <StyledDot 
                key={media.mediaId}
                likeCount={media.likeCount}
                isHidden={!!props.hidden[media.dataId]}
                active={i===props.activeMedia}
                onClick = {() => props.onClick(i)}>

                </StyledDot>
    })


    return (
        <StyledDotsPosition self={props.self}>
            {dots}
        </StyledDotsPosition>
    )
}

Sd.propTypes = {
    mediaList: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired
}

export default Sd;