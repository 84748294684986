import './App.css';
import Feed from './feed/Feed';
import React, {useState, useEffect} from 'react';
import Navbar from './navigation/Navbar'
import AccountBar from './navigation/AccountBar'
import loveMireApi from './lib/loveMireApi';
import EditPost from './MyProfile/media/EditPost'
import MyProfile from './MyProfile/MyProfile'
import MyMatches from './MyMatches/MyMatches'
import RelativeWrapper from './styles/RelativeWrapper'
import ErrorModal from './ErrorModal'
import MessageModal from './navigation/MessageModal'
import ToolTip from './tooltip/tooltip'
import {ErrorBoundary} from 'react-error-boundary'
import Matches from './data/Matches'
import {Sse} from './lib/SseApi'
import {getPicByMediaId} from './lib/MediaApi'
import ChatApp from './Chat/App'
import Main from './Main/Main'
import MySettings from './MySettings/MySettings'


import { useSpring, animated } from 'react-spring'
import './data/firebase.js'

import { ThemeProvider } from 'styled-components';
import { Standard } from './themes/Standard'

import NewEditPost from './MyProfile/editpost-new/EditPost'

// Public routes
import WelcomeToFlirvy from './Public/Home';

// Test Imports
import {FlirvySpinner }from './styles/post/Images'
import Cropper from './MyProfile/editpost/CropImage'
import TestGesture from './feed/media/components/SwipeLike'
import likeTypes from './feed/media/data/LikeTypes';
import PostRequest from './feed/components/PostRequest'
import {notificationRequest,getNotificationRequest, getChat, getFlirt} from './lib/NotificationsApi'
import ProfileBar from './Profile/ProfileBar';

import {BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


// Matches Context
export const MatchesContext = React.createContext({})

// Data Context, contains data the usually updates on load only
// [{account}, {appSettings}]
// appSettings shouldn't be confused with mySettings which are user settings from notification service
export const DataContext = React.createContext([{},{}])

// State Context, contains state that will update regularly
// [[profiles]]
export const StateContext = React.createContext([[]])

function App() {

  /**
   * Data objects
   * 
   * These are held with state but never called to set. These classes do api calls
   * and push data to components with registered callbacks
   */
  const [matches, setMatches] = useState(null)

  // State Context
  const [profiles, setProfiles] = useState([])
  const [onlineUsers, setOnlineUsers] = useState([])
  const [connectionsList, setConnectionsList] = useState([])
  const [unSeenMessagesPerUser, setUnSeenMessagesPerUser] = useState({})
  const [myMatches, setMyMatches] = useState({to: [], from: []})
  
  // Data Context
  const [account, setAccount] = useState({})

  const [errorState, setErrorState] = useState(false)

  /** Settings */
  const [testWindow, setTestWindow] = useState(false)
  const[messageModal, setMessageModal] = useState("")
  const[messageVersion, setMessageVersion] = useState(1)
  const[tipName, setTipName] = useState("welcome")


  /** Spring, to use? */
  const pSpring = useSpring({to: {opacity: 1}, from: {opacity: 0}})
  
  /**
   * Holds the profiles the user needs here to be available to all routes.
   * 
   * Should hold a seperate list between matches and feed. matches are not recycled, feed is
   * recycled based on fifo.
   * 
   * Has been moved to matches
   * 
   * @param {[String]} requestedProfiles 
   *
  const updateProfileList = (requestedProfiles) => {

    console.log("App: call getProfiles", requestedProfiles)

    if ( requestedProfiles.length > 0 ) {

        const profileString =
            requestedProfiles.join(",") // map to string csv
            //.replace(/,\s*$/, "")            // remove trailing comma

        // TODO: avoid reloading same profiles?

        console.log(profileString)
        loveMireApi.getJson('profiles', profileString)
            .then ( (response) => {
              // NO, TODO: can't concat, have to search for dups
              // BIG redo needed 
                setProfiles( response )
            })
            .catch ( e => console.log(e) )
    }
  }*/

  useEffect( () => {
    let params = new URLSearchParams(window.location.search)
    console.log("Loading App: ")
    setMatches(new Matches())
    if ( params.has("t")) {
      console.log("Found token in url")
      loveMireApi.setToken(params.get("t"))
      window.localStorage.setItem("t", params.get("t"))
    } else if (window.localStorage.hasOwnProperty('t')) {
      loveMireApi.setToken(window.localStorage.getItem("t"))
    } else {
      //loveMireApi.setToken("eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJUZXN0VXNlciIsImV4cCI6MTY1OTg3ODIxNX0.lJFRg85RTeQQ9hIhnwvrK2bYh-sQTFPyBviMuRj3V3UNwojZ0jf3lFpwVlycEe1S5PsbQJnghAlnqI3Cj9i0Gw")
    }

  },[])

  /**
   * USER AUTH 
   */

  useEffect( () => {
    if(matches) {

    // Test code here

    /*loveMireApi.getPic('mediaData',"61e17cafc965c9727bb31a5a")
      .then ( r => {
        setRawTestImage(r)
        setTestImage(URL.createObjectURL(r))
      })*/

    Sse(matches)   

    // Tells matches object to get new object and sets a callback to finish setting up
    // after we have username
    matches.apiGetAccount ( account => {
      
      setAccount(account)      
      matches.registerAccountsCallBack ( r => setAccount(r), "APP")
    })

    // TO: From:
    matches.registerConnectionsCallBack( r => setMyMatches(r), "APP")

    // Any new profiles needed should be set in top level profiles cache
    matches.registerProfilesCallBack ( r=> setProfiles(r), "APP")


    // All online users
    matches.registerOnlineCallBack( r => setOnlineUsers(r), "APP")

    // notifications list, updates profile bar
    matches.registerNotificationsCallBack ( (r,l) => setConnectionsList(prev => {
      return [...l]
    }), "APP")

    // Unseen messages list
    matches.registerUnSeenMessagesCallBack( r => setUnSeenMessagesPerUser(prev => {
      return{...r}
    }), "APP")

        // TESTING
        matches.setProfilesArray(['TestUser','TestUser2', 'Model7'])
        matches.apiGetProfiles()
        matches.apiGetConnections()
        matches.apiGetOnlineUsers(['TestUser2'])
        // END TESTING
    
    return () => {
      matches.unRegisterOnlineCallBack("APP")
      matches.unRegisterProfilesCallBack("APP")
      matches.unRegisterAccountsCallBack("APP")
      matches.unRegisterNotificationsCallBack("APP")
      matches.unRegisterUnSeenMessagesCallBack("APP")
    }
  }


  }, [matches])

  /**
   * Error Handling
   */

  const handleError = (obj) => {

    setErrorState({error: obj})

  }

  /**
   * Settings object.
   * This object is passed down in composition. Contains needed information
   * and callbacks.
   */

  const settings = () => {
    return {
      //account: {...account},
      message: (m) => {
        setMessageModal(m)
        setMessageVersion(Math.random())
      },
      setTipName: (t) => {
        //setTipName(t); 
      },
      error: (m) => {
        setErrorState(m)
      }
    }
  }

  /**
   * Using Matches object is ideal for this scenario.
   * 
   * @param {*} id 
   * @returns 
   */
  const buildChatBubble = (id) => {
    console.log(matches, matches.profiles, matches.getProfiles())
    console.log(id, matches.getProfiles(), matches.getProfiles().filter ( p => p.username === id))
    return matches.getProfiles().filter ( p => p.username === id)
  }

  /*
  async function askUserPermission() {
    return await Notification.requestPermission();
  }
  function registerServiceWorker() {
    return navigator.serviceWorker.register('/lib/PushApi.js')
    .then(function(registration) {
      console.log('Service worker successfully registered.');
      return registration;
    })
    .catch(function(err) {
      console.error('Unable to register service worker.', err);
    });
  }
  registerServiceWorker().then(r => console.log(r)) */

  const [testImage,setTestImage] = useState(null)
  const [rawTestImage, setRawTestImage] = useState(null)
  
  if (testWindow) {

    // to test single component
    /*return <div style={{overflow: 'hidden', position: 'relative', border: '1px blue solid', height: '100vh'}}><TestGesture 
      current={Object.values(likeTypes).slice(0,5)} 
      available={Object.values(likeTypes).slice(5,5)}
      onSelected={ (removed, added) => console.log("Removed:", removed, "Added:", added)} />
      </div>*/

      /* Chat testing
      console.log(p)
    return <animated.div style={p}>
    <ChatApp user={account}
      matches={matches}
      profiles={profiles}
      singleThread={"Melani"} />
    </animated.div>
    */

    /* Image tests
   let testImageChange  = (e) => {
    let formData = new FormData();
    console.log(e.target.files)
    formData.append('file', new Blob( [e.target.files[0]], {type: 'image/jpeg'}),'media' );
    loveMireApi.postForm('testUpload', formData, "", "blob")
      .then( r => {
        console.log(r)
        setTestImage(URL.createObjectURL(r))
      })
   }

   let testCropImagechange = async (i) => {
     console.log("changing")
     setTestImage(URL.createObjectURL(i))
    let formData = new FormData();
    formData.append('file', new Blob([i], {type: 'image/jpeg'}),'media' );
    formData.append('crop', JSON.stringify({
      width: 100,
      height: 100,
      x: 0,
      y: 0,
      unit: '%'
  }))

    loveMireApi.postForm('testUpload', formData, "", "blob")
      .then( r => {
        console.log(r)
        setRawTestImage(r)
        setTestImage(URL.createObjectURL(r))
      })
      .catch(e => console.log(e))
   } 
   return <div>
     <input type="file" multiple accept="image/*" onChange={testImageChange} />
     

      <p>cropper:</p>
     <Cropper 
            mediaData={testImage}
            saveMessage={"Accept" }
            updateMedia={testCropImagechange}
            onMediaLoaded={ (m) => console.log(m)}>
            </Cropper>
      <button onClick={() => setTestWindow(false)}>finish</button>
      {testImage && <img src={rawTestImage} /> }
   </div>
   */


   //console.log(profiles, myMatches, matches.getUser(), account)
   return <div>
         <ThemeProvider theme={Standard}>
         <MatchesContext.Provider value={matches}>
    <DataContext.Provider value={[account,settings()]}>
    <StateContext.Provider value={{profiles: profiles, 
      onlineUsers: onlineUsers,connectionsList: connectionsList,
      myMatches: myMatches, unSeenMessagesPerUser: unSeenMessagesPerUser}}>


     {matches && profiles && myMatches && onlineUsers &&
     <ProfileBar 
      myMatches={matches.findMatches('TestUser', myMatches)}
      profile={profiles.find( pf => pf['username'] === 'TestUser2')} />}
     <button onClick={() => setTestWindow(false)}>finish</button>
     </StateContext.Provider>
      </DataContext.Provider>
      </MatchesContext.Provider>    
      </ThemeProvider> 
     </div>
  
  } else if (!matches || !matches.getUser()) {

    // User hasn't logged in
    return <ThemeProvider theme={Standard}>
      <WelcomeToFlirvy />
    </ThemeProvider>

  } else {
  return (
    <div className="App">
    
    <ThemeProvider theme={Standard}>
    <MatchesContext.Provider value={matches}>
    <DataContext.Provider value={[account,settings()]}>
    <StateContext.Provider value={{profiles: profiles, 
      onlineUsers: onlineUsers,connectionsList: connectionsList,
      myMatches: myMatches, unSeenMessagesPerUser: unSeenMessagesPerUser}}>
    <Router>
      <RelativeWrapper>
        {/*<ToolTip  />*/}
        <MessageModal messageVersion={messageVersion} message={messageModal} />
        <Navbar>
          {!!account && <AccountBar {...account} />}
          <PostRequest />
        </Navbar>
      <Switch>
      <Route path="/MyMatches">
          <div><MyMatches /></div>
        </Route>
        <Route exact path="/MyProfile/AddPost/:id"
          children={<NewEditPost />} />
        <Route exact path="/MyProfile/AddPost"
          children={<NewEditPost />} />
        <Route path={"/MyProfile"}>
          <div><MyProfile /></div>
        </Route>
        <Route path="/MySettings">
          <MySettings />
        </Route>
        <Route path="/Feed">
          <Feed />
        </Route>
        <Route exact path="/">
          <Main />
        </Route>

      </Switch>
      {!!errorState && <ErrorModal error={errorState} clearError={() => setErrorState(false) }/>}

      </RelativeWrapper>     
      {account && account.name && <ChatApp />}
      </Router>
      </StateContext.Provider>
      </DataContext.Provider>
      </MatchesContext.Provider>
      </ThemeProvider>
    </div>
  );
  }
}

export default App;
