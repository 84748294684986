import styled from 'styled-components'

export const SlideButton = styled.button`
    position: absolute;
    z-index: 1;
    border: none;
    background-color: transparent;
    width: 20px;
    height: 100%;
    outline: none;
    ${props => props.slide}: 10px;
    bottom: 50px;
    padding: 10px 2px 0px 0px;
`;

export const Arrow = styled.i`
    border: solid rgba(154, 141, 141, 0.9);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding 3px;
    transform: rotate(${props => props.deg}deg);
    -webkit-transform(${props => props.deg}deg);
`;