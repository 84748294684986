import loveMireApi from './loveMireApi'

export const getAll = () => {

    return loveMireApi.getJson('getAllNotifications');
}

export const getAllGifts = () => {

    let payload = {}

    return loveMireApi.postJson('gifts', payload)
}

export const receiveAllGifts = (user, ids) => {


    let payload = {}

    if (user) payload.fromUsers = [user]

    if (ids && ids.length > 0) payload.giftIds = ids

    return loveMireApi.postJson('receiveGifts', payload)

}

export const markSeenFromUser = (user) => {

    let notificationSeenPromise = loveMireApi.get('seenFromUser', 'json', user)
    let messagesSeenPromise = loveMireApi.putJson('markSeenByUser', {}, user)

    return Promise.all([notificationSeenPromise, messagesSeenPromise])        
            
}

export const getNotificationRequest = (payload = {}) => {

    return loveMireApi.postJson('notifications', payload)
}

export const notificationRequest = (request, user) => {

    return loveMireApi.postJson('myList', request)
        .then ( response => {
            
            if ( !Array.isArray(response) ) {
                throw new Error("Notification List Request: malformed body")
            }

            let newList = []   // Final list, reduces toId/FromId to "match", has latest date, only totals from To
            let index          // Used as placeholder
            let isTo           // is the current element to user (true) 

            response.forEach ( e => {

                if (e.toId === user) {
                    // notifications incoming. Keep unseen, total, gifts
                    isTo = true;
                    index = newList.findIndex( f => f.name === e.fromId)
                } else {
                    // notifications outgoing
                    isTo = false;
                    index = newList.findIndex( f => f.name === e.toId)
                }

                if (index === -1) {
                    newList.push({})
                    index = newList.length - 1
                }

                if (isTo) {
                    newList[index].name = e.fromId
                    newList[index].date = (!newList[index].date || new Date(e.date) > newList[index].date) ?
                        new Date(e.date) : newList[index].date;   // come back
                    newList[index].unseen = e.unseen
                    newList[index].total = e.total
                    newList[index].gifts = e.gifts
                } else {
                    newList[index].name = e.toId
                    newList[index].date = (!newList[index].date || new Date(e.date) > newList[index].date) ?
                        new Date(e.date) : newList[index].date;
                }

            })

            return newList

        })
}

/**
 * This supports multiple fetches, but does not seperate the messages per user.
 * 
 * @param {string} match 
 */
export const getChat = (match) => {

    let promises = match.map ( e => {
        return loveMireApi.getJson('chatDirect',e)
            .then( response => {
                return response.content.map (m => {
                    // add a "match" element to message
                    // message content only contains "sender" information
                    m["match"] = e
                    return m
                });
            })
            .catch(e => {return []})
    }) 

    return Promise.all (promises)
    .then ( values => {
        return values.reduce ( (a,b) => a.concat(b), [])
    })
    .catch(e => console.log(e))
}

/**
 * The matched user(s) must always come first!
 * 
 * This supports requesting mulitple matches, multiple notifications and chat at once but does not
 * seperate the notifications/messages. (TODO?)
 * 
 * Requests and sorts chat and notifications
 * 
 * @todo
 * TODO: This will need a mechanism to track and sort the pages returned by each service and 
 *  insure the latest date of a notification includes both services.
 *  For example, 20 notifications might only go back a day where 20 chats is 30 days old. More
 *  notifications would need to be requested to populate the 30 day history
 * @param {String} match 
 * @param {String} user 
 */
export const getFlirt = (m, user) => {

    let payload = {}

    payload["withUsers"] = Array.isArray(m) ? m : [m]

    let notificationsPromise = getNotificationRequest(payload)
    let chatPromise = getChat(payload["withUsers"])

    return Promise.all([notificationsPromise, chatPromise])
        .then( results => {

            // combine results and sort by created
            return results[0]
                    .concat(results[1])
                    .map( r => {
                        if (!r.created.endsWith("+00:00")) {
                            // make sure date string ends with time zone
                            r.created += "+00:00"                            
                        }
                        return r;
                    })
                    .sort( (a,b) => Date.parse(a.created) - Date.parse(b.created))

        })
        

}

/**
 * Gets list of new messages waiting from chat.
 */
export const getUnseenMessagesPerUser = (userList) => {

    return loveMireApi.getJson("getUnseenMessagesPerUser", userList)

}