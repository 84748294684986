import { useState, useEffect } from 'react';

/**
 * https://usehooks.com/useWindowSize/
 * Does this listener get created needlessly several times?
 * (uncomment console lines)
 * 
 */
export const useWindowSize = () => {

    const [windowSize, setWindowSize] = useState({
        width: undefined, height: undefined
    })

    useEffect ( () => {

        const handleResize = () => {
            setWindowSize({width: window.innerWidth, height: window.innerHeight})
        }

        //console.log("%c Resize listener created.", 'background: red; color: black;')
        window.addEventListener("resize", handleResize)
        handleResize()

        return () => {
            //console.log("%c Resize listener deleted.", 'background: red; color: black;')
            window.removeEventListener("resize", handleResize);
        }

    }, [])

    return windowSize

}