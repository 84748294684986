import React from 'react'
import StyledModal, {StyledH2, StyledP} from '../styled/modal'

export default function one (e) {

    const dems = {

    }
    return <>
    <StyledModal {...dems}>
        <StyledH2>I do Chop!</StyledH2>
        <StyledP>Step 1:</StyledP>
        <StyledP>Choose ICM or Chip Chop.</StyledP>
        <StyledP>Step 2:</StyledP>
        <StyledP>Enter the chip totals and prize totals.</StyledP>
        {e.children}
    </StyledModal>
    
    </>

}