import React, {useState, useEffect, useReducer} from 'react'
import styled from 'styled-components'
import { RadioSelection, SelectButton } from 'lovemire-components'
import { Field } from 'lovemire-components'

import { MainWrapper } from '../../Main/Main'

import {NewUserWizardWrapper} from '../NewUserWizard'

const GenderSelection = (props) => {

    const GenderSelections = [
        {
            name: 0,
            selected: props.profile.gender == 0,
            title: 'Man',
            value: 0
        },
        {
            name: 1,
            selected: props.profile.gender == 1,
            title: 'Female',
            value: 1
        }
    ]

    const InterstedInSelections = [
        {
            name: 0,
            selected: props.profile.interestedIn == 0,
            title: 'Men',
            value: 0
        },
        {
            name: 1,
            selected: props.profile.interestedIn == 1,
            title: 'Women',
            value: 1
        }
    ]

    return <NewUserWizardWrapper>
                <NewUserWizardWrapper.Col>
                    <h2>I am a: </h2>
                    <RadioSelection 
                        selections={GenderSelections}
                        select={(e) => props.onChange({gender: e})} />
                    
                    {props.profile?.gender !== -1 && <>
                        <h2>I like: </h2><RadioSelection
                        selections={InterstedInSelections}
                        select={(e) => props.onChange({interestedIn: e})} />
                        </>}
                    {props.profile?.interestedIn !== -1 && <>
                        <h2>Your birthday?</h2>
                        <p>Just give us a rough estimate!</p>
                        <Field type="date"
                            value={props.profile.birthday ? props.profile.birthday : "1999-01-01"}
                            select={e => {console.log(e)
                                props.onChange({birthday: e})
                            }}  />                        
                        </>}

                    {props.finished && <SelectButton
                        selected={true}
                        onClick={() => {
                            props.doFinished()
                        }}
                    >Ok!</SelectButton> }
                </NewUserWizardWrapper.Col>
            </NewUserWizardWrapper>
}

export default GenderSelection