import React from 'react'
import Modal from '../styled/MediaModal'

export default function one (e) {

    const dems = {

    }
    return <Modal>
        <Modal.H2>Consider Posting Secret Image!</Modal.H2>
        <Modal.P>Posting a secret image restricts viewing to those</Modal.P>
        <Modal.P>who have liked the previous media in this post.</Modal.P>
        <Modal.P>A secret image can greatly increase interaction!</Modal.P>
        {e.children}
    </Modal>

}