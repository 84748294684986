import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {ChatTypes} from '../data/NotificationTypes';
import { getPicByMediaId, getChatMediaById } from '../../lib/MediaApi';

import { StyledListP } from '../../styles/Text';
import { SquareThumbnail } from '../../styles/Image';
import { Bubble } from '../../styles/Cards';

import { toHowOld } from '../../lib/TextLib'


/**
 * Handles formatting of Notification line.
 * 
 * See NotificaitonLine 
 *   
 * @param {*} props 
 */
const Nl = (props) => {

    const [mostRecent, setMostRecent] = useState({})
    const [type, setType] = useState("")
    const [thumb, setThumb] = useState(null)

    // DRY?
    const colorPicker = () => {

        if (mostRecent.sender.name !== props.user) {

            // if match user, return gendered color
            if(props.matchUser.gender === 0) {
                return "#D4D4D4"
            } else if (props.matchUser.gender === 1) {
                return "#E4DADA"
            } else {
                return "#f7f7f7" // shouldn't ever get returned
            }

        } else return '#f7f7f7' // return neutral color for user
    }

    useEffect( () => {
        setMostRecent( props.events.reduce( (a,b) => Date.parse(a.created) > Date.parse(b.created) ? a : b ) )
        
    },[])

    useEffect( () => {
        setType (ChatTypes.find(e => e === mostRecent.type) || { name: "undefined"})
        if (mostRecent.type === 'IMAGE') {
            getChatMediaById(Number(mostRecent.id))
            .then ( i => setThumb(URL.createObjectURL(i)))
            .catch( e => {
                if ( !!e.response && e.response.status === 418) {
                    setThumb('/images/s.jpg')
                } else {
                    setThumb('/images/heart.jpg')  // TODO: error image
                    console.log("Unknown error: ", e)
                }
            })
        }
    },[mostRecent])
    
    if (Object.keys(mostRecent).length === 0) {
        return <></>
    } else {
    return (
        <Bubble 
            contain
            emphasize={!mostRecent.seen}
            bg={colorPicker()}
            right={mostRecent.sender.name === props.user}> 
            <div>    
            {thumb && <a onClick={() => props.onMediaClick(Number(mostRecent.referenceId))}><img 
                src={thumb} style={{maxWidth: '300px'}} /> </a>}
            <StyledListP>
                {mostRecent && mostRecent.content}             
                { false && `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of`}
               
            </StyledListP>
            <StyledListP>
                {/*
                {toHowOld(mostRecent.created)}
                {(!!mostRecent.sender && mostRecent.sender.name === props.user && mostRecent.seen === true) && ` Seen: ${toHowOld(mostRecent.seenTime)}`}
                */}
            </StyledListP>
                        
            </div>
        </Bubble>
    )}
}

export default Nl;