import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {NavSelectButton, NavSelectedButton} from '../styles/Buttons'
import {NavButton} from 'lovemire-components'

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    background-color: white;
    border-radius: 5px;
    font-size: calc(10px + 2vmin);
    color: white;
    align-items: top;
    justify-content: center;
    text-align: left;
    margin-bottom: 1px;
`

const MenuItem = styled.div`
    display: flex;
    flex-basis: 33%;
    padding: 2px;
    position: relative;
`

/**
 * selections format:
 *      name: String
 *      title: String
 *      children: <></> (should be absolute positioned)
 *      onClick: () => {}
 * 
 * @param {Object} props 
 */
const PMenu = (props) => {

    return <Wrapper>
        {props.selections.map ( s => <MenuItem>
            <NavButton onClick={ () => s.onClick()} selected={s.name === props.selected}>{s.title}</NavButton>
        </MenuItem>)}
    </Wrapper>

    /*
    TODO: moved to lovemire-components
    Some components here can be removed
    return <Wrapper>
        {props.selections.map ( s => <MenuItem>
            {s.name !== props.selected && <NavSelectButton onClick={() => s.onClick() }>{s.title}</NavSelectButton>}
            {s.name === props.selected && <div style={{padding: '5px', width: '100%'}}><NavSelectedButton>{s.title}</NavSelectedButton></div>}
        </MenuItem>)}
    </Wrapper>
    */
}

export default PMenu

PMenu.propTypes = {
    selections: PropTypes.array.isRequired,
    selected: PropTypes.string.isRequired
}