import React, {useState, useEffect, useCallback} from 'react';
import { useHistory } from 'react-router-dom';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';
import ExifReader from 'exifreader'
import loveMireApi from '../../lib/loveMireApi';
import AspectButton from './components/AspectButton'
import {HideButton, StyledButtonRow, StyledButtonRowTopFixed, PlusButton, LeftButton, RightButton} from '../../styles/Buttons'
import { Spinner } from '../../styles/post/Images';
import { FixedGalleryWrapper, FixedPostEditorWrapper, StyledProfileFlexContainer } from '../../styles/profile/Wrappers';
import ToolTip from "../../tooltip/tooltip"
import CoverSave from "../../navigation/CoverSave"
import FullModal from '../../styles/Modals'
import PostMediaWithFind from '../../MyMatches/components/PostMediaWithFind'
import LocationSearch from '../../feed/components/LocationSearch';
import TopMenu from './TopMenu'
import { ConfirmationModal, SelectButton } from 'lovemire-components';

import {
    useParams,
    Redirect,
    Prompt
  } from "react-router-dom";

import ImageEdit from './ImageEdit'
import AddMedia from './AddMedia'
import PostDescription from './components/PostDescription';

import { apiSaveMedia, apiGetMedia } from './bin/MediaApi'

import { MatchesContext, DataContext, StateContext } from '../../App';



// Post Context
// Post contains the object sent to post server
// Post Media contains array of Media object sent to Media Server
// Post Media is used to update Post object when post saved.
// PostUpdate contains the callbacks for updating data
export const PostContext = React.createContext({})
export const PostMediaContext = React.createContext([])
export const PostUpdateContext = React.createContext({})

const StyledFlexRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledGalleryThumb = styled.img`
    object-fit: cover;
    overflow:hidden;
    width: 96%;
    height: 96%; 
    
`;

// If max-height is changed, see sizes below
const StyledGalleryDiv = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    max-height: 18vh;
    height: 100%;
    padding: 0 2px 0 2px;    
    
`;


/**
 * Props receives:
 * 
 * Post: Object corresponding to post dto from post service. If Post is not passed
 * or does not have an Id field, this component will first try to make a new inactive
 * post
 * 
 * NEEDS COMPLETE REWRITE!  
 * Mostly rewritten. Still need to handle the [post] useEffect better.
 * idea: create a isInitialLoad state that will fire after post is retrieved from server
 * which will then indicate the actions in current useEffect. Then can set a post useEffect
 * meant to update on any change to post (which wouldn't run if isInitialLoad) wtf crazy
 * 
 * What about seperate payload object that is used to send post updates. Then we can fire a complete
 * reload afterward. Reload all images again? blah
 * 
 * Update post state, send payload. If error in sending, reload all.
 * 
 * Api calls made when editing a post:
 * 
 * postJson('posts', {}, id) - gets the post information
 * putJson('post', {post}, id) - updates limited post information (dimensions, active)
 * postJson('newPost',{}) - creates new post and gets new id
 * getJson('postMedia', medialist) - gets complete media information from media server
 * getPic('mediaData', id) - gets the media
 * postJson('addPostMedia', media, post id) - Adds / modifies the media obj in a post (post server)
 * deletePostMedia('addPostMedia', 'post.id/media.id') - deletes media from a post
 * postJson('setActivePost', {}, post.id) - Makes post visible
 * putForm('postMedia', form data) / postForm('postMedia', form data) - Uploads image to media server (frontend then adds media to post)
 * 
 * @param {*} props 
 */
const EditPost = (props) => {

    const history = useHistory()

    const matches = React.useContext(MatchesContext)
    const [account, appSettings] = React.useContext(DataContext)

    const [loadingPost, setLoadingPost] = useState(true)
    const [isActive, setPostActive] = useState(true)
    const [post, setPost] = useState({})    // whole post
    const [postMediaList, setPostMediaList] = useState([])
    const [selected, setSelected] = useState(-2)     // the media being edited
                                                    // corresponds to post.mediaList
                                                    // -2 means a new image from initial load
                                                    // -1 means a new image requested

    const [hasRecommendedDims, setHasRecommendedDims] = useState(false)
                                // Will only recommend on the first image loaded
                                // modified in postMediaList useeffect
                                // set to false when length = 0
                                // set to true first time analyzeMedia called
    const [userSetLocation, setUserSetLocation] = useState(false)
                                // If this is false, the first image's EXIF
                                // data will always be updated into post
                                // This sets to true if user manual changes

    const [tipName, setTipName] = useState(null)
    const [aspects, setAspects] = useState ( {
        1: true,
        1.7778: false,
        0.5625: false,
        0.75: false
    })  // aspects, only one should be true. Used to set desired aspect ratio for whole post

    

    // Post Modal State
    const [showPostMediaModal, setShowPostMediaModal] = useState(true)
    const [modalMediaId, setModalMediaId] = useState(null)
    const [modalPostId, setModalPostId] = useState(null)

    const aspectRecommended = {
        1: dim => dim > 0.8 && dim < 1.7,
        1.7778: dim => dim >= 1.7,
        0.5625: dim => dim < 0.6,
        0.75: dim => dim >= 0.6 && dim <= 0.8
    }
    
    // Used for determining if post data, other than media list, has been modified
    // use effect will save it when modified, and a div will show saving
    const [isPostInfoModified, setPostInfoModified] = useState(false)

    // If user tries to navigate away and post is modified, this is updated with
    // path they tried to take
    // Maybe we won't use this
    const [requestedNav, setRequestedNav] = useState(null)


    let {id} = useParams()


    const toggleAspect = (dims, userChange = false) => {

        if (Number(getAspect()) !== Number(dims) && (selected > 0 || selected === -1)) {
            console.log(getAspect(), dims)
            setTipName("dims_change")
        }
        let k = Number(dims)
        const newAspect = {
            1: (k === 1),
            1.7778: (k === 1.7778),
            0.5625: (k === 0.5625),
            0.75: (k === 0.75)
        }
        setAspects(newAspect)
        userChange && setPostInfoModified(true)
        setPost( prev => {
            let newPost = {...prev}
            newPost.dimensions = k
            return newPost
        })
    }

    const getAspect = () => {
        return Object.entries(aspects).find ( ([k,v]) => v)[0]
    }

    

const thumbSize = () => {

    let bh = "22vh"

    const sizes = {
        1.7778: [`calc(0.8*${bh} * 1.7778)`, `calc(0.8*${bh})`],
        0.5625: [`calc(0.8*${bh} * 0.5625)`, `calc(0.8*${bh})`],
        0.75: [`calc(0.8*${bh} * 0.75)`, `calc(0.8*${bh})`],
        1: ['calc(0.8*19vh)', 'calc(0.8*19vh)']
    }

    let a = Number(getAspect())

    //console.log(a, Object.keys(sizes).map(k => Number(k)))
    if (Object.keys(sizes).map(k => Number(k)).includes(a)) {
        return sizes[a]
    } else {
        return sizes[1]
    }
}

    /**
     * This updates the Post server with the new media after the media
     * is saved to media service. 
     * 
     * This should be replaced with event driven solution. Or not
     */
    const addPostMedia = (media) => {

    console.log(post)
    loveMireApi.postJson('addPostMedia', media, post.id)
        .then ( response => {
            console.log(response)
            // since successful, add it to medialist
            setPostInfoModified(true) // tells post useffect to save to post server
            setPost( prevPost => {
                let newPost = {...prevPost}
                media.img = null;       // used to force a pic download
                if (prevPost.mediaList.find( m => m.id === media.id)
                    === undefined) { // add since doesn't exist (was new media)
                    newPost.mediaList.push(media)
                } else {
                    // replace media so update is filtered down
                    newPost.mediaList = newPost.mediaList.map ( ml => 
                        ml.mediaId === media.id ? {...media} : ml)
                }                
                return newPost;
            })

        })
        .catch( e => console.log(e.response))    
    }

    /**
     * Removes the media from postMediaList
     * sets postmodified true
     * 
     * This update will not delete anything from server until published
     * 
     * @param {*} media 
     */
    const deletePostMedia = (media) => {

        setPostMediaList ( prev => {
            let newList = [...prev]
            return newList.filter( e => e.mediaId !== media.mediaId)
        })

        setPostInfoModified(true)

    }

    /**
     * User Pressed Publish
     * 
     * Rolls postMediaList into post.mediaList and uploads.
     * Sets post.active to true
     */
    const setActive = (active = true) => {

        let newPost = {...post}
        newPost['mediaList'] = postMediaList.map ( 
            ({dataId, mediaId, active, master}) => ({dataId, mediaId, active, master}))

        // if post is active and not modified, must be an unpublish
        // else set to parameter
        newPost.active = (post.active && !isPostInfoModified) ? !post.active : active

        loveMireApi.putJson('post',newPost,newPost.id)
            .then ( r => {
                // all we need to change on success is active and postmodified
                setPost(newPost)
                setPostInfoModified(false)

            }).catch ( e => console.log(e))
    }

    /**
     * User Pressed Save Draft when post modified.
     * 
     * Save post but set active to false
     */
    const saveDraft = () => {

        setActive(false)

    }

    /**
     * Quick method to call to return to selected = -1
     */
    const finished = () => {
        setSelected(-1)
    }

    /**
     * Receives an object from react-easy-crop that specifies the
     * natural width and height of a newly updated image.
     * 
     * s corresponds to the active media.
     * -2 is first image
     * -1 is a new image
     * 0+ is replacing an existing image.
     * 
     * If image is -2, post dims can be automatically set.
     * 
     * @param {number} s
     * @param {object} mediaSize 
     */
    const analyzeNewMediaDims = (mediaSize) => {

        console.log("%c Media Info: ", "color: green", mediaSize, 
            hasRecommendedDims)
        let dims = aspectRecommended
        let recommendedDims =
        Object.keys(dims).filter( k => dims[k](mediaSize.naturalWidth / mediaSize.naturalHeight))[0]

        if (!hasRecommendedDims) {
            setHasRecommendedDims(true)
            toggleAspect(recommendedDims)
        }

    }

    /*******************************
     * NEW METHODS USING CONTEXT
     *******************************/

    /**
     * Adds files to PostMedia state.
     * 
     * After state updates, use effect will fire and process the new media.
     * 
     * @param {[]} acceptedFiles 
     */
    const addMedia = (acceptedFiles) => {

        let previousLength = postMediaList.length   // saves length of media
                                                    // prior to new files
                                                    // unused, handled with filter


        // Adds to postmedialist, sets processing to 'newfile'
        // Adds url object
        let newMedia = acceptedFiles.map ( f => { return {
            processing: 'newfile',
            master: 'master',
            file: f,
            img: URL.createObjectURL(f)
        }})

        // Updates post so it shows spinner while adding to server
        setPostMediaList ( prev => [...prev,...newMedia])

        // save to media, then remove processing flag and previous
        // objects via filter
        Promise.all( newMedia.map( m => apiSaveMedia(m.file,m)) )
            .then( ml => {
                ml.forEach( e => delete e.processing)
                setPostMediaList(prev => [...prev.filter(e => !e.processing)
                    ,...ml])
                setPostInfoModified(true)
            })

    }

    /**
     * Called by ImageEdit to update a media file.
     * 
     * This calls the api end point and updates the media on server. Doesn't
     * update the post since the post doesn't change.
     * 
     * @param {*} media 
     * @param {*} crop 
     * @param {*} previewFile 
     */
    const modifyMedia = (media, crop, previewFile) => {

        if (!media.file) {
            throw new Error("modifyMedia: ", "Must have media.file")
        }

        // knows how to handle undefinied crop and previewFile
        apiSaveMedia(media.file,media,crop,previewFile)
            .then ( r => {
                setPostMediaList(prev => {
                    let newList = [...prev]
                    return newList.map( e => e.mediaId !== media.mediaId ?
                        e : r)
                })
            })

        // TODO: The Post is actually not modified here
        // At least for now, we'll keep it this way

    }

    const loadMedia = (mediaList) => {

        Promise.all( mediaList.map( m => apiGetMedia(m)))
            .then(r => setPostMediaList(r))
            .catch( e => console.log(e))
    }

    /**
     * Updates the past object by concat
     * 
     * newPost = {...post,...newPostData}
     * @param {obj} newPostData 
     */
    const updatePost = (newPostData) => {
        setPostInfoModified(true)
        
        // user changed location so stop checking image
        if (!!newPostData.addressString) {
            setUserSetLocation(true)
        }
        setPost ( prev => {
            return {...prev,...newPostData}
        })
    }

    /**
     * Find image location
     * 
     * Update post.
     */
    const findLocation = (media) => {
        console.log(media)
        try {
            ExifReader.load(media.img, {expanded: true})
                .then ( tags => {
                    return tags.gps ? tags.gps : {}
                })
                .then ( gps => {
                    let pathVar = gps.Latitude + '/' + gps.Longitude
                    return loveMireApi.getJson('convertCoords',pathVar)
                })
                .then ( formattedAddress => {
                    setPost( prev => {
                        let newPost = {...prev}
                        newPost.addressString = formattedAddress.formatted_address
                        newPost.coords[0] = parseFloat(formattedAddress.lat)
                        newPost.coords[1] = parseFloat(formattedAddress.lng)
                        return newPost
                    })
                })
        } catch (e) {
            console.log(e)
        }
    }



    /*******************************
     * USE EFFECTS
     *******************************/

    /**
     * On mount
     * 
     * Calls post server to get object. If props has post object, will
     * use Id to make call.
     * 
     * If props.post.id doesn't exist, will call newPost endpoint
     */
    useEffect ( () => {

        console.log("EditPost useEffect: ", id, post)
        if ( !!id ) {
            // try to get id from url
            loveMireApi.postJson('posts',{},id)
                .then ( response => {
                    let newPost = response[0]
                    console.log('%c Post Response: ',
                        'background: #222; color: #bada55', response)
                    if (newPost.account.username === matches.getUser()) {
                        newPost.mediaList && newPost.mediaList.length > 0
                            && loadMedia(newPost.mediaList)
                        setPostActive(newPost.active)
                        toggleAspect(newPost.dimensions)     
                        setPost(newPost) 
                        setLoadingPost(false)              
                    } else {
                        appSettings.error({message: `Attempt to Edit Post that user (${matches.getUser()}) doesn't own!`})
                    }
                })
        } else if (!props.post || !props.post.id) {
            // get new Media
            loveMireApi.postJson('newPost',{})
                .then ( response => {
                    console.log(response)
                    setPost(response)
                    console.log('%c WARNING: ', 'background: black, color: red', "redirection", props.post)
                    window.location.href = window.location.href + '/' + response.id                    
                })
            .catch ( e => console.log(e,e.response))
        } 
    },[])

        
    /**
     * Used to update mediaData
     * loops through medialist downloads the image
     * if it doesn't exist
     * 
     * This could be fired twice when it updates post with the media data
     * but won't make an api call again if all media data is saved.
     */
    useEffect( () => {

        console.log("EditPost: useEffect [post] START", 
            isPostInfoModified, post)

    }, [post])

    useEffect( () => {
        console.log("Aspected toggled on selected image: ", selected)
        console.log("TODO: Provider user tooltip.")
    }, [aspects])


    /**
     * Simple update for now.
     * 
     * TODO: handle failed api put
     */
    useEffect ( () => {

        console.log(isPostInfoModified, post)

    }, [isPostInfoModified])

    useEffect ( () => {

        // Handle selected image
        if ( selected < 0 ) {
            setSelected(postMediaList.length - 1)
        } else if (postMediaList.length === 0) {
            setSelected(-1)
        } else if (selected >= postMediaList.length) {
            console.log(postMediaList.length)
            setSelected(postMediaList.length - 1)
        }

        // Handle has recommended Dims
        if(postMediaList.length === 0 && hasRecommendedDims) {
            setHasRecommendedDims(false)
        }

        // Debug console prints

        postMediaList[0]?.file && 
            findLocation(postMediaList[0])

        postMediaList.length > 0 &&
            console.log("PostMediaList updated: ", 
                postMediaList, typeof postMediaList[0].img, 
                typeof new Blob([postMediaList[0].file]),
                typeof postMediaList[0].file,
                postMediaList[0].img.clientHeight,
                postMediaList[0].img.naturalWidth)

        const testObj = {
            "dataId": "61f9e1addb50f40fa4c65321",
            "mediaId": 738,
            "active": true,
            "master": "master"
        }

        apiGetMedia(testObj)
            .then( r => console.log(r))
    }, [postMediaList])

    /**
     * Setup edit post components. With a big screen, these are spread at the top.
     * On a small screen, they are placed in an expandable top menu.
     * 
     */

    let barComponents = (
            <StyledButtonRowTopFixed>
                {(postMediaList.length > 0 && !isPostInfoModified) && 
                <SelectButton 
                    selected={true}
                    noCheckBox
                    onClick={ () => {
                        setShowPostMediaModal(true);
                        setModalPostId(post.id);
                    }}>
                        View Post
                </SelectButton>}
                {(postMediaList.length > 0 && isPostInfoModified) &&
                    <SelectButton
                        onClick={saveDraft}>
                        Save Draft
                    </SelectButton>
                }
                {postMediaList.length > 0 && <SelectButton 
                    noCheckBox
                    selected={post.active && !isPostInfoModified}
                    onClick={() => setActive()}>
                    { post.active && !isPostInfoModified ? "Post Live!" : "Publish!" }</SelectButton>}
                
            </StyledButtonRowTopFixed>);

    let dropComponents = (
        <>
        <StyledButtonRow>
                {Object.entries(aspects).map ( ([k,v]) => {
                    return <div key={k} onClick={ (event) => toggleAspect(k,true) } >
                        <AspectButton                         
                        aspect={Number(k)}
                        selected={v}
                         /></div>
                })}
            </StyledButtonRow>

        </>
    )



    if (!loadingPost) {
        return (<>
            <FixedPostEditorWrapper> {/*div margintop: 120px for large screens*/}
            <PostContext.Provider value={post}>
            <PostMediaContext.Provider value={postMediaList}>
            <PostUpdateContext.Provider value={{
                addMedia: addMedia,      // for new media
                modifyMedia: modifyMedia,
                finished: finished,
                updatePost: updatePost
            }}>

            <Prompt when={isPostInfoModified}
                message="Post not saved, lose changes?"
                /* @todo wtf, why doesn't work?
                    message={(location,action) => {
                    setRequestedNav(location.pathname)
                    return false
                }}*/ />
            {requestedNav && <ConfirmationModal
                cancelButtonText="Back"
                onCancel={ () => setRequestedNav(null)}
                onConfirm={ () => {
                    console.log(requestedNav)
                    history.push(requestedNav)
                 } }>
                Post not saved, lose changes?
            </ConfirmationModal>}
            <ToolTip tipName={tipName}>
            {showPostMediaModal && (modalMediaId || modalPostId) &&
                    <FullModal onClick={() => {setShowPostMediaModal(false);}}>
                        <PostMediaWithFind 
                            postId={modalPostId}
                            mediaId={modalMediaId}
                            showDrafts />
                        <HideButton onClick={() => setShowPostMediaModal(false)} />
                    </FullModal>}

            {window.innerWidth > 600 && <>{barComponents}{dropComponents}</>}
            {window.innerWidth <= 600 && <TopMenu 
                topBar={barComponents}
                dropDown={dropComponents}
                />}
            

            
            <StyledFlexRow>
            {selected < 0 && <>
                <PostDescription post={post}>
                    <AddMedia />
                </PostDescription>
                </>}
            {selected >= 0 && <ImageEdit
                media = { postMediaList[selected] }
                post={post}
                selected={selected}
                aspects={aspects}
                onMediaLoaded={(size) => analyzeNewMediaDims(size)}
                 />}

                {/*<CropImage 
                    media={ selected >=0 ? post.mediaList[selected] : {}}
                    post={post}
                    aspect={aspects}
                    updatePost={ (m) => addPostMedia(m)}
                deleteMediaFromPost= { (m) => deletePostMedia(m) } />                
                <ImageEdit 
                    media={ selected >=0 ? post.mediaList[selected] : {}}
                    selected={selected} // really just to force an update
                    post={post}             
                    aspect={aspects}
                    onMediaLoaded={(s, size) => analyzeNewMediaDims(s,size)}
                    updatePost={ (m) => addPostMedia(m)} />*/}
            </StyledFlexRow>
            </ToolTip>
            </PostUpdateContext.Provider>
            </PostMediaContext.Provider>
            </PostContext.Provider>
            </FixedPostEditorWrapper>
            
            {postMediaList.length > 0 && 
            <FixedGalleryWrapper> {/*StyledFlexRow for large screens*/}
                {postMediaList.map ( (media,i) => 
                    <StyledGalleryDiv key={media.dataId || i}
                        onClick={() => { !media.processing && setSelected(i); }}>
                        <div style={{width: thumbSize()[0], height: thumbSize()[1]}}>
                        {media.img && <StyledGalleryThumb 
                            src={media.img} 
                            onClick={() => setSelected(i)} />}
                        {media.img && <HideButton onClick={ () => deletePostMedia(media)} />}
                        {media.processing && <Spinner />}
                        </div>
                        {/*<div style={{display: "inline-block", verticalAlign: "top",  padding: 0, height: "20%"}}>
                            {i !== 0 && <LeftButton onClick={ (e) => swapMediaPosition(e,i,i-1)} />}
                            {i !== post.mediaList.length-1 && <RightButton onClick={ (e) => swapMediaPosition(e,i,i+1)} />}
                        </div>*/}
                    </StyledGalleryDiv>
                )}
                <StyledGalleryDiv 
                    aspect={Number(getAspect())}
                    onClick={() => {console.log("selected"); 
                    if(post.mediaList.length > 2) setTipName("suggest_hidden"); 
                        setSelected(-1);} }>
                    <div style={{width: thumbSize()[0], height: thumbSize()[1]}}>
                    <div style={{margin: '1px 0 1px 0', height: '95%', width: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <PlusButton onClick={() =>  setSelected(-1)} />
                    </div></div>
                </StyledGalleryDiv>
            </FixedGalleryWrapper>}
            
        </>

        )
    } else {
        // loading post
        // If new post, creating new post
        return (
            <Spinner />
        )
    }
}

export default EditPost