import styled from 'styled-components'

export const ProfileWrapper = styled.div`
    width: 90%;
    margin: 100px auto 0 auto;    
    position: relative;

    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const FixedPostEditorWrapper = styled.div`

    margin: 0 auto;
    margin-top: 60px;
    max-width: 1024px;    

    @media (max-width: 600px) {
        width: 100vw;
        z-index: 98;
        position: fixed;
        bottom: 20vh;
        top: 5vh;
        left: 50%;
        transform: translateX(-50%);
        overflow-y: auto;
        margin-top: 0;
        
        &::-webkit-scrollbar {
            width: 8px;
           }
    
        /* Track */
        &::-webkit-scrollbar-track {
        background: #f1f1f1;
        }
    
        /* Handle */
        &::-webkit-scrollbar-thumb {
        background: #888;
        }
    
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
        background: #555;
        }
    
    }
    `;

export const FixedGalleryWrapper = styled.div`

    display: flex;
    margin: 0;    
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    background-color: #707070;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 10px;

    @media (max-width: 600px) {
        
        border-top: black 2px solid;
        position: fixed;
        bottom: 0;
        left: 0;
        overflow-x: scroll;
        overflow-y: hidden;
        justify-content: flex-start;
        z-index: 99;
        width: 100%;
        flex-wrap: nowrap;
        padding: 0;

    
        &::-webkit-scrollbar {
            height: 1vh;
           }
    
        /* Track */
        &::-webkit-scrollbar-track {
        background: #f1f1f1;
        }
    
        /* Handle */
        &::-webkit-scrollbar-thumb {
        background: #888;
        }
    
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
        background: #555;
        }
    

    }
`;

export const StyledImageEditContainer = styled.div`
    display: flex;    
    background-color: #d6d6d6;
    display: flex;
    flex-direction: row;
    font-size: 1em;
    align-items: top;
    justify-content: center;
    box-sizing: border-box;
    flex-wrap: wrap;

    position: relative;

    p {
        font-size: 1.2em;
        margin: 5px;
        padding: 0;
        color: #282c34;
        font-family: 'Roboto';
    }

    @media (max-width: 600px) {
        flex-wrap: wrap;
        width: 100%;
    }

`;

export const StyledImageEditContainerL = styled.div`
    display: flex;
    width: 300px;
    padding: 10px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
        

    @media (max-width: 600px) {
        width: 100%;
        padding: 0;
    }
`;

export const StyledImageEditContainerR = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 300px;
    padding: 20px;
    

    @media (max-width: 600px) {
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
        background-color: white;
        border-radius: 15px;
        border: 2px gray solid;
        padding: 0;
    }

`;

StyledImageEditContainer.Left = StyledImageEditContainerL
StyledImageEditContainer.Right = StyledImageEditContainerR


export const StyledProfileFlexContainer = styled.div`
    display: flex;
    background-color: #d6d6d6;
    display: flex;
    flex-direction: row;
    font-size: calc(10px + 2vmin);
    color: #707070;
    align-items: top;
    justify-content: center;
    position: relative;

    p {
        margin: 5px;
        padding: 0;
        color: #282c34;
        font-size: 0.5em;
        font-weight: lighter;
        font-family: 'Roboto';
    }
    
    @media (max-width: 600px) {
        flex-wrap: wrap;
    }
`;

export const StyledFormFlexContainer = styled.div`
    display: flex;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    display: block;
    font-size: calc(10px + 2vmin);
    color: #707070;
    align-items: top;
    justify-content: center;
    text-align: left;

    p {
        margin: 5px;
        padding: 0;
        color: #282c34;
        font-size: 0.5em;
        font-weight: lighter;
        font-family: 'Roboto', serif;
    }


    h3 {
        margin: 5px;
        padding: 0;
        color: #282c34;
        font-size: 0.9em;
        font-weight: normal;
        font-family: 'Roboto', serif;
    }

    h4 {
        margin: 5px;
        padding: 0;
        color: #282c34;
        font-size: 0.8em;
        font-weight: lighter;
        font-family: 'Roboto', serif;
    }

    li {
        display: flex;
        align-items: stretch;
        justify-content: center;
        text-align: left;
        padding: 2px;
        margin: 5px 0;
        width: 100%;

        label {
            text-align: left;
            flex: 1 0 120px;
        }

        input:not([type=checkbox]) {
            height: 30px;
            width: 90%;
            margin: 0;
            border: #86888B 1px solid;
            padding: 5px;
            background-color: #ebe6e6;
            border-radius: 5px;
            box-shadow: 0 0 2px 0 rgba(0,0,0,0.75);
            -webkit-box-shadow: 0 0 2px 0 rgba(0,0,0,0.75);
            -moz-box-shadow: 0 0 2px 0 rgba(0,0,0,0.75);
        }

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
        }

        textarea {
            height: 100px;
            width: 100%;
            border: #86888B 1px solid;
            border-radius: 2px;
            padding: 5px;
            background-color: #ebe6e6;
            border-radius: 5px;
            box-shadow: 0 0 2px 0 rgba(0,0,0,0.75);
            -webkit-box-shadow: 0 0 2px 0 rgba(0,0,0,0.75);
            -moz-box-shadow: 0 0 2px 0 rgba(0,0,0,0.75);
        }

        button {

            width: 100%;
            border: #86888B 1px solid;
            border-radius: 5px;
            padding: 25px 5px;
            background-color: #ebe6e6;

        }

        @media (max-width: 600px) {
            flex-wrap: wrap;
        }
    }


`;

export const StyledFormLeftColumn = styled.div`
    display: flex;
    width: 33%;
`;

export const StyledFormRightColumn = styled.div`
    align-items: left;
    width: 66%;
`;


export const StyledProfileLeftColumn = styled.div`
    display: flex;
    width: 300px;
    padding: 20px;
`;

export const StyledProfileRightColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 300px;
    padding: 20px;
`;


export const StyledProfileRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;

    @media (max-width: 600px) {
        flex-wrap: wrap;
    }
`;

StyledProfileFlexContainer.Row = StyledProfileRow
StyledProfileFlexContainer.Left = StyledProfileLeftColumn
StyledProfileFlexContainer.Right = StyledProfileRightColumn

StyledFormFlexContainer.Row = StyledProfileRow
StyledFormFlexContainer.Left = StyledFormLeftColumn
StyledFormFlexContainer.Right = StyledFormRightColumn
