import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LikeTypes from './data/LikeTypes';
import SwipeDots from './SwipeDots';
import LikeButton from './LikeButton';
import { getHiddenViews } from '../../lib/LikesApi';
import loveMireApi from '../../lib/loveMireApi';
import { HideButton } from '../../styles/Buttons';
import { PostWrapper } from '../../styles/post/Wrappers';
import { MediaImg } from '../../styles/post/Images';
import { LikeDiv } from '../../styles/post/Overlays';
import { SlideButton, Arrow } from '../../styles/post/Buttons';
import MediaNotifications from './components/MediaNotifications';
import ToolTip from '../../tooltip/tooltip';
import HiddenProgress from './components/HiddenProgress'
import { useSwipeable } from 'react-swipeable'
import SwipeLike from './components/SwipeLike'
import SlideLike from './components/SlideLike'
import HoldLike from './components/HoldLike'
import DeleteLikeButton from './components/DeleteLikeButton'
import DraftOverlay from './components/DraftOverlay'

import { MatchesContext, DataContext } from '../../App';


const LikeSwipeWrapper = styled.div`
    
    p {
        font-size: 0.4em;
        color: #C28383;
        background-color: #E4DADA;
        border: #707070 solid 1px
        text-shadow: 5px 5px 5px #E4DADA;
        padding: 2px;
        border-radius: 5px;
    }

    display: block;
    width: 100%;
    overflow: hidden;
    background-color: inherit;
    position: relative;

    span {
        font-size: 0.8em;
        color: #c7af67;
        font-weight: bolder;
    }
`;



/**
 * Decides on the width and height of the Media in the feed.
 * For now uses a set width of 400, but this needs to be modified
 * to use screen info primarily.
 * 
 * @param {*} d 
 * @returns 
 */
const pickDims = (d) => {
    const dims = {
        1: [400,400],
        1.7778: [400, 225],
        0.5625: [400, 711],
        0.75: [400, 533]
    }

    let width = document.body.clientWidth > 480 ? 480 : document.body.clientWidth
    let calculatedHeight = dims[d] !== undefined ?
    width / d : width;

    return [width, calculatedHeight]
    //return dims[d] !== undefined ? dims[d] : dims[1]

}

/**
 * Stateful component. Will handle likes, image sliding for the media
 * on a particular post.
 * 
 * Will also handle hidden images...
 * @param {} props 
 * @returns 
 */
const PostMedia = (props) => {

    const matches = React.useContext(MatchesContext)
    const [account,appSettings] = React.useContext(DataContext)

    const [activeMedia, setActiveMedia] = useState(0);

    const [mediaData, setMediaData] = useState({})

    // Contains information on a media that is hidden
    // key is the media data id
    const [hiddenMedia, setHiddenMedia] = useState({})

    const tempHiddenImage = "606dd8285eb81701bb148a62";

    // Determines if an overlay is active
    const [overlayActive, setOverlayActive] = useState(false)

    // Determines if post was made by viewer
    const [selfPost, setSelfPost] = useState(false)

    const [tipName, setTipName] = useState("")

    const [hiddenUnlocks, setHiddenUnlocks] = useState({})

    const onClickDot = (id) => {
        setActiveMedia(id)
    }

    const swipeHandlers = useSwipeable({
        onSwipedRight: () => {
            setActiveMedia( prev => prev===0 ? 0 : prev-1)
            console.log("Swipe Left")
        },
        onSwipedLeft: () => {
            setActiveMedia( prev => prev===props.mediaList.length-1 ? 
                props.mediaList.length-1 : prev+1)
            console.log("Swipe Right")            
        },
        preventDefaultTouchmoveEvent: true,
        trackTouch: true,
        trackMouse: true
    })

    /**
     * Finds the data id in the medialist then returns the previewDataId
     * @param {string} dataId 
     */
    const findPreviewId = (dataId) => {

        if (!!props.mediaList && props.mediaList.length > 0) {
            let media = props.mediaList.find( e => e.dataId === dataId)
            
            if(media && media.mediaId) {
                return media.mediaId
            } else {
                return ""
            }
        }
    }

    /**
     * Returns index of requested mediaId in mediaList or 0 if not found
     * @param {Long} mediaId 
     * @returns 
     */
    const findActiveByMediaId = (mediaId) => {

        if (!!props && !!props.mediaList && props.mediaList.length > 0) {
            let i = props.mediaList.findIndex( m => m.mediaId === mediaId)
            if (i === -1) i = 0;
            return i;
        } else return 0;
    }

    /**
     * Returns false if does not exist in hiddenMedia
     */
    const isHidden = (media) => {
        if (Object.keys(hiddenMedia).includes(media.dataId)) {
            // is hidden check if user is over requiredscore

            let score = hiddenMedia[media.dataId].like_counts.reduce( (total,lc) => total + lc.cost, 0);

            return score > 0 ? score : -1
        } else return false
    }

    const slideButtons = () => {
        let sb = []
        if ( !!props.mediaList && props.mediaList.length > 0) {
            // Determine which side to side navigation buttons to put on media

            if ( activeMedia < props.mediaList.length-1) {
                sb.push(<SlideButton slide={"right"}
                            key={"right"}
                            onClick={() => setActiveMedia( prev => prev+1)}>
                        <Arrow deg={"-45"} />
                        </SlideButton>)
            } 
            if ( activeMedia !== 0) {
                sb.push(<SlideButton slide={"left"}
                            key={"left"}
                            onClick={() => setActiveMedia( prev => prev-1)}>
                        <Arrow deg={"135"} />
                        </SlideButton>)
            }
        } 
        return sb;
    }

    /**
     * Returns first like that isn't found in user_likes
     * @returns String
     */
    const nextLike = () => {
        let next = "none"

        if ( !props.mediaList[activeMedia].user_likes) {
            return "free"
        }

        for ( let l of LikeTypes ) {
            if ( props.mediaList[activeMedia].user_likes.filter( ul => ul.likeType.name === l.name).length === 0) {
                // not found in user_likes
                console.log(l)
                next = l.name;
                break;
            }
        }
        return next;
    }

    /**
     * Returns all likes not in user_likes
     * @returns Array
     */
    const remainingLikes = () => {

        const likesContains = (l) => {
            if (props.mediaList[activeMedia].user_likes.filter (e => e.likeType.name === l).length > 0) {
                return true;
            }
            return false;
        }

        if( !props.mediaList[activeMedia].user_likes) {
            return Object.values(LikeTypes)
        }

        return Object.values(LikeTypes).filter(lt => !likesContains(lt.name))
    }

    /**
     * Converts a user's likes to it's corresponding generic like type
     */
    const genericLikeTypes = () => {
        const findByName = (name) => {
            return Object.values(LikeTypes).filter(lt => lt.name === name)[0]
        }

        if (!props.mediaList[activeMedia].user_likes) return []
        return props.mediaList[activeMedia].user_likes.map ( ul => findByName(ul.likeType.name))
    }

    /**
     * Returns highest like found in user_likes
     */
    const highestLike = () => {
        
        console.log("Finding highest: ", props.mediaList[activeMedia].user_likes)
        if ( !props.mediaList[activeMedia].user_likes || props.mediaList[activeMedia].user_likes.length === 0) {
            return "none"
        }

        // find highest cost incoming
        let highest = Math.max( ...props.mediaList[activeMedia].user_likes.map( ul => ul.likeType.costIncoming ) )

        // return the proper like type
        return props.mediaList[activeMedia].user_likes.find( ul => ul.likeType.costIncoming === highest).likeType.name;
    }

    const saveMediaData = (mediaDataId) => {

        let params = {}

        //console.log("Fetching: ", mediaDataId)

        setMediaData ( prevData => {
            let newData = {...prevData}
            newData[mediaDataId] = "";
            return newData;
        })

        // Check if token exists in hiddenMedia
        if ( mediaDataId in hiddenMedia) {
            // has an entry in hidden Media, try to get token.
            if ('token' in hiddenMedia[mediaDataId]) {
                params['token'] = hiddenMedia[mediaDataId]['token']
                console.log("found token: ", params)
            }
        }

        loveMireApi.getPic('mediaData',mediaDataId, params)
            .then ( (response) => {
                
                if ( Object.keys(hiddenMedia).includes(mediaDataId)) {
                    // remove from hidden media if exists
                    // this happens if this request was included a token
                    // needs to update so UI won't show progress bar
                    setHiddenMedia( prev => {
                        let newHidden = {...prev}
                        delete newHidden[mediaDataId]
                        return newHidden
                    })
                }
                setMediaData( prevData => {
                    let newData = {...prevData}
                    newData[mediaDataId] = URL.createObjectURL(response);
                    return newData;
                })
            })
            .catch ( e => {
                // This error block is big, need to consider moving it somewhere.


                if ( e.response && e.response.status === 204) {
                    // handle hidden image.
                    // Set hidden image state
                    // old code when we included call for token with every hidden

                    if ( !Object.keys(hiddenMedia).includes(mediaDataId) ) {
                        requestHiddenAccessByDataId(mediaDataId)
                    }

                    // NOTE: previewDataId added to media server for this purpose
                    //console.log(mediaDataId, findPreviewId(mediaDataId))
                    loveMireApi.getPic('previewData',findPreviewId(mediaDataId))
                        .then( (response) => {

                            // temporary place holder so it won't refetch
                            setMediaData ( prevData => {
                                let newData = {...prevData}
                                newData[mediaDataId] = URL.createObjectURL(response);
                                return newData;
                            })

                        })
                        .catch( e => console.log(e) )

                } else { // end hidden media status
                    appSettings.error(e)
                }
            })        
    }

    const deleteLike = () => {
        props.onDelete(props.mediaList[activeMedia].mediaId, highestLike());
    }

    /**
     * 
     * 
     * @param {string} mediaDataId 
     */
    const requestHiddenAccessByDataId = (mediaDataId, includeToken = false) => {

        let hiddenPath = includeToken ? 'requestHidden' : 'hiddenRequirements'
        loveMireApi.getJson(hiddenPath, mediaDataId)
        .then ( (response) => {

            // Create an entry in hiddenMedia state.
            // This state stores info about the media, including
            // the token, requirements, and score
            setHiddenMedia ( prevData => {
                let newData = {...prevData}
                newData[mediaDataId] = {
                    ...response
                }
                return newData;
            })

        })
        .catch (e => console.log(e.response))
    }

    /**
     * Handles calling proper methods when like is changed
     * 
     * TODO: Add tips
     * 
     * @param {*} deletedLikes 
     * @param {*} addedLikes 
     */
    const likeSwipeHandler = (deletedLikes, addedLikes) => {

        console.log(deletedLikes, addedLikes)

        if (addedLikes.length > 0) {
            setTipName( addedLikes[addedLikes.length-1].name.replace(/-/g,'_').replace("free","free_like")); 
            props.onLike(props.mediaList[activeMedia].mediaId, addedLikes)
        } else if (deletedLikes.length > 0) {
            props.onDelete(props.mediaList[activeMedia].mediaId,deletedLikes)
        }
    }

    /**
     * If Hidden Media changes, we need to check for tokens and run a request for the
     * hidden pic.
     */
    useEffect ( () => {

        Object.keys(hiddenMedia).forEach ( k => {
            saveMediaData(k)
        })

    }, [hiddenMedia])

    /**
     * When a key is added to hidden unlocks, queries media-data server for count
     * of unlocks. This is only used in self posts?
     */
    useEffect ( () => {
        //console.log("Hidden Unlocks Use Effect", hiddenUnlocks, Object.keys(hiddenUnlocks).includes("401"))
    }, [hiddenUnlocks])

    useEffect ( () => {

        /*
        Handled differently when self post because the owner can only tell if
        it's hidden through the media likes endpoint. A non owner sees it's hidden
        by the status code from media-likes.
        (non-owners can never know hidden media exists unless they browse to it)
        */
        if (!selfPost && isHidden(props.mediaList[activeMedia])) {
            // request new hidden info in case user has liked
            requestHiddenAccessByDataId(props.mediaList[activeMedia].dataId, false)
            setTipName("hidden")
        }
        if (selfPost && props.mediaList[activeMedia].hidden==true 
            && !Object.keys(hiddenUnlocks)
                .includes(String(props.mediaList[activeMedia].mediaId))) {

            getHiddenViews(props.mediaList[activeMedia].dataId)
                    .then ( amount => 
                        setHiddenUnlocks ( prev => {
                            let newState = {...prev}
                            newState[parseInt(props.mediaList[activeMedia].mediaId)]=amount
                            return newState
                    }))
            
        }
    }, [activeMedia])

    useEffect ( () => {

        // Check if self post
        if ( !!props.self ) {
            setSelfPost(true)
        }

        // Check if props has active media (used for seeing single media)
        if ( !!props.activeMedia && !isNaN(props.activeMedia)) {
            setActiveMedia(findActiveByMediaId(props.activeMedia))
        }
        props.mediaList.forEach ( media => {
            if ( !( media.mediaId in Object.keys(mediaData)) ) {
                saveMediaData(media.dataId)
            } 
        })
    }, [])

    useEffect( () => {
        console.log("PostMedia: new props")
    }, [props])
    /* RENDER STARTS HERE
     */
    if (!!props.mediaList) {

        // TODO: swipe, dots

        // Like Count sets the total # of likes for
         // TODO: we have to move this up even if troublesome
        let likeCount = 0
        if ( !!props.mediaLikes ) {
            let likeArray = props.mediaLikes
                .find( ml => ml.id === props.mediaList[0].mediaId)

            if (!!likeArray) {
                likeCount = likeArray['cost']
            }

        }


        //console.log(props, props.mediaList[2].previewDataId===undefined, props.mediaList[2].previewDataId)
        return (
            <div>
            <div {...swipeHandlers} onDoubleClick={!selfPost ? e => props.onLike(props.mediaList[activeMedia].mediaId, nextLike()) : null }>
            
            <ToolTip tipName={tipName} profile={props.profile}>
                
            <PostWrapper dimensions={pickDims(props.dimensions)}>
                {props && props.active===false && <DraftOverlay />}
                <MediaImg 
                        dimensions={pickDims(props.dimensions)}
                        key={props.mediaList[activeMedia].dataId}
                        src={mediaData[props.mediaList[activeMedia].dataId]} />
                {selfPost && 
                    <>
                    {!!overlayActive && <MediaNotifications
                        media={props.mediaList[activeMedia]}>
                        {slideButtons()}
                        <HideButton onClick={() => setOverlayActive(false)} />
                    </MediaNotifications>}
                    {!overlayActive && <LikeDiv>
                        <p><span>Likes:</span> {props.mediaList[activeMedia].likeCount}</p>
                        <p><span>Score:</span> {props.mediaList[activeMedia].likeCost}</p>
                        {props.mediaList[activeMedia].hidden && 
                            <p>
                                <span>Unlocks: </span>
                                {Object.keys(hiddenUnlocks).includes(String(props.mediaList[activeMedia].mediaId)) ? 
                                    hiddenUnlocks[props.mediaList[activeMedia].mediaId] : "no"}
                            </p>}
                        <button onClick={() => setOverlayActive(true)}>Browse Likes</button>
                    </LikeDiv>}
                    </>}
                {!overlayActive && props.children}
                {/*!selfPost && !overlayActive && !isHidden(props.mediaList[activeMedia]) &&
                 /*<LikeButton user_likes={props.mediaList[activeMedia].user_likes}
                    onClick={ () => {setTipName(nextLike().replace(/-/g,'_').replace("free","free_like")); 
                    props.onLike(props.mediaList[activeMedia].mediaId,nextLike())}}
                onDeleteClick={ () => props.onDelete(props.mediaList[activeMedia].mediaId,highestLike())} />*/
                /*
                <LikeSwipeWrapper>
                    <SlideLike current={genericLikeTypes()}
                        available={remainingLikes()}
                        onSelected={ (removed, added) => likeSwipeHandler(removed, added)} />
                    <DeleteLikeButton 
                        user_likes={props.mediaList[activeMedia].user_likes}
                        onClick={() => deleteLike()} />
                </LikeSwipeWrapper>*/
                }
                {!selfPost && 
                    isHidden(props.mediaList[activeMedia]) &&
                    !props.mediaList[activeMedia].hiddenRequirements &&
                    <HiddenProgress 
                        {...hiddenMedia[props.mediaList[activeMedia].dataId]}
                        onClick={() => requestHiddenAccessByDataId(props.mediaList[activeMedia].dataId, true)} />}
                {slideButtons()
                }
                {!!props.mediaList && 
                    <SwipeDots 
                        self={selfPost}
                        mediaList = {props.mediaList}
                        hidden = {hiddenMedia}
                        activeMedia = {activeMedia}
                        onClick = {(id) => onClickDot(id)}/>}
                <p style={{color: 'black', backgroundColor: 'white', bottom: 0, left: 0, position: 'absolute'}}>{
                props.mediaList[activeMedia].mediaId + genericLikeTypes().map( e => e.name) + '|' +
                remainingLikes().map(e => e.name)}
                </p>
            </PostWrapper>
            </ToolTip>
            </div>

            

            {/* like swiper!selfPost && !overlayActive && 
            <LikeSwipeWrapper>
                    <SlideLike current={genericLikeTypes()}
                        available={remainingLikes()}
                        dimensions={pickDims(props.dimensions)}
                        disabled={ isHidden(props.mediaList[activeMedia]) } // if hidden cant like
                        onSelected={ (removed, added) => likeSwipeHandler(removed, added)}>
                    <DeleteLikeButton 
                        user_likes={props.mediaList[activeMedia].user_likes}
                        onClick={() => deleteLike()} />
                    </SlideLike>
            </LikeSwipeWrapper>*/}

            {!selfPost && !overlayActive &&
            <LikeSwipeWrapper>
                <HoldLike
                    mediaId={props.mediaList[activeMedia].mediaId}
                    current={genericLikeTypes()}
                    available={remainingLikes()}
                    hidden={ isHidden(props.mediaList[activeMedia]) }
                    onSelected={ (removed,added) => likeSwipeHandler(removed, added) }
                    //onSelected={ (removed, added) => console.log(removed,added)}
                    >
                </HoldLike>
                <DeleteLikeButton 
                        user_likes={props.mediaList[activeMedia].user_likes}
                        onClick={() => deleteLike()} />
            </LikeSwipeWrapper>}

            </div>
        )
    }
    else { // loading
        return (
            <PostWrapper>
                <p>Img...</p>
            </PostWrapper>
        )
    }

}

PostMedia.propTypes = {
    onLike: PropTypes.func,
    onDelete: PropTypes.func
}

export default PostMedia;