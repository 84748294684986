import React from 'react'
import Modal from '../styled/MediaModal'

export default function one (e) {

    const dems = {

    }
    return <Modal>
        <Modal.H2>{e.profile.title}'s secret image!</Modal.H2>
        <Modal.P>Liking {e.profile.title}'s previous media in this post</Modal.P>
        <Modal.P>will unlock this image.</Modal.P>
        <Modal.P centered>Note: Once viewed, likes cannot be undone.</Modal.P>
        {e.children}
    </Modal>

}