import React from 'react'
import Modal from '../styled/MediaModal'

export default function one (e) {

    const dems = {

    }
    return <Modal>
        <Modal.H2>Admired!</Modal.H2>
        <Modal.P centered>Cost: 5</Modal.P>
        <Modal.P>{e.profile.title} will see your name in admired list!</Modal.P>
        <Modal.P>Keep going! Send a Gift!</Modal.P>
        {e.children}
    </Modal>

}