
/**
 * Structure for sequence of likes. 
 */
 const likeTypes = [
    {
        cost: 1,
        name: "free",
        title: "First Like"
    },
    {
        cost: 2,
        name: "double-like",
        title: "Double Like"
    },
    {
        cost: 5,
        name: "admire",
        title: "Admire"
    },
    {
        cost: 10,
        name: "double-admire",
        title: "Double Admire"
    },
    {
        cost: 25,
        name: "max-like",
        title: "Max Like"
    }
    
]

export default likeTypes;