import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ConnectButton from '../../MyMatches/components/ConnectButton'
import {getThreadByUser} from '../../Chat/lib/ChatLibApi'
import NewMessageForm from '../../Chat/Components/NewMessageForm';
import { useWindowSize } from '../../lib/useWindowSize';

import { MatchesContext } from '../../App';

const Wrapper = styled.div`
    width: 80%;
    background-color: gray;
    margin: auto;
`

const FixedWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(243, 243, 243, 0.6);
    border-radius: 10px;
    margin: auto;
    font-family: 'Roboto', serif;
    color: #707070;
    z-index: 2;

    p {
        font-size: 1em;
    }
`

const StackedWrapper = styled.div`
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: rgba(243, 243, 243, 0.8);
    border-radius: 10px;
    margin: auto;

    p {
        color: #707070;
        font-family: 'Roboto', serif;
        display: flex;
        justify-content: center;
    }

    input {
        margin-left: 10px;
        cursor: pointer;
        height: 25px;
        width: 25px;

        &:checked {
            color: #f3f3f3;
            background-color: #f3f3f3;
        }
    }

`

const FlirtChat = (props) => {

    const matches = React.useContext(MatchesContext)

    const [threadId, setThreadId] = useState(null)
    const [displayChat, setDisplayChat] = useState(false)
    const [account, setAccount] = useState({})
    const [confirmPay, setConfirmPay] = useState(false)
    const [paidMessageAttempted, setPaidMessageAttempted] = useState(false)

    const windowSize = useWindowSize()


    let connectionStatus = {
        "NONE": {text: "",
            displayChat: false,
            chatPane: <FixedWrapper>
            <ConnectButton onClick={(e) => {
                e.stopPropagation();
                matches.updateMatches(props.profile.username,"LIKE");}}
                />
        </FixedWrapper>,
            icon: <></>},
        "TO": {text: "Liked",
            displayChat: false,
            chatPane: account.tokens >= 1000 ? <StackedWrapper>
                <p>Send Message to {props.profile.title}: Cost 1,000 each</p>
                <p style={{backgroundColor: (paidMessageAttempted ? `gray` : `none`)}}><input 
                    type="checkbox" 
                    onChange={ () => setConfirmPay(!confirmPay)} /></p>
            </StackedWrapper> : <FixedWrapper>
                <p>Cost to Send Message: 1,000</p>
            </FixedWrapper>,
            icon: <img src="/images/match-sent.jpg" alt="Request Sent!" />},
        "FROM": {text: "Likes me",
            displayChat: false,
            chatPane: <FixedWrapper>
            <ConnectButton onClick={(e) => {
                e.stopPropagation();
                matches.updateMatches(props.profile.username,"LIKE");}}
                />
        </FixedWrapper>,
            icon: <img src="/images/match-request.jpg" alt="Match Requested!" />},    
        "CONNECTION": {text: "Connected",
            displayChat: true,
            chatPane: <></>,
            icon: <img src="/images/connection.jpg" alt="Connected!" />}    
    }

    // callback from newMessage, handles calling apiGetAccount if necessary
    const onSend = (messageSuccessful) => {

        console.log("onSend calling")
        if(messageSuccessful && confirmPay) {
            matches.apiGetAccount()
        } else if (!messageSuccessful && props.myMatches==="TO") {
            // trying to send to non-connection without pay selected
            setPaidMessageAttempted(true)
        }

    }

    useEffect( () => {

        console.log("Flirt Chat loaded: ", props)

        if (props) {

            getThreadByUser(props.profile.username)
                .then( id => setThreadId(id))

        }

    },[props.profile])

    useEffect( () => {

        console.log("Flirt Chat: myMatches changed")
        setDisplayChat(connectionStatus[props.myMatches].displayChat)

        if (props.myMatches === 'TO') {

            matches.apiGetAccount( account => setAccount(account))
        }

    },[props.myMatches])

    /**
     * RENDER
     */
    return <div style={{width: "100%", position: "relative", color: "black", marginBottom: windowSize.height > 800 ? "50px" : "10px"}}>
            {!displayChat && connectionStatus[props.myMatches].chatPane}
            <NewMessageForm 
                        confirmPay={props.myMatches!=="TO" || confirmPay }  // confirm pay is true unless myMatches is TO and confirmpay is false
                        wide
                        chatHeight={!displayChat ? 50 : null}
                        onSend={(ms) => onSend(ms)}
                        threadId={Number(threadId)} />
            
        </div>
}


FlirtChat.propTypes = {
    myMatches: PropTypes.string.isRequired

}

export default FlirtChat;