import React, {useState, useEffect, useCallback} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { SelectButton } from 'lovemire-components';
import { errors } from 'exifreader';

const axios = require('axios').default;




const NewUserWizardWrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: center;
  text-align: left;

  @media (max-width: 425px) {
      width: 99%;
  }

  input:not([type=checkbox]) {
    height: 30px;
    width: 90%;
    min-width: 240px;
    margin: 0;
    border: #86888B 1px solid;
    padding: 5px;
    background-color: #ebe6e6;
    border-radius: 5px;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.75);
    -webkit-box-shadow: 0 0 2px 0 rgba(0,0,0,0.75);
    -moz-box-shadow: 0 0 2px 0 rgba(0,0,0,0.75);
}
`

NewUserWizardWrapper.Row = styled.div`
    display: flex;
    flex-direction: row;   
    
    justify-content: center; 

    @media (max-width: 425px) {
        flex-wrap: wrap;
    }
`

NewUserWizardWrapper.Left = styled.div`
  display:flex;
  flex-direction: column;
  flex: 0 0 33%;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  min-width: 190px;
`  
NewUserWizardWrapper.Right = styled.div`
  display:flex;
  flex-direction: column;
  flex: 0 0 66%;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  min-width: 190px;
`  

const Schema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("password")],
        "Does not match"
        )
    })
});


const NewUserForm = (props) => {

    const [newUser, setNewUser] = useState(false)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [isSubmitting, setSubmitting] = useState(false)

    const [complete, setComplete] = useState(false)
    const [signupMessage, setSignupMessage] = useState("")

    const createAccount = async (values) => {

        const authServer = process.env.NODE_ENV === "development" ?
        `http://staging:9200` : "https://lovemire.com/auth";

        try {
            let response = await axios.post( authServer + '/new',
                values)
            
            setComplete(true)
            setSignupMessage("User Created! " + JSON.stringify(response.data) )
        } catch (e) {
            console.log(e.message)
            setSignupMessage("We got an error, yea well, this isn't ready yet! Sorry!" +
                e.message)
            setComplete(true)
        }

    }

    if (complete) {
        return <NewUserWizardWrapper>
                <NewUserWizardWrapper.Left>
                    <p>Signup Complete!</p>
                    <p>{signupMessage}</p>
                </NewUserWizardWrapper.Left>
        </NewUserWizardWrapper>
    } else return (
        <NewUserWizardWrapper>
            <Formik
                initialValues={{email: "", password: "", confirmPassword: ""}}
                validationSchema={Schema}
                onSubmit={ (values) => {
                    if (newUser) createAccount(values)
                }}>
            { ({values, errors}) => (
            <Form>
            {!newUser && <><NewUserWizardWrapper.Row>
                <NewUserWizardWrapper.Left>
                    <p>Email: </p>
                </NewUserWizardWrapper.Left>
                <NewUserWizardWrapper.Right>
                    <Field type="text" name="email" />
                    <span>{errors.email}</span>
                </NewUserWizardWrapper.Right>
            </NewUserWizardWrapper.Row>

            <NewUserWizardWrapper.Row>
                <NewUserWizardWrapper.Left>
                    <p>Password: </p>
                </NewUserWizardWrapper.Left>
                <NewUserWizardWrapper.Right>
                    <Field type="password" name="password" />
                    <span>{errors.password}</span>
                </NewUserWizardWrapper.Right>
            </NewUserWizardWrapper.Row>
            <NewUserWizardWrapper.Row>
                <NewUserWizardWrapper.Left>
                    <SelectButton selected={false} 
                        noCheckBox
                        onClick={() => setNewUser(true)}>
                            Create Account!</SelectButton>
                </NewUserWizardWrapper.Left>
                <NewUserWizardWrapper.Right>
                    <SelectButton selected={false} 
                        type="submit"
                        noCheckBox
                        onClick={() => console.log("submit")}>
                            Login!</SelectButton>
                </NewUserWizardWrapper.Right>                
            </NewUserWizardWrapper.Row>
            </>}
            {newUser && <><NewUserWizardWrapper.Row>
                <NewUserWizardWrapper.Left>
                    <p>Email: </p>
                </NewUserWizardWrapper.Left>
                <NewUserWizardWrapper.Right>
                    <Field type="text" name="email" />
                    <span>{errors.email}</span>
                </NewUserWizardWrapper.Right>
            </NewUserWizardWrapper.Row>

            <NewUserWizardWrapper.Row>
                <NewUserWizardWrapper.Left>
                    <p>Password: </p>
                </NewUserWizardWrapper.Left>
                <NewUserWizardWrapper.Right>
                    <Field type="password" name="password" />
                    <span>{errors.password}</span>
                </NewUserWizardWrapper.Right>
            </NewUserWizardWrapper.Row>
            <NewUserWizardWrapper.Row>
                <NewUserWizardWrapper.Left>
                    <p>Confirm Password: </p>
                </NewUserWizardWrapper.Left>
                <NewUserWizardWrapper.Right>
                    <Field type="password" name="confirmPassword" />
                    <span>{errors.confirmPassword}</span>
                </NewUserWizardWrapper.Right>
            </NewUserWizardWrapper.Row>
            <NewUserWizardWrapper.Row>
                <NewUserWizardWrapper.Left>
                <SelectButton selected={false} 
                        type="button"
                        noCheckBox
                        onClick={() => setNewUser(!newUser)}>
                            Back</SelectButton>
                </NewUserWizardWrapper.Left>
                <NewUserWizardWrapper.Right>
                    <SelectButton selected={false} 
                        type="submit"
                        noCheckBox
                        onClick={() => console.log("submit")}>
                            Add me to Flirvy!</SelectButton>
                </NewUserWizardWrapper.Right>                
            </NewUserWizardWrapper.Row>
            </>
            }
            </Form>
            )}
            </Formik>
        </NewUserWizardWrapper>
    )
}

export default NewUserForm;