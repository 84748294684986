import styled from 'styled-components'

export const ProfileFeedThumb = styled.img`
    height: 180px;
    width: auto;

`

export const ProfileThumb = styled.img`
    height: 50px;
    width: 50px;
    border-radius: 20%;
    margin: auto;

`;

export const ProfileImageExpanded = styled.img`
    height: 200px;
    border-radius: 50%;
    border: 1px black solid;
    margin: auto;

`;

export const Verified = styled.span `
    position: absolute;
    left: 0; bottom: 2%;
    font-size: 1.4em;
    color: #ed7272;
    padding: 0; margin: 0;
    &:after {content: "☑"; }
    &:hover {
        &:before {
            background-color: white;
            font-size: 0.8em;
            border-radius: 5px;
            border: #1f70c2 1px solid;
            padding: 2px;
            content: "Verified";
        }
    }
`;

export const Developer = styled.div `
    position: absolute;
    left: 0; bottom: 2%;
    font-size: 1.4em;
    color: #1f70c2;
    padding: 0; margin: 0;
    &:after {content: "☑"; }
    &:hover {
        &:before {
            background-color: white;
            font-size: 0.8em;
            border-radius: 5px;
            border: #1f70c2 1px solid;
            padding: 2px;
            content: "Developer";
        }
    }

`