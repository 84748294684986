
/**
 * Correspondes to Notification_types on notification server.
 * For now, this will be hand managed. TODO:
 * 
 */
const Nt = {
    getByName: (n) => {
        return Object.values(Nt).filter ( v => v.name === n)[0]
    },
    0: {
        name: "undefined",
        title: "Undefined from server",
        visible: true,
        toUserString: "",
        fromUserString: ""
    },    
    5: {
        name: "free",
        title: "FirstLike",
        visible: false,
        toUserString: "Liked your photo!",
        fromUserString: "You liked a photo!"
    },
    10: {
        name: "matchCreated",
        title: "ProfileLike",
        visible: true,
        toUserString: "Liked you!",
        fromUserString: "You sent Like!",
        noThumb: true
    },
    11: {
        name: "matchDeleted",
        title: "ProfileLike",
        visible: true,
        toUserString: "Unliked you!",
        fromUserString: "You Unliked!",
        noThumb: true
    },
    12: {
        name: "gift-like",
        title: "Gift",
        visible: true,
        toUserString: "sent you a gift!",
        fromUserString: "You sent a gift"
    },
    6: {
        name: "double-like",
        title: "DoubleLike",
        visible: false,
        toUserString: "Double Liked your photo!",
        fromUserString: "You double-liked a photo!"
    },
    13: {
        name: "gift-message",
        title: "Gift Message",
        visible: true,
        toUserString: "sent a Premium Message. You'll receive 200+ when you connect!",
        fromUserString: "You sent a premium message.",
        noThumb: true
    },
    7: {
        name: "admire",
        title: "Admire",
        visible: true,
        toUserString: "Admired your photo!",
        fromUserString: "You admired a photo!"
    },
    8: {
        name: "double-admire",
        title: "DoubleAdmire",
        visible: true,
        toUserString: "Double admired your photo!",
        fromUserString: "You double admired a photo!"
    },
    9: {
        name: "max-like",
        title: "MaxLike",
        visible: true,
        toUserString: "Max Liked your photo!",
        fromUserString: "You max liked a photo!"
    }
}

export const ChatTypes = ["MESSAGE", "IMAGE"]

export default Nt;