import React, {useState, useEffect} from 'react';
import styled from 'styled-components'
import PropTypes from 'prop-types';
import { StyledFormFlexContainer, ProfileWrapper } from '../styles/profile/Wrappers';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Switch, TextField, Select, MenuItem } from "@material-ui/core";
import { getSettings, saveSettings } from '../lib/SettingsApi'
import CoverSave from '../navigation/CoverSave'
import Notifications from './menus/Notifications'
import Tokens from './menus/Tokens'


import { CollaspableMenu } from 'lovemire-components'

import { DataContext, MatchesContext } from '../App';

const SettingsFlex = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    box-sizing: border-box;
    gap: 5px;


    @media (max-width: 410px) {
        flex-wrap: wrap;
    }
`

SettingsFlex.Col = styled.div`
    flex: 0 0 48%;
    min-width: 190px;
    border-radius: 5px;
    border: ${props => props.theme?.borders?.insideMenu ? props.theme.borders.insideMenu : '1px solid black'};
    background-color: ${props => props.theme?.colors?.secondaryBackground ? props.theme.colors.secondaryBackground : 'gray'};
`

SettingsFlex.Row = styled.div`
    min-width: 195px;
`

export { SettingsFlex }

/**
 * 
 * @param {*} props 
 * @returns 
 */
const MySettings = (props) => {

    return <ProfileWrapper>
        <CollaspableMenu title="Tokens">
            <Tokens />
        </CollaspableMenu>
        <CollaspableMenu title="Notifications">
            <Notifications />
        </CollaspableMenu>
        <CollaspableMenu title="Account">
            <h2>Account</h2>
        </CollaspableMenu>
    </ProfileWrapper>
}

export default MySettings;