import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { SquareThumbnail } from '../../../styles/Image';

const StyledLikeButtonWrapper = styled.div`
    position: absolute;
    z-index: 2;
    bottom: 10px;
    right: 10px;
    width: 120px;
    height: 60px;
`

const StyledRequestButtonWrapper = styled.div`
    position: absolute;
    z-index: 2;
    top: 50%; right: 50%;
    transform: translate(50%,-50%);
    border-radius: 15px;
    padding: 15px;
    background-color: rgba(217, 217, 217,0.8);

    button {
        background-color: white;
        padding: 10px;
        border-radius: 5px;
        margin: 5px;
    }

    p {
        font-size: 0.4em;
        color: black;
        padding: 5px;
    }

`


const MyProgressOutside = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.8);
    border-radius: 5px;
    overflow: hidden;
`;

const MyProgressInside = styled.div`
    width: ${props => props.progress ? props.progress : 0}%;
    background-color: pink;
    color: grey;
    font-size: 0.4em;
    height: 100%;
`;

const Hp = (props) => {

    const [progress, setProgress] = useState(0)
    const [score, setScore] = useState(0)
    const [requiredScore, setRequiredScore] = useState(0)

    /**
     * Sets state on load
     * progress (percentage)
     * score (total cost)
     * requiredscore
     * @param {object} requirements 
     */
    const calcProgress = (requirements) => {
        let score = requirements.like_counts.reduce( (total,lc) => total + lc.cost, 0);
        setScore(score);
        let progressPercentage = Math.round((score / requirements.requirements.requiredScore)*100) 
        setProgress(progressPercentage)
    }


    /**
     * Recalculate progress whenever receiving new props
     */
    useEffect ( () => {
        if(!!props.requirements) calcProgress(props)
    }, [props])

    console.log(props)
    return (<>
    {progress >= 100 && 
    <StyledRequestButtonWrapper>
        <SquareThumbnail src={"/images/s.jpg"} style={{zIndex: "99", width: '50px', height: '50px'}}/>
        <button onClick={() => props.onClick()}>See Secret Image!</button>
        <p>(Previous likes/gifts will be locked)</p>
    </StyledRequestButtonWrapper>}
    <StyledLikeButtonWrapper onClick={progress >= 100 ? () => props.onClick(): () => console.log("no progress")}>
        {<MyProgressOutside>
            <MyProgressInside progress={progress} />
        </MyProgressOutside>}
    </StyledLikeButtonWrapper></>)
}

export default Hp;