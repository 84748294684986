import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import loveMireApi from '../../lib/loveMireApi'

const LocationArrow = styled.div`
    color: rgb(202, 181, 181);
    width: 15px;
    height: 15px;
    background-color: transparent;
    font-size: 1.6rem;
    flex-grow: 1;
    padding: 0;
    margin: 0;
    &:after { content: "➣"; }
    transform: rotate(.93turn) translateY(-14px) translateX(-10px);
    cursor: pointer;

    @media (max-width: 750px) {

    }
    @media (max-width: 425px) {
        transform: rotate(.93turn) translateY(-8px) translateX(-8px);
        font-size: 1.6rem;
    }

`;

const SearchInput = styled.input`
    height: 25px;
    width: 80%;
    min-width: 250px;
    flex-grow: 3;
    border: none;
    font-size: 1rem;
    outline: none;
    background-color: transparent;

    @media (max-width: 425px) {
        font-size: 0.8rem;
    }
`;

const SearchDiv = styled.div`
    background-color: #f2f2f2;
    padding: 5px;
    display: flex;
    border-radius: 5px;
    margin-top: 5px;
`;



const Ls = (props) => {


    const googleUrl = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDd0sO8dvBP5wNHd0ORobsQw9kjPr-7b3w&libraries=places&callback=initMap`
    const [googleIsLoaded, setGoogleIsLoaded] = useState(false)

    const [addressString, setAddressString] = useState("");
    const autoCompleteRef = useRef(null);
    
    const [latlng, setLatlng] = useState([])

    let autocomplete

    const loadScript = (url, callback) => {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.async = false
      
        if (script.readyState) {
          script.onreadystatechange = function() {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              callback();
            }
          };
        } else {
          script.onload = () => callback();
        }
      
        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
      };
    
    
      const googleLoad = (updateQuery, autoCompleteRef) => {
    
        const options = {
            types: ['(cities)'],
            fields: ['formatted_address', 'geometry', 'name']
        };
    
        autocomplete = new window.google.maps.places.Autocomplete(
            autoCompleteRef.current,
            options
        )
    
        autocomplete.setFields(['address_components', 'formatted_address']);
    
        autocomplete.addListener('place_changed', () => handlePlaceSelect(updateQuery))
    
    }
    
    const handlePlaceSelect = (updateQuery) => {
    
        const addressObject = autocomplete.getPlace()
        let address = addressObject.formatted_address;
        let point = [addressObject.geometry.location.lat(),addressObject.geometry.location.lng()]
    
        if (address) {
            updateQuery(address)
        }
        if (point) {
            setLatlng(point)
        }

        if (address && point) {
            props.onChange( address, point)
        }
        console.log(addressObject, [addressObject.geometry.location.lat(), addressObject.geometry.location.lng()],
            addressString);
    }
    
    const getLocation = () => {
            
        if ( "geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition( (p) => {
                loveMireApi.getJson("convertCoords", `${p.coords.latitude + '/' + p.coords.longitude}`)
                    .then( response => {                        
                        setAddressString(response.formatted_address)
                        props.onChange(response.formatted_address, [response.lat, response.lng])
                    })
                    .catch( e => props.settings.error(e))
            } )
        } else {
            alert("Can't get location.")
        }
    }

    const handleChange = (e) => {

        // update post request
        props.onChange(e.target.value, [latlng[0],latlng[1]])
        //setAddressString(e.target.value)
    }
    
    
    useEffect( () => {
        if ( process.env.NODE_ENV !== "development") {
            loadScript ( googleUrl, () => googleLoad(setAddressString, autoCompleteRef))
        }
    },[])

    useEffect( () => {

        if (props.postRequest.lat && props.postRequest.lng) {
            setLatlng([props.postRequest.lat, props.postRequest.lng])
        }

        if (props.postRequest.addressString) {
            setAddressString(props.postRequest.addressString)
        }
        
    }, [props])

    return <SearchDiv>
                <SearchInput 
                    ref={autoCompleteRef}
                    value={props.postRequest.addressString}
                    type="text" name="addressString" 
                    size="15"
                    onChange={e => handleChange(e)}
                        />
                <LocationArrow onClick={() => getLocation()} />
            </SearchDiv>
}

export default Ls;