import Cropper from "./CropImage"
import React, {useEffect, useState, useRef} from 'react'
import PropTypes from 'prop-types'
import loveMireApi from "../../lib/loveMireApi"
import { StyledProfileFlexContainer, StyledImageEditContainer } from '../../styles/profile/Wrappers';
import HiddenBar from './components/HiddenBar'
import PreviewImage from '../media/components/PreviewImage'
import CoverSave from '../../navigation/CoverSave'
import { SelectButton, SettingsButton, HideButton, HideBar } from "../../styles/Buttons";
import { TextArea } from "lovemire-components";


import { PostMediaContext, PostUpdateContext } from './EditPost'

const fullCrop = {
    width: 100,
    height: 100,
    x: 0,
    y: 0,
    unit: '%'
}

const defaultRequirements = {
    requiredScore: 43, requiredConnection: false, referenceIds: []
}




const ImageEdit = (props) => {

    const [mediaData, setMediaData] = useState(null)
    const [previewData, setPreviewData] = useState(null)
    const [media, setMedia] = useState(null)
    const [aspect, setAspect] = useState()
    const [isAdded, setAdded] = useState(false)  // is added to post    
    const [isSaving, setSaving] = useState(false)
    const [previewDataUrl, setPreviewDataUrl] = useState(null)
    const [isExpanded, setExpanded] = useState(false)
    const [description, setDescription] = useState("")

    const postUpdate = React.useContext(PostUpdateContext)
    const postMediaList = React.useContext(PostMediaContext)

    /**
     * Call Backs
     */

    /**
     * Called by cropper.
     * 
     * Active is used to specify whether image is just being edited
     * before adding to a post. If false, it won't pass the media up.
     * 
     * (active can be false and the media is still edited if already part of post)
     * 
     * @param {file} img
     * @param {boolean} active
     */
    const updateImageCallBack = (img, active) => {

        console.log("Image CallBack: ", )

        let submitMedia = media;

        submitMedia.file = img
        submitMedia.img = URL.createObjectURL(img)

        postUpdate.modifyMedia(submitMedia)
    }

    /**
     * Calling this uses the state media, mediaData, previewData (if not null)
     * to save the media info to the server
     * 
     * active sets the media to the post with props.updatePost(media)
     * 
     * Once completed will reset isSaving
     * 
     * Both parameters are optional. If nm is supplied, it will be used to
     * create the media payload. If nm is not supplied, the media state will
     * be used.
     * 
     * @param {boolean} active 
     * @param {nm} object
     */
    const apiSaveMedia = async (active, nm) => {

        // probably very simple like mediacallback

        console.log("apiSaveMedia called: ", media, mediaData,previewData, !!media, !!mediaData, !!previewData)

        // must have at least mediaData or media+previewData
    if (!!mediaData || (!!media && !!previewData)) {

        setSaving(true)

        // If this method is supplied an object, it will use that to create payload
        // nm should be created by cloning media
        let submitMedia = nm ? nm : media;

        let request
        let formData = new FormData();
        formData.append('file', new Blob( [await fetch(mediaData).then(r=>r.blob())], {type: 'image/jpeg'}),'media' );
        if (!!previewData) {
            console.log("sending preview", previewData,previewDataUrl,mediaData,new Blob( [await fetch(mediaData).then(r=>r.blob())], {type: 'image/jpeg'}))
            formData.append('preview', previewData, 'media');/*new Blob( [await fetch(previewData).then(r=>r.blob())]{type: 'image/jpeg'})*/
        }
        formData.append('crop', JSON.stringify(fullCrop))
        
        if ( !!submitMedia && submitMedia.mediaId > 0) {
            // Editing existing media
            formData.append('media', JSON.stringify(submitMedia))
            request = loveMireApi.putForm('postMedia', formData)
        } else {
            // new media
            formData.append('media', "{}")
            request = loveMireApi.postForm('postMedia', formData)
        }
        
        try {
            request
                .then ( response => {
                    console.log(response)
                    response.mediaId = response.id;
                    if (active) {
                        setMedia(response)
                        setAdded(true)

                    } else {
                        setMedia(response)
                    }
                    console.log("set saving")
                    setSaving(false)
                })
        } catch (err) {
            console.log(err.response)
            setSaving(false)
        }
    
    } // if check for truthy

    } // End apiSaveMedia

    /**
     * Updates hidden requirements based on form data.
     * 
     * See HiddenBar.js for details on how this is implemented.
     * 
     * @param {target} e 
     */
    const onRequirementsChange = (selected) => {
        console.log("Requirements Change Called:", selected)

        if (selected) {
            // requirements changed from not hidden to hidden
            console.log("to selected")
            setPreviewData(null)
            /*setMedia ( prev => {
                let newMedia = {...prev}
                newMedia.hidden = t.checked ? defaultRequirements : null;
                if(newMedia.hidden) newMedia.hidden.referenceIds = props.post.mediaList.map( p => p.id)
                return newMedia
            })*/
            let newMedia = {...media}
            newMedia.hidden = defaultRequirements;
            if(newMedia.hidden) newMedia.hidden.referenceIds = postMediaList.map( p => p.id)
            apiSaveMedia(true, newMedia)
        } else {
            /*setMedia(
                prev => {
                    let newMedia = {...prev}
                    newMedia.hidden[t.name] = t.type === 'checkbox' ? t.checked : t.value;
                    return newMedia
                }
            )*/

            let newMedia = {...media}
            newMedia.hidden = null;
            apiSaveMedia(true, newMedia)
        }
    }

    /**
     * Updates media with state description and calls save api.
     * This will not save requirements info if modified.
     */
    const saveDescription = () => {

        let newMedia = {...media}
        newMedia.description = description
        postUpdate.modifyMedia(newMedia)

    }

    const savePreviewData = async (o) => {

        console.log("SavePreviewData called: ", o)
        
        function dataURLtoBlob(dataurl) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], {type: 'image/jpeg'});
        }

        //console.log(o,typeof new Blob( [await fetch(previewData).then(r=>r.blob())],{type: 'image/jpeg'}))
        //let image = new Image()
        //image.src = o
        //let file = new Blob([new Uint8Array(o)], {type: 'image/jpeg'})
        //console.log(image)
        //setPreviewData(URL.createObjectURL(file))
        //console.log(o,new Blob([new Uint8Array(o.data)], {type: 'image/jpeg'}))
        //setPreviewData(new Blob( [await fetch(previewData).then(r=>r.blob())],{type: 'image/png'}))
        //console.log(dataURLtoBlob(o))
        console.log(typeof o)

/*      Just lost in callback hell  
        let request
        let formData = new FormData();
        let i = new Image()
        i.src = dataURLtoBlob(o)
        formData.append('preview', dataURLtoBlob(o), 'media');
        new Blob( [await fetch(previewData).then(r=>r.blob())]{type: 'image/jpeg'})
        formData.append('crop', JSON.stringify(fullCrop))
        loveMireApi.postForm('testBase64', formData)
            .then ( r => {
                console.log(r)
                setPreviewDataUrl(r)
            })*/

        //setPreviewData(dataURLtoBlob(o))
        setPreviewData(new Blob( [o], {type: 'image/jpeg'}))
        setPreviewDataUrl(o)
        //setPreviewDataUrl(URL.createObjectURL(new Blob([new Uint8Array(o.data)], {type: 'image/jpeg'})))
        //setSaving(true)
        //apiSaveMedia(true)

    }


    /**
     * Use Effects
     */

    /**
     * Received new props
     */
    useEffect ( () => {

        console.log("Image Edit: received [props] START", props, media)
        setSaving(false)

        // Handle Aspect
        let newAspect = props.aspects && 
            Object.entries(props.aspects).find ( ([k,v]) => v)[0]

        if (newAspect !== aspect ) {            
            setAspect(newAspect)
        }        
        
        props.media && setMedia(props.media)
        props.media && setDescription(props.media.description)
        console.log(props.media.description, description)

        props.media && props.media.img && setMediaData(props.media.img)                


    }, [props])

    /**
     * Rewritten later to call the api to update information based on useEffects
     * 
     * Gets preview data if exists
     * 
     */
    useEffect( () => {
        console.log("Media UseEffect", isSaving)
        if (media && !!media.previewDataId) {
            loveMireApi.getPic('mediaData', media.previewDataId)
                .then ( r => {
                    setPreviewDataUrl(URL.createObjectURL(r))
                })
                .catch ( e => {
                    setPreviewDataUrl(null)
                })
        } else {
            setPreviewDataUrl(null)
        }
    }, [media])

    /**
     * When preview data is updated, needs to call api
     */
    useEffect( () => {
        if (!!previewData) apiSaveMedia()
    }, [previewData])

    /**
     * UseEffect for is saving is used to download new media when update is performed
     */
    useEffect( () => {

    }, [isSaving])

    useEffect ( () => {

        setMedia(postMediaList[props.selected])

    },[postMediaList])

    useEffect( () => {
        console.log(description)
    },[description])


    console.log(props,isAdded,isSaving)
    return (<StyledImageEditContainer>
        {/* @todo <HideBar onClick={postUpdate.finished} />*/}
        <StyledImageEditContainer.Left>
            
        <Cropper 
            disabled={isExpanded}
            aspect={aspect}
            saveMessage={props.selected < 0 ? "Accept" : "Crop"}
            mediaData={mediaData}
            onMediaLoaded= { (mediaSize) => props.onMediaLoaded(mediaSize)}
            updateMedia={updateImageCallBack}>
                
                {!!props.selected && media && !!media.active &&
                 <SelectButton 
                    selected={media && media.hidden}
                    onClick={() => setExpanded(!isExpanded)}>
                    Media Settings
                    </SelectButton>}
            </Cropper>
        </StyledImageEditContainer.Left>
        {!!media && isExpanded && <StyledImageEditContainer.Right>
            <CoverSave show={isSaving}>
            <HideButton 
                onClick={() => setExpanded(!isExpanded)} />

            <p>What were you doing here?</p>
            <TextArea onChange={ v => setDescription(v)}
                onComplete={() => saveDescription()}
                value={description} />
            
            {!!props.selected && props.selected > 0 && 
                <HiddenBar selected={!!media.hidden}
                    hiddenRequirements={media.hidden}
                    update={ e => onRequirementsChange(e) } />}
            {!!props.selected && props.selected > 0 && !!media.hidden &&
            <PreviewImage 
                update={ (o) => savePreviewData(o)}
                mediaData={mediaData}
                previewData={previewDataUrl}
                aspect={aspect}
                />}
            </CoverSave>
        </StyledImageEditContainer.Right>}
    </StyledImageEditContainer>)

}

ImageEdit.propTypes = {
    updatePost: PropTypes.func.isRequired,
    post: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired // can be {}
    /* deleteMediaFromPost: PropTypes.func.isRequired isn't needed, can put X on editpost thumbs */
}

export default ImageEdit;