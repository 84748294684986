import React from 'react'
import CropImage from '../../MyProfile/editpost/CropImage'
import { NewUserWizardWrapper } from '../NewUserWizard'
import loveMireApi from '../../lib/loveMireApi'

import { SelectButton } from 'lovemire-components'

const fullCrop = {
    width: 100,
    height: 100,
    x: 0,
    y: 0,
    unit: '%'
}

const ImageSelection = (props) => {

    const [mediaData, setMediadata] = React.useState(null)

    const updateProfileImage = async (img, active) => {
        
        let request
        let formData = new FormData();
        formData.append('file', new Blob( [img], {type: 'image/jpeg'}, {type: 'image/jpeg'}),'media' );

        formData.append('crop', JSON.stringify(fullCrop))
        

        formData.append('media', "{}")
        request = loveMireApi.postForm('postMedia', formData)
        

        try {
            request
                .then ( response => {

                    console.log(response)
                    props.onChange({profileImageId: response.dataId,
                        profileImageMediaId: response.id})

                    loveMireApi.getPic("mediaData", response.dataId)
                        .then( i => {
                            console.log(i)
                            setMediadata(URL.createObjectURL(i))
                        })
                        .catch( e => console.log("error downloading profile image",e))
            
                })

            } catch ( err ) {
                console.log(err.response)
            }

    }

    return <NewUserWizardWrapper>
    <NewUserWizardWrapper.Col>
        <h2>Profile Image:  </h2>
            <p>Required: You can change any time and you won't appear in searches until your first post.</p>

            <div style={{textAlign: "center", width: "100%"}}>
                <CropImage
                    aspect={1}
                    mediaData={mediaData}
                    updateMedia={ (m) => updateProfileImage(m) } />

            </div>  

        {props.finished && <SelectButton
            selected={true}
            onClick={() => {
                props.doFinished()
            }}
        >Ok!</SelectButton> }
    </NewUserWizardWrapper.Col>
</NewUserWizardWrapper>
}

export default ImageSelection