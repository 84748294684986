import React from 'react'
import Modal from '../styled/MediaModal'

export default function one (e) {

    const dems = {

    }
    return <Modal>
        <Modal.H2>Double Admired!</Modal.H2>
        <Modal.P>Cost: 5</Modal.P>
        <Modal.P>Keep Going!</Modal.P>
        <Modal.P>One more to send gift!</Modal.P>
        {e.children}
    </Modal>

}