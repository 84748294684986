import React from 'react'
import styled from 'styled-components';
import NewUserForm from './forms/NewFormUser';
import LogoWide from '../images/logo-wide.png'

import { SelectButton } from 'lovemire-components';

const FullPageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    width: 100%;
    margin: auto;
`
export const MainWrapper = styled.div`

  width: 100%;
  max-width: 1024px;
  text-align: center;
  margin: auto;

  h1 {
      color: #86888B;
  }
  span {
      color: #CAB5B5;
  }

  h2 {

    color: #86888B;
    margin-bottom: 2px;
    span{
        text-decoration: line-through;
        color: #86888B; 
        transform: rotate(-90deg);
    }

    b {
        color: #CAB5B5;
        font-family: 'Merriweather', serif;
        font-weight: bold;
    }
  }

  p {
      color: #86888B;
      margin: 0;
  }

  `;


const Home = (props) => {

    return  <FullPageWrapper><MainWrapper>
        <h1>Project:</h1>
    <img src={LogoWide} 
        alt="LoveMire"
        style={{display: 'block', margin: '0 auto'}} />
        <h2>Welcome to Flirvy! (Project LoveMire)</h2>
        <NewUserForm />
</MainWrapper></FullPageWrapper>
}

export default Home;