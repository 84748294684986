import React, {useState, useEffect, useReducer} from 'react'

/**
 * HOC for easy wizard progressing while filling out profile
 * 
 * 
 * @param {JSX.Element} WrappedComponent 
 * @param {*} props 
 * @param {*} profileDeconstructor 
 * @param {*} finishedTest 
 * @returns 
 */
const WithProfileManagement = (WrappedComponent, {
    pReducer, finishedTest, ...props
}) => {

    const [profile, setProfile] = useReducer((prev,update) => {
        console.log(prev,update)
        return {...prev,...update}
    },{})

    const [finished, setFinished] = useState(false)

    const doFinished = (lastChange) => {

        if (finished) {
            setFinished(false)
            let newProfile = lastChange ? {...profile, ...lastChange} : profile
            props.onChange(newProfile)
        }
    }

    useEffect( () => {

        // save only gender interestedIn
        console.log(props.profile)
        setProfile( pReducer ? pReducer(props.profile) : props.profile )


    },[])

    useEffect( () => {
        console.log("props", props)
    },[props])

    useEffect( () => {

        if (finishedTest(profile)) {
            setFinished(true)
        }
    },[profile])

    useEffect( () => {

        if (finished && props.autoSlide) {
            const timer = setTimeout ( () => {
                doFinished()
            }, "2000")

            return () => clearTimeout( timer )
        }

    },[finished])


    return <WrappedComponent 
        onChange={ (e) => {console.log(e); setProfile(e);}}
        profile={profile}
        doFinished={(e) => doFinished(e)}
        finished={finished} />

}

export default WithProfileManagement