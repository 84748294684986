import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import loveMireApi from '../../lib/loveMireApi'
import {getFeed} from '../../lib/FeedApi'
import {getMediaLikes} from '../../lib/LikesApi'
import CoverLoad from '../../navigation/CoverLoad'
import PostMedia from '../../feed/media/PostMedia'
import LikeTypes from '../../feed/media/data/LikeTypes'
import ToolTip from '../../tooltip/tooltip'

import { MatchesContext } from '../../App'

const StyledH2 = styled.h2`
    padding: 1em;
    color: black;
`;
/**
 * Displays a PostMedia after retrieving.
 * 
 * Lots to do in this component. postLike and deleteLike are mostly repeated code from feed.js
 * Needs a rewrite as future components may also use the like functions.
 * 
 * @param {Object} props 
 */
const Pmwf = (props) => {

    const matches = React.useContext(MatchesContext)

    const [isLoading, setLoading] = useState(true)
    const [post, setPost] = useState(null)

    
    /**
     * Called when the user pressed the like button.
     * The media Id and the type of like must be passed.
     * 
     * Since this is a cost associated function, some decisions will need
     * to be made about the handling.
     * 
     * Event Driven. Payment Service puts an event out. Front end updates
     * through websocket or polling.
     * 
     * @param {long} mediaId 
     * @param {String} likeType 
     */
     const postLike = (mediaId, likeType) => {

        const verifyLikeType = (lt) => {
            if (lt === 'none') return false
            let type = LikeTypes.filter ( l => l.name === lt)[0]

            if (type === undefined) return false

            return true
        }
      
        console.log("Post Like: ", mediaId, likeType)

        let likeTypeArgument = ""

        if (Array.isArray(likeType)) {

            let likeTypes = likeType.map ( lt => lt.name ).filter(lt => verifyLikeType(lt))
            if (likeTypes.length > 0) {
                let highestLike = likeTypes[likeTypes.length - 1]
                //setTipName(`${highestLike.replace(/-/g,'_').replace("free","free_like")}`)
                likeTypeArgument = likeTypes.join(",")
            }


        } else {

            if(verifyLikeType(likeType)) {
                //setTipName(`${likeType.replace(/-/g,'_').replace("free","free_like")}`)
                likeTypeArgument = likeType
            }

        }

        if(likeTypeArgument.length > 0) {

        loveMireApi.postJson('postLike', {}, `${mediaId}/${likeTypeArgument}`)
            .then ( (response) => {
                // This should be a 200 code which means we received a new like
                setPost ( prevPost => {
                        let newPost = {...prevPost}
                        newPost.mediaList.forEach( media => {
                            if ( media.mediaId === mediaId ) {
                                // add to userLike
                                try {
                                    media.user_likes = media.user_likes.concat(response)
                                } catch (e) {props.settings.error({error: e,response: response,post: post})}
                            }
                        })                    
                    return newPost;})             
            })
            .catch( e => {

                if (!check409(e)) {
                    props.settings.error(e)
                }

            })
        }

    }

    /**
     * Structurally same as postLike, need to DRY
     * @param {*} mediaId 
     * @param {*} likeType 
     * @returns 
     */
    const postDeleteLike = (mediaId, likeType) => {

        const verifyLikeType = (lt) => {
            if (lt === 'none') return false
            let type = LikeTypes.filter ( l => l.name === lt)[0]

            if (type === undefined) return false

            return true
        }

        //console.log("Delete Like: ", mediaId, likeType)

        let likeTypes = []
        let likeTypesArgument = ""

        if (Array.isArray(likeType)) {
            likeTypes = likeType.map( lt => lt.name).filter( lt => verifyLikeType(lt))
            likeTypesArgument = likeTypes.join(",")
            //console.log(likeTypes, likeType, LikeTypes)
        } else {

            if (!verifyLikeType(likeType)) return;
            likeTypesArgument = likeType;
            likeTypes = [likeType]
            //console.log(likeTypesArgument)
        }

        loveMireApi.deletePath('postLike', `${mediaId}/${likeTypesArgument}`)
            .then ( (response) => {
                // This should be a 200 code 
                console.log("found it", `${mediaId}/${likeTypesArgument}`, response)
                setPost ( prevPost => {
                        let newPost = {...prevPost}
                        newPost.mediaList.forEach( media => {
                            if ( media.mediaId === mediaId ) {
                                
                                media.user_likes = media.user_likes.filter (ul => {
                                    return !likeTypes.includes(ul.likeType.name)}
                                )
                            }
                        })
                        return newPost;
                    })
            })
            .catch( e => {
                if (!check409(e, "Likes Locked, hidden image viewed?")) {
                    console.log("caught 409")
                    props.settings.error(e)
                }
            })

    }


    const check409 = (e, message = "Server Responded Conflict: Reloading Likes") => {
        // Check for 409, if 409, reload fresh likes and give message of error and reloading
        if ( e.response && e.response.status === 409) {
        
            props.settings.message(message)

            // this causes a flicker, but should be rare in production
            getMediaLikes([post]) 
                .then( newPost => { 
                    setPost(newPost[0])
                    setLoading(false)
                })
            return true
        }
        return false;
    }

    useEffect( () => {

        console.log("loaded Pmwf: ", props)

        const payload = {
            
        }

        if (props.mediaId) {
            payload.findByMedia = [Number(props.mediaId)]
        }

        if (props.postId) {
            payload.findInPosts = Array.isArray(props.postId) ? props.postId : [props.postId]
        }

        if (props.showDrafts) {
            payload.showDrafts = true
        }

        getFeed(payload)
            .then ( response => {

                setPost(response[0])

            })
            .catch (e => console.log(e))

    }, [])

    /**
     * Populates with additional data (media likes)
     * 
     * Should only run once when loading
     */
    useEffect( () => {

        if (isLoading && !!post && !!post.mediaList) {
            getMediaLikes ([post])
                .then(newPost => {
                    setPost(newPost[0])
                    setLoading(false)
                })
        }

        if (isLoading && !!post && post.mediaList.length == 0) {
            // empty post, just unset loading
            setLoading(false)
        }

    },[post])


    return <CoverLoad show={isLoading}>
        {post && post.mediaList.length > 0 && <PostMedia {...post}
            self={matches.getUser() === post.account.username}
            activeMedia={props.mediaId}
            onLike={ (id, t) => postLike(id, t) }
            onDelete={ (id, t) => postDeleteLike(id, t) } />}
        {!post || post.mediaList.length == 0 && <StyledH2>Post Does Not Exist.</StyledH2>}
    </CoverLoad>

}

export default Pmwf;