import styled from 'styled-components'

// font-size: calc(10px + 2vmin); just doesn't work right on desktop
export const FeedWrapper = styled.div`
    background-color: none;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    color: white;
    margin-top: ${props => props.marginTop ? props.marginTop : "120px"};
`

export const PostWrapper = styled.div`
    background-color: ${props => props.theme.profileBar};
    margin: 9px 0;
    box-shadow: 1px 1px 7px 1px rgba(0,0,0,0.5);
    ${props => props.dimensions ? `width: ${props.dimensions[0]}px; height: ${props.dimensions[1]}px;` : null}
    `;

