import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import loveMireApi from '../lib/loveMireApi';
import { ExpandedProfile } from '../styles/profile/Cards';
import {XMarkButton, CheckMarkButton } from '../styles/Buttons'
import { ProfileFeedThumb, ProfileThumb, ProfileImageExpanded } from '../styles/profile/Images';
import OnlineStatus from '../MyMatches/components/OnlineStatus'

import { MatchesContext } from '../App';

const ProfileThumbGrid = styled.div`
    display: flex;
    flex-wrap: wrap;

    div {
        flex-grow: 1;
        width: 33%;
        height: 180px;
        display: flex;
        justify-content: center;
        cursor: pointer;

        img {
            object-fit: cover;
            height: auto;
            width: 100%;
        }
    }
`

const Profile = (props) => {

    const [profileImage, setProfileImage] = useState(null)    
    const [profilePostThumbs, setProfilePostThumbs] = useState({});

    const matches = React.useContext(MatchesContext)

    /**
    * Component received new props, this is used to save profile image
    * 
    * This is redundant to ProfileBar currently. Once backend image processing
    * is complete and images have a thumbed version, this will be necessary to get the
    * higher res image.
    */
    useEffect ( () => {

        if ( !profileImage && !!props.profile && Object.keys(props.profile) !== 0) {

            loveMireApi.getPic('mediaData', props.profile.profileImageId)
                .then ( pic => {
                    setProfileImage(URL.createObjectURL(pic))
                })
                .catch(e => console.log(e.response, props.profile, props.profile.profileImageId))
        }

        if (Object.keys(profilePostThumbs).length === 0 // haven't updated yet
            && props.profile.postLinks.length !== 0 // user has links
            ) {
            // do get for profile thumbs
            let postLinks = props.profile.postLinks.map( pl => pl.linkId).join(',')
            console.log(profilePostThumbs, props.profile, postLinks)
            loveMireApi.postJson( 'profileThumbs', {}, postLinks)
                .then ( (response) => {

                    if (response.length > 0) {
                        // get individual images
                        response.map ( post => {

                            loveMireApi.getPic('mediaData', post.mediaList[0].dataId)
                                .then ( pic => {
                                    setProfilePostThumbs ( prevData => {
                                        let newData = {...prevData}
                                        console.log("adding")
                                        newData[post.id] = URL.createObjectURL(pic);
                                        return newData;
                                    })
                                })
                                .catch(e => console.log(e.response))
                        })
                    }

                })
                .catch (e => console.log(e.response))
        } 

    }, [props])

    let connectionStatus = {
        "NONE": {text: "",
            xmark: <XMarkButton onClick={ () => console.log("Hide!")} />,
            check: <CheckMarkButton onClick={ () => {
                matches.updateMatches(props.profile.username, 'LIKE')}} />,
            icon: <></>},
        "TO": {text: "Liked",
            xmark: <XMarkButton onClick={ () => matches.updateMatches(props.profile.username, 'HIDE') } />,
            check: <></>,
            icon: <img src="/images/match-sent.jpg" alt="Request Sent!" />},
        "FROM": {text: "Likes me",
            xmark: <XMarkButton onClick={ () => console.log("Hide!")} />,
            check: <CheckMarkButton onClick={ () => matches.updateMatches(props.profile.username, 'LIKE')} />,
            icon: <img src="/images/match-request.jpg" alt="Match Requested!" />},    
        "CONNECTION": {text: "Connected",
            xmark: <XMarkButton onClick={ () => matches.updateMatches(props.profile.username, 'HIDE')} />,
            check: <></>,
            icon: <img src="/images/connection.jpg" alt="Connected!" />}    
    }

    const InteractionButtons = 
        <>
            {connectionStatus[props.myMatches].xmark}
            {connectionStatus[props.myMatches].check}
        </>

console.log(props.online)
    return  <ExpandedProfile>
        <ExpandedProfile.Row>
            <ExpandedProfile.Column>
                {profileImage && <ProfileImageExpanded src={profileImage} />}
            </ExpandedProfile.Column>
            <ExpandedProfile.Column>
                <ExpandedProfile.Row>
                    <h2>{props.profile.title}
                    <span>{props.profile.age}</span>
                    </h2>     
                    <OnlineStatus showLastOnline {...props.online} />               
                </ExpandedProfile.Row>
                <ExpandedProfile.Row>
                    {InteractionButtons}                              
                </ExpandedProfile.Row>
            </ExpandedProfile.Column>
        </ExpandedProfile.Row>
        <ExpandedProfile.Row>
            <ExpandedProfile.Column>
            <i>{"\"" + props.profile.aboutMe + "\""}</i>
            <h3>{props.profile.lookingFor}</h3>
            </ExpandedProfile.Column>
        </ExpandedProfile.Row>
        <ExpandedProfile.Row>
            <ExpandedProfile.Row>
                <ProfileThumbGrid>
            {Object.entries(profilePostThumbs).map ( ([k,thumb]) => {
                console.log(thumb)
                return <div key={k}>
                    <img 
                        onClick={() => props.onMediaClick(k)}
                        src={thumb}
                        />
                </div>
            })}
            </ProfileThumbGrid>
            </ExpandedProfile.Row>
        </ExpandedProfile.Row>
    </ExpandedProfile>
    
}

export default Profile;

Profile.propTypes = {
    profile: PropTypes.object.isRequired
}