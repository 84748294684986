import React, {useState, useEffect, useCallback} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import styled from 'styled-components';
import loveMireApi from '../lib/loveMireApi';
import Cropper from './editpost/CropImage'
import {StyledNav, StyledUl, StyledLi, Sp, LiDiv} from '../navigation/styles/StyledNavigation'
import {getFeed} from '../lib/FeedApi'
import PostMedia from '../feed/media/PostMedia'
import {HideButton, EditButton, ProfileButton} from '../styles/Buttons'
import {getMediaLikes} from '../lib/LikesApi'
import {BigButton} from '../styles/Buttons'
import { ProfileWrapper, 
    StyledProfileFlexContainer,
    StyledProfileLeftColumn,
    StyledProfileRightColumn } from '../styles/profile/Wrappers'
import { ProfileCard } from '../styles/profile/Cards'
import { StyledFormFlexContainer } from '../styles/profile/Wrappers';
//import RadioSelection from '../components/RadioSelection'

import { SelectButton, RadioSelection, ConfirmationModal } from 'lovemire-components'

import { DataContext } from '../App';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
  } from "react-router-dom";
import { Select } from '@material-ui/core';

  const fullCrop = {
    width: 100,
    height: 100,
    x: 0,
    y: 0,
    unit: '%'
}


const MyProfile = (props) => {

    const [account,appSettings] = React.useContext(DataContext)

    // State
    const [profile, setProfile] = useState(null)
    const [profileImage, setProfileImage] = useState(null) // TODO: default image
    const [profileUpdating, setProfileUpdating] = useState(false) // used to fire api, set to true when profile needs pushing
    const [mainFeed, setMainFeed] = useState([])
    const [mainFeedKeys, setMainFeedKeys] = useState({})
    const [profileFeed, setProfileFeed] = useState([])
    const [editing, setEditing] = useState(false)
    const [showDrafts, setShowDrafts] = useState(false)

    // used to show cropper
    const [isEditingImage, setEditingImage] = useState(false)

    // used for save prompts
    const [profileImageChanged, setProfileImageChanged] = useState(false)
    const [profileChanged, setProfileChanged] = useState(false)
    const [submitPressed, setSubmitPressed] = useState(false)

    // to acesss form values
    // @todo
    // This was an attempt to show a confirmation when profile changed.
    // Formik doesn't make this easy. Could update all the handleChange
    // on each field...
    // For now, we have the prompt on profile changed which was most important.
    const formikRef = React.useRef()

    const [perMediaNotifications, setPerMediaNotifications] = useState([])
    const [seeMediaId, setSeeMediaId] = useState(null)

    // Route
    let { path, url } = useRouteMatch();

    // Use Effects
    useEffect( () => {

        loveMireApi.getJson('myProfile')
            .then ( response => {
                setProfile(response)
            })
            .catch( e => console.log(e.response))

    }, [])

    useEffect( () => {
        console.log("MyProfile: new props", props)
    },[props])

    /**
     * Downloads the profile image and feeds
     */
    useEffect( () => { 
        if (profile) {

        getProfilePic()

        // Feed
        getFeed({"findByUsername": profile.name, "showDrafts": showDrafts})
            .then( response => {
                //setMainFeed(response) // yuck, don't like this code
                getMediaLikes(response)
                    .then ( finalResponse => {
                        setMainFeed(finalResponse)
                    })
            })

        // Static Profile posts (profile.postLinks)
        console.log(profile, profile.postLinks.map(pl => pl.linkId))
        getFeed({"findInPosts": profile.postLinks.map(pl => pl.linkId)})
            .then( response => {
                console.log("findInPosts:", response)
                getMediaLikes(response)
                    .then ( finalResponse => {
                        setProfileFeed(finalResponse)
                    })
            })

    }}, [profile, showDrafts])

    useEffect( () => {

        // set key
        //let newKeys = mainFeed.map( p => {
        //    p.key = p.id + `${p.mediaList.reduce( (a,b) => a+b.likes.length,0)}`
        //})
        //setMainFeedKeys(newKeys)
        console.log("new main feed")
        
    }, [mainFeed])

    /**
     * When this is set to an id, calls an ip to get notifications for the
     */
    useEffect ( () => {
        
    },[seeMediaId])

    /**
     * Fired when profile is updated and should push
     */
    useEffect ( () => {
        if (profileUpdating) {
            updateProfile();
        }
    }, [profileUpdating])

    useEffect( () => {
        setProfileImage(null) // resets image to force download
    }, [editing])

    useEffect( () => {
        if (!profileImage) {
            getProfilePic();
        }
    }, [profileImage])

    useEffect ( () => {
        if (submitPressed && !profileChanged && !profileImageChanged ) {
            setEditing(false)
            setSubmitPressed(false)
        }
    },[submitPressed])
    
    // END Use Effects

    // UPDATING PROFILE

    const getProfilePic = () => {
        if ( profile && profile.profileImageId) {
            loveMireApi.getPic('mediaData', profile.profileImageId)
            .then( response => {
                setProfileImage(URL.createObjectURL(response))
            })
            .catch( e => console.log(e.response))
        }
    }

    const updateProfileImage = async (img, active) => {

        console.log("Updating Profile Image: ", profile, active)
        
        let request
        let formData = new FormData();
        formData.append('file', new Blob( [img], {type: 'image/jpeg'}, {type: 'image/jpeg'}),'media' );

        formData.append('crop', JSON.stringify(fullCrop))
        
        if ( profile.profileImageId !== null) {
            // Editing existing media
            let submitMedia = { id: profile.profileImageMediaId, display: "PROFILE", validate: "QUEUED"}
            formData.append('media', JSON.stringify(submitMedia))
            request = loveMireApi.putForm('postMedia', formData)
        } else {
            // new media
            formData.append('media', "{}")
            request = loveMireApi.postForm('postMedia', formData)
        }

        try {
            request
                .then ( response => {

                    console.log(response)
                if (active) {
                    setProfileImageChanged(false)
                    setProfile ( profile => {
                        let newProfile = {...profile};
                        newProfile.profileImageId = response.dataId;
                        newProfile.profileImageMediaId = response.id;
                        return newProfile;
                    })
                    setProfileUpdating(true)
                } else {
                    setProfileImageChanged(true)
                    loveMireApi.getPic("mediaData", response.dataId)
                        .then( i => setProfileImage(URL.createObjectURL(i)))
                }
            
                })
            } catch ( err ) {
                console.log(err.response)
            }

    }
    

    const updateProfile = () => {

        console.log("Updating Profile", profile)

        loveMireApi.postJson('myProfile', profile)
            .then ( response => {
                console.log("Successful Profile update: ", response)
                appSettings.message("Profile Updated")
                setProfileUpdating(false)
            })
            .catch(e => { setProfileUpdating(false);
                console.log(e, e.response)
            });
    }

    const removePost = (id) => {

        console.log("Remove post: ", id)
        loveMireApi.deletePath('post',id)
            .then ( response => {
                appSettings.message("Post Removed");

                setMainFeed( prev => {
                    let newFeed = prev.filter( e => e.id !== id)
                    return newFeed
                })

            })
            .catch (e => console.log(e, e.response) )
    }

    const addPostToProfile = (id) => {
        setProfile ( prev => {
            let newProfile = {...prev}
            newProfile.postLinks.push({linkId: id, active: true})
            return newProfile
        })
        setProfileUpdating(true)
        console.log("Add Post To Profile: ", id)
        appSettings.message("Added Post to Profile")
    }


    console.log(formikRef?.current?.dirty, profile, mainFeed, profileFeed)
    if (profile) {
    return (
        <ProfileWrapper>
        {editing && <HideButton onClick={ () => setSubmitPressed(true) }/>}
        {!editing && <StyledProfileFlexContainer>
            <StyledProfileLeftColumn>
                {/*Profile Image*/}
                <ProfileCard.Image src={profileImage} />
            </StyledProfileLeftColumn>
            <StyledProfileRightColumn>
            <ProfileCard>
                <EditButton onClick={ () => setEditing(true) }/>
                <ProfileCard.Row>
                    <ProfileCard.Title>
                    {profile.title}
                    </ProfileCard.Title>
                </ProfileCard.Row>
                <ProfileCard.Row>
                    <ProfileCard.Description>
                    {profile.birthday}
                    </ProfileCard.Description>
                </ProfileCard.Row>
                <ProfileCard.Row>
                    <ProfileCard.Description>
                    {profile.aboutMe}
                    </ProfileCard.Description>
                </ProfileCard.Row>
                <ProfileCard.Row>
                    <ProfileCard.Description>
                    {profile.lookingFor}
                    </ProfileCard.Description>
                </ProfileCard.Row>     
            </ProfileCard>           
            </StyledProfileRightColumn>
            
        </StyledProfileFlexContainer>}
        {editing && <StyledProfileFlexContainer>
            {submitPressed && (profileChanged || profileImageChanged) &&
                <ConfirmationModal
                    cancelButtonText="Back"
                    message={profileImageChanged ?
                        "Image will be lost" : "Changes will be lost"}
                    onConfirm={ () => setEditing(false) }
                    onCancel={ () => setSubmitPressed(false) }>
                    {profileImageChanged ? 'Profile Image not saved, exit?' :
                        "Changes not saved, exit?"}
                </ConfirmationModal>}
            <StyledProfileLeftColumn>
                {isEditingImage ?
                <Cropper 
                    aspect={1}
                    mediaData={profileImage}
                    onMediaLoaded= { (mediaSize) => console.log("MediaLoaded: ", mediaSize)}
                    updateMedia={ (m, active) => updateProfileImage(m, active)}
                    /> :
                    <div>
                        <ProfileCard.Image src={profileImage} />
                        <SelectButton noCheckBox onClick={() => setEditingImage(true)}>
                            Change Profile Image
                        </SelectButton>
                    </div>
                }
            </StyledProfileLeftColumn>
            <StyledProfileRightColumn>
                <ProfileCard>
                    {/*<HideButton onClick={ () => setEditing(false) }/>*/}
                    
                <Formik
                    initialValues={profile}
                    innerRef={formikRef}
                    onSubmit={ (values, { setSubmitting }) => {
                        
                        const genderConversion = {
                            "Man": 0, "Woman": 1,
                            0: 0, 1: 1, "0": 0, "1": 1
                        }

                        // update profile and set
                        let newProfile = {...profile}
                        Object.keys(values).forEach( k => {

                            if (k === "gender") {
                                console.log("%c Test Gender: ", "background: red", k, values[k])
                            } else {
                                console.log("%c Test Gender: ", "color: blue", k, values[k])
                            }
                            // convert genders to int for server
                            values[k] = (k === "gender" || k === "interestedIn") ? 
                                genderConversion[values[k]] : values[k]

                            newProfile[k] = values[k]
                        })
                        setProfile(newProfile)
                        setProfileUpdating(true)
                    }}
                    >
                    {({values, handleChange, handleBlur, setFieldValue, profileUpdating}) => (
                        
                    <Form>
                    <StyledFormFlexContainer>
                    <li>
                        <label>
                        <p>First Name</p>
                        </label>
                        <Field type="text" name="title" />
                        
                    </li>
                    <li>
                        <label>
                            <p>I am </p>

                            {/*<select
                                disabled={false}
                                name="gender"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                >
                                <option value="Man" selected={values.gender === 0 ? true : false}>Man</option>
                                <option value="Woman" selected={values.gender === 1 ? true : false}>Woman</option>
                            </select>*/}
                        </label>
                        <RadioSelection 
                                selections={
                                    [{title: 'Man', name: 0, selected: values.gender === 0},
                                    {title: 'Woman', name: 1, selected: values.gender === 1}]}
                                select={(e) => setFieldValue('gender', e)}/>
                    </li>
                    <li>
                        <label>
                            <p>seeking: </p>                            
                 
                        </label>
                        <RadioSelection 
                                selections={
                                    [{title: 'Man', name: 0, selected: values.interestedIn === 0},
                                    {title: 'Woman', name: 1, selected: values.interestedIn === 1}]}
                                select={(e) => setFieldValue('interestedIn', e)}/>    
                    </li>
                    {!profile.genderLocked && <li>
                        <label>
                            <p>Gender preferances can be changed one time.</p>
                        </label>
                    </li>}
                    <li>
                        <label>
                        <p>Birthday</p>
                        </label>
                        <Field type="date" name="birthday" />
                        
                    </li>
                    <li>
                        <label>
                        <p>Short Bio</p>
                        </label>                                                
                    </li>
                    <li>
                        <Field type="text" name="aboutMe" />
                    </li>
                    <li>
                        <label>
                        <p>Full Bio</p>
                        </label>                        
                    </li>   
                    <li>
                        <Field name="lookingFor" as="textarea" type="textarea" />
                    </li>
                    <li>
                        <label>
                            <button type="submit" disabled={profileUpdating}>Update</button>
                        </label>
                        <label>
                            <button type="cancel" onClick={() => setEditing(false)}>Cancel</button>
                        </label>
                    </li>   
                    </StyledFormFlexContainer>
                    </Form>
                    )}             
                </Formik>
                </ProfileCard>
            </StyledProfileRightColumn>
            
        </StyledProfileFlexContainer>}

        <Link to="/MyProfile/AddPost">
        <BigButton>Add Post</BigButton>
        </Link>
        <StyledNav size={'min'}>
            <StyledUl>
                <StyledLi>
                    <LiDiv>
                        <Link to={`${url}/feed`}><Sp>Feed</Sp></Link>
                    </LiDiv>
                </StyledLi>
                <StyledLi>
                    <LiDiv>
                        <Link to={`${url}/profileFeed`}><Sp>Profile</Sp></Link>
                    </LiDiv>
                </StyledLi>

            </StyledUl>

        </StyledNav>

        <StyledProfileFlexContainer>
            <Switch>
                <Route exact path={`${url}/profileFeed`}>
                    <div>
                    {profileFeed.map( post => <PostMedia self 
                        key={post.id} {...post}>
                        <HideButton onClick={() => console.log("remove from profile feed")} />
                        </PostMedia>
                    )}
                    </div>
                </Route>
                <Route path={`${url}`}>
                    <div>
                        <p>Show Drafts? <input type="checkbox" checked={showDrafts} onChange={() => setShowDrafts(!showDrafts)} /></p>
                    {mainFeed.map( post => { if (!!post.mediaList && post.mediaList.length > 0) return (
                        <PostMedia 
                            self 
                            key={post.id  + `${!!post.mediaList[0].user_likes ? post.mediaList[0].user_likes.length : 0}`}
                            {...post}>
                        <HideButton onClick={() => removePost(post.id)}/>
                        <ProfileButton onClick={ () => addPostToProfile(post.id)} />
                        <Link to={`/MyProfile/AddPost/${post.id}`}><EditButton /></Link>
                        
                        </PostMedia>)
                        else return (
                            <div key={post.id} style={{position: "relative"}}>
                                Draft Post
                                <Link to={`/MyProfile/AddPost/${post.id}`}><EditButton /></Link>
                                <HideButton onClick={() => removePost(post.id)}/>
                            </div>
                        )
                    }
                    )}
                    </div>
                </Route>
            </Switch>
        </StyledProfileFlexContainer>
        </ProfileWrapper>
    )} else return (<div>Loading...</div>)
}

export default MyProfile;