import styled from 'styled-components'

export const ProfileCard = styled.div`
  position: relative;
`

const StyledProfileRow = styled.div`
    display: flex;
    text-align: left;
`;

const StyledTitle = styled.h2`
    color: black;
    font-family: 'FreeStyle Script', cursive;
`;

const StyledDescription = styled.p`
    color: black;
`;

const ProfileImg = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
`

ProfileCard.Row = StyledProfileRow
ProfileCard.Title = StyledTitle
ProfileCard.Description = StyledDescription
ProfileCard.Image = ProfileImg

export const ExpandedProfile = styled.div`

    height: 100%;
    width: 100%;
    background-color: inherit;
    padding: 0;
    margin: 0 0 5px 0;
    display: flex;
    flex-direction: column;
    overflow: auto;

    h2 {
        color: #282c34;
        font-size: 1.2em;
        font-weight: normal;
        font-family: 'Segoe Print', cursive;
        display: inline-block;
        margin: 5px 0 0 15px;
        line-height: 50px;
        /*text-shadow: 2px 2px #CAB5B5;*/
    }

    h3 {
        text-align: left;
        padding: 10px;
        color: #282c34;
        font-weight: normal;
        font-size: 0.6em;
        border: 0.5px solid gray;
        margin: 5px;
    }

    p {
        margin: 10px 10px 5px 10px;
        color: #282c34;
        font-size: 0.8em;
        font-weight: bolder;
        font-family: 'Roboto';
    }

    i {
        font-family: 'Roboto', serif;
        color: black;
        font-size: 0.7em;
    }

    span {
        margin: 0 0 0 1em;
        color: black;
        font-size: 0.6em;
        font-weight: normal;
        font-family: 'Roboto', serif;
        text-shadow: none;
    }

`

const StyledExpandedProfileColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
`;

const StyledExpandedProfileRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 15px 0 0 0;
    flex: 1;
`;

ExpandedProfile.Column = StyledExpandedProfileColumn
ExpandedProfile.Row = StyledExpandedProfileRow

