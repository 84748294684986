import React from 'react'
import StyledModal, {StyledH2, StyledP} from '../styled/modal'
import Modal from '../styled/MediaModal'

export default function one (e) {

    const dems = {

    }

    console.log(e)

    return <Modal>
            <Modal.H2>
                Liked!
            </Modal.H2>
            <Modal.P centered>Cost: FREE!</Modal.P>
            <Modal.P>{e.profile.title}'s media score will increase!</Modal.P>
            <Modal.P>Look for the <Modal.SPAN>gold color</Modal.SPAN> when liking costs tokens!</Modal.P>
            <Modal.P>Admire to get more attention!</Modal.P>
            <Modal.P centered><input type="checkbox" />Don't show again</Modal.P>
            {e.children}
    </Modal>

}