import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import loveMireApi from '../lib/loveMireApi';
import FlirtChat from './chat/FlirtChat'
import { ScrollCard } from '../styles/Cards';
import NotificationLine from '../MyMatches/components/NotificationLine';
import MessageLine from '../MyMatches/components/MessageLine'
import {HideButton, SelectButton} from '../styles/Buttons'
import FullModal from '../styles/Modals';
import PostMediaWithFind from '../MyMatches/components/PostMediaWithFind'
import {ChatTypes} from '../MyMatches/data/NotificationTypes'
import {markSeenFromUser} from '../lib/NotificationsApi'

import { MatchesContext } from '../App';

const Flirt = (props) => {

    const matches = React.useContext(MatchesContext)

    const [hiarchyNotifications, setHiarchyNotifications] = useState({})

    // post modal state
    const [showPostMediaModal, setShowPostMediaModal] = useState(true)
    const [modalMediaId, setModalMediaId] = useState(null)
    const [modalPostId, setModalPostId] = useState(null)
    const [scrollBottom, setScrollBottom] = useState(true)

    const onScroll = useCallback ( e => {
        let obj = e.target
        if( obj.scrollTop >= (obj.scrollHeight - obj.offsetHeight)-2) {
            // scroll on bottom
            setScrollBottom(true)
        } else {
            console.log("Setting false")

            // unfortunately, only a difficult solution can fix this.
            // We need to detect when the scroll is caused by the dom changing
            // otherwise, the cursor will always go to bottom when a new notification arrives
            //setScrollBottom(false)
        }
    },[])

    // use state ref
    // https://medium.com/welldone-software/usecallback-might-be-what-you-meant-by-useref-useeffect-773bc0278ae
    // memory leak? Revisit with experience
    const useRefWithCallback = () => {

        const nodeRef = useRef(null)
        
    
        const setRef = useCallback( n => {

            if (n !== null) {
                // on mount, we scroll to bottom, for now
                // TODO: impl a stick to bottom if scrolled
                if (scrollBottom) {
                    console.log("scroll")
                    n.scrollTop = n.scrollHeight - n.clientHeight
                } else {
                }
                
            }

            if (nodeRef.current) {
                nodeRef.current.removeEventListener("scroll", onScroll)
            }

            nodeRef.current = n

            if (nodeRef.current) {
                nodeRef.current.addEventListener("scroll", onScroll)
            }



        })
        
        return setRef
    }

    const scrollCardRef = useRefWithCallback()



    const findUser = (match) => !!matches.findUser ? matches.findUser(match) : {title: "not found"}

    /**
     * Sorts the hiarchyNotifications by created.
     * 
     * @param {String} match 
     * @returns 
     */
    const notificationsSortedByMatch = (match) => {

        /*if (hiarchyNotifications && match in hiarchyNotifications) {
            console.log( hiarchyNotifications[match]['168'].map(e => e.created),
            Object.values(hiarchyNotifications[match]).sort ( (a,b) => {
                return a.map(e => e.created).reduce( (ea,eb) => ea > eb ? ea : eb) >
                 b.map(ee => ee.created).reduce ( (eea,eeb) => eea > eeb ? eea : eeb)
            }))}*/
        if (!!hiarchyNotifications && match in hiarchyNotifications) {
            return Object.values(hiarchyNotifications[match]).sort ( (a,b) => {
                let aDate = a.map(e => Date.parse(e.created)).reduce( (ea,eb) => ea > eb ? ea : eb)
                let bDate = b.map(ee => Date.parse(ee.created)).reduce ( (eea,eeb) => eea > eeb ? eea : eeb)
                
                return aDate - bDate
            })
        } else return {}
    }

    /**
     * Marks all notifications as seen. Called hiarchyNotifications is updated,
     * the user exists in hiarachyNotifications, and this pane is open
     * 
     * @param {String} user 
     */
    const markSeen = (user) => {

        // confusing much?
        // markSeenFromUser in the import calls the api.
        // After which, the matches object is updated independantly
        markSeenFromUser(user)
            .then ( () => {
                // for now, we put a 3 second timeout previously unseen notifications
                // show for user momentarily.
                // Could put this in return () of useeffect if wanted it to be updated when closed
                setTimeout ( () => matches.markSeenFromUser(user), 3000)
            })
            .catch ( e => matches.handleError(e))
    }


    useEffect( () => {

        // handle setting Matches callbacks

        let tag = "Profile-" + props.profile.username

        console.log("Flirt loading: ", tag)

        matches.registerNotificationsCallBack ( (r) => {
            setHiarchyNotifications( prev => {
                let newNot = {...r}
                return newNot
            })
        }, tag)

        /*getAll()
            .then(response => {
                setNotifications(response)
            })  */
        
        matches.apiAddNotifications(props.profile.username)
        //matches.apiGetNotificationsList()        
        
        return () => {
            matches.unRegisterNotificationsCallBack(tag)
        }

        

    },[])

    useEffect ( () => {

        let tag = "Profile-" + props.profile.username
        console.log(tag, "HiarchyNotifications changed.", hiarchyNotifications)
        if(hiarchyNotifications[props.profile.username]) {
            markSeen(props.profile.username)    
        }

    },[hiarchyNotifications])


    /**
     * RENDER
     */
    if (!hiarchyNotifications[props.profile.username]) {
        return <div>Loading...</div>
    } else {
        //console.log(hiarchyNotifications)
        //if (!! hiarchyNotifications && hiarchyNotifications.hasOwnProperty(props.profile.username)) {
        //    console.log(hiarchyNotifications[props.profile.username])
        //}
    return <>
            {showPostMediaModal && (modalMediaId || modalPostId) &&
            <FullModal onClick={() => {setShowPostMediaModal(false);}}>
                <PostMediaWithFind 
                    postId={modalPostId}
                    mediaId={modalMediaId} />
                <HideButton onClick={() => setShowPostMediaModal(false)} />
            </FullModal>}
            <ScrollCard ref={scrollCardRef}> 
                {notificationsSortedByMatch(props.profile.username).map( e => {
                    if (ChatTypes.includes(e[0].type)) {
                        
                        return <MessageLine 
                            key={"MESSAGE" + e[0].id}
                            events={e}
                            user={matches.getUser()}
                            matchUser={findUser(props.profile.username)} />
                    } else {
                        return <NotificationLine
                            key={e[0].referenceId + e.length}
                            onMediaClick={(id) => {setShowPostMediaModal(true); setModalMediaId(id);}}                              
                            showThumb 
                            referenceId={props.profile.username} 
                            events={e} 
                            user={matches.getUser()}
                            matchUser={findUser(props.profile.username)}>
                                {e.type}                                
                        </NotificationLine>}
                })}
            </ScrollCard>
            <FlirtChat 
                myMatches={props.myMatches}
                profile={props.profile}/>
        </>
    }

}

export default Flirt;

Flirt.propTypes = {
    matches: PropTypes.object.isRequired

}