import loveMireApi from "./loveMireApi"

export const getMediaInfo = async (mediaList) => {

    return loveMireApi.getJson('postMedia', mediaList)
}

/**
 * Returns media for a single media Id
 * 
 * This makes two calls, first to media
 * @param {*} mediaId 
 */
export const getPicByMediaId = async (mediaId) => {

    if (!isNaN(mediaId)) {
        
        let media = await getMediaInfo(mediaId)
        //console.log(media)
        if (!!media[0] && !!media[0]['dataId']) {
            return loveMireApi.getPic('mediaData', media[0]['dataId'])
        }
    } 


}

export const getChatMediaById = async (mediaId) => {
    if (!isNaN(mediaId)) {
        return loveMireApi.getPic('chatImage', mediaId)
    }
}

export const getPicByDataId = async (dataId) => {

    if (!!dataId) {
        return loveMireApi.getPic('mediaData', dataId)
    }
}