import React from 'react'
import { SettingsFlex } from '../MySettings'
import { StyledFormFlexContainer } from '../../styles/profile/Wrappers';
import { ReloadButton } from 'lovemire-components'
import { DataContext, MatchesContext } from '../../App';
import loveMireApi from '../../lib/loveMireApi';

export default (props) => {

    const [account,appSettings] = React.useContext(DataContext)
    const matches = React.useContext(MatchesContext)

    const freeReload = () => {

        loveMireApi.getJson( 'freeReload' )
            .then ( r => {
                // write new account to matches
                console.log(r)
                matches.doAccountCallBacks(r)
            })
            .catch ( e => {
                // error TODO when beta public
                // error will usually be already used for the day
                // this will be a good oppurtunity to create a better
                // error modal that reads message from server
            })
    }

    return <StyledFormFlexContainer>

            <p>
                Tokens: <span>{account?.tokens ? parseInt(account.tokens) : 'loading...' }</span>
                (<span>{account?.earnedTokens ? parseInt(account.earnedTokens) : '...'}</span>)
            </p>
            <h3>Staging Beta Free Reload:</h3>
            <h4>In Public Beta, tokens will be reloadable once per day.</h4>
            <h4>On Staging Beta, Enjoy!</h4>
            <ReloadButton onClick={() => freeReload() }>Reload</ReloadButton>

    </StyledFormFlexContainer>
}