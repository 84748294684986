import { props } from 'bluebird';
import React from 'react'
import styled from 'styled-components'

/**
 * Covers entire parent with "Saving..." message
 */
export const Cover = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(255,255,255,0.7);
    color: black;
    &:after {content: "Saving...";}
    justify-content: center;
`;

const Cs = (props) => {
    return (
        <div style={{position: 'relative'}}>
            {props.show && <Cover />}
            {props.children}
        </div>
    )
}

export default Cs
