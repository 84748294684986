import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import NotificationTypes from '../../../MyMatches/data/NotificationTypes'
import {Bubble, ScrollCard} from '../../../styles/Cards'

import { MatchesContext, StateContext } from '../../../App';

const Model = styled.div`
  position: absolute;
  display: block;
  top: 50px;
  left: 10%;
  width: 80%;
  height: 100%;

  p {
      margin: 0;            
  }
  `;
const Mn = (props) => {

    const matches = React.useContext(MatchesContext)

    const {profiles} = React.useContext(StateContext)

    const [numAnonymousLikes, setNumAnonymousLikes] = useState(0);
    const [loading, setLoading] = useState(true)

    const [hiarachyNotifications, setHiarchyNotifications] = useState({})
    const [quickList, setQuickList] = useState([])
    const [media, setMedia] = useState({})

    useEffect ( () => {


        //let l = props.likes.reduce ( (a,b) => b.likeType.name==="free" ? a+1 : a , 0)
        //setNumAnonymousLikes(l)

        let tag = props.media.mediaId.toString()
        matches.registerNotificationsCallBack ( (r) => {
            setHiarchyNotifications( prev => {
                let newNot = {...r}
                return newNot
            })
        }, tag)

        //matches.apiAddReferenceNotifications(props.media.mediaId)


        //props.matches.setProfileListFromLikeList (props.likes)
        //props.matches.apiGetProfiles()
        console.log("Media Notifications: ", props)

        return () => {
            matches.unRegisterNotificationsCallBack(tag)
        }
    }, [])

    // media id probably changed
    useEffect( () => {

        // blows up
        // Having the state media is necessary because of the way postmedia
        // handles the active media. Changing postmedia to have a map would
        // eliminate this need
        console.log("Received new props: ", props)
        if (props.media && (!media.mediaId || props.media.mediaId != media.mediaId)) {
            setMedia(props.media)
            setLoading(true)
            matches.apiAddReferenceNotifications(props.media.mediaId)
        }

    },[props])

    useEffect ( () => {
        if (profiles.length > 0) {
            setLoading(false)
        }
    }, [profiles])

    useEffect( () => {

        console.log("Received new Notifications: ", hiarachyNotifications)
        // updatesAnonymous account and rendered list
        let anonymousCount = props.media.likeCount
        let renderedList = []
        for ( const user in hiarachyNotifications) {

            let refId = hiarachyNotifications[user][props.media.mediaId] || []
                for (let i = 0; i < refId.length; i++) {
                    anonymousCount--
                    renderedList.push("like: " + refId[i].fromId)
                }
        }

        // shouldn't ever be less than zero right? lol
        setNumAnonymousLikes(anonymousCount >= 0 ? anonymousCount : 0)
        setQuickList(renderedList)
        setLoading(false)
        
    },[hiarachyNotifications])

    const getProfileByName = (name) => {
        return profiles.filter ( p => p.name === name)[0]
    }

    return  <><Model>
            {loading && <Bubble right>
                    <div>
                        <p>No Information!</p>
                    </div>
                </Bubble>}
            {!loading && <ScrollCard>
                {numAnonymousLikes > 0 && <Bubble right>
                        <div>
                            <p>{numAnonymousLikes + ' anonymous likes.'}</p>
                        </div>
                    </Bubble>}
                {numAnonymousLikes === 0  && <Bubble right>
                    <div>
                        <p>No Information!</p>
                    </div>
                </Bubble>}
                {quickList.length > 0 &&  quickList.map ( ( l,i ) => 
                        <Bubble key={i} right>
                            <div>
                                {/*<p style={{fontSize: '0.6em'}}>{getProfileByName(l.name).title + ' ' + NotificationTypes.getByName(l.likeType.name).toUserString}</p>*/}
                                <p>{l}</p>
                            </div>
                        </Bubble>
                )}
            </ScrollCard>}
            </Model>
            {props.children}
            </>

}

export default Mn;