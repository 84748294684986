import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import { toHowOld } from '../lib/TextLib';

/**
 * Accepts an object with {now : Date() / date string / date array}
 * 
 * Converts datetime to how long since string, wraps in span and sets
 * interval to keep updated.
 * 
 * @param {time object or string} param0 
 */
const HowOldLive = ({now}) => {

    const [sinceString, setSinceString] = useState("")


    useEffect ( () => {

        setSinceString(toHowOld(now))
        
        const interval = setInterval( () => {
            setSinceString(toHowOld(now))
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    return <span>{sinceString}</span>

}


export default HowOldLive