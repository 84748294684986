import React,{useState,useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PortfolioChatApi from '../lib/PortfolioChatApi';

const userColor = '134,136,139';

const TextAreaWrapper = styled.div`
    display: flex;
    flex-grow: 2;
    width: ${props => props.wide ? "90%" : "100%"};
    background-color: rgba(${userColor}, 0.15);
    border-radius: 6px;
    font-family:  'Segoe UI', 'Roboto',serif;
    padding: 2px;

    div {
        flex-grow: 1;
    }
`

const MessageInput = styled.textarea`
    font-size: 16px;
    font-family:  'Segoe UI', 'Roboto',serif;
    flex-grow: 3;
    display: inline-block;
    width: 100%;
    height: 23px;
    max-width: 100%;
    resize: none;
    line-height: 1.1;
    overflow-y: scroll;
    border: 0;
    background-color: transparent;
    position: relative;
    bottom: 0px;
    left: 0;

    &:focus {
        outline: 0;
    }

    &::-webkit-scrollbar {
        width: 8px;
       }

    /* Track */
    &::-webkit-scrollbar-track {
    background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50%;
    height: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
    background: #555;
    }
    
`;

const MessageButton = styled.button`
    

    ${props => props.wide ? 
    `display: inline-block;
    flex-shrink: 2;
    height: 100%;
    margin: auto;
    padding: 12px 6px;
    background-color: #FBFAF5 ;
    border: 0;
    cursor: pointer;`
    : 
    `font-size: 0.7em;
    border: 0;
    background-color: Transparent;
    display: inline-block;
    position: absolute;
    bottom: 3px;
    right: 1px;`}
`;

const ChatArrowImg = styled.img`
    height: 100%;
`
//style={{position: props.wide ? "flex" : "relative", marginTop: "2px"}
const Wrapper = styled.div`
    ${props => props.wide ? `
    justify-content: center;
    align-items: stretch;
    flex-flow: row nowrap;
    display: flex;
    width: 100%;
    height: ${props => props.height ? props.height : "90px"}
    `
    :
    `position: relative;`}
    margin-top: 2px;
    margin-bottom: 5px;
    height: ${props => props.height ? props.height : "23px"};
`
//added to api url + thread number
const PATHEXT = "newTextMessage/";
const MAXINPUTHEIGHT = 50;





const NewMessageForm = (props) => {
    
    const [messageContent, setMessageContent] = useState("");
    const [chatHeight, setChatHeight] = useState("30px")
    const messageInput = useRef();

    // Handles text area growth
    const handleKeyDown = (e) => {

        // if key pressed is Enter without shift, do a submit
        if ( !e.shiftKey && e.key === 'Enter' ) {
            handleSubmit(e);
        } else if (e.key === 'Enter') { // else handle scroll height
            let scrollDiff = e.target.offsetHeight - e.target.scrollHeight;
            if (scrollDiff < 0 || scrollDiff > 4 ) scrollDiff = 4;
            let newHeight = (e.shiftKey && e.key === 'Enter') ? 23 : 0;
            newHeight += e.target.scrollHeight + scrollDiff; // scroll height always 2 less
            if (newHeight < 95) {
                e.target.style.height = `${newHeight-4}px`;
                setChatHeight( (props.chatHeight > newHeight ? props.chatHeight : newHeight) + "px")
            }
            console.log(e.target.style.height, chatHeight, (props.chatHeight > newHeight ? props.chatHeight : newHeight) + "px",
            newHeight, props.chatHeight, e.target);
        }

    }

    const onChange =  (e) => {

        setMessageContent(e.target.value);
        if (e.target.value === "" && e.target.style.height !== `23px`)
            e.target.style.height = `23px`;
    }

    // Submit new message
    const handleSubmit = async (e) => {

        if (props.confirmPay !== undefined && props.confirmPay) {
            e.preventDefault();

            if ( messageContent !== "") {

                let formData = new FormData();
                formData.append("content", messageContent);
                if (!!props.confirmPay) formData.append("pay", props.confirmPay)
                try {
                    await PortfolioChatApi.postForm(`${PATHEXT + props.threadId}`, formData)
                    setMessageContent("");

                    // successful message send, do a call back if exists
                    if (props.onSend) {
                        props.onSend(true)
                    }
                    
                    // reset text area and focus
                    if ( messageInput && messageInput.current ) {
                        messageInput.current.style.height = `23px`;
                        messageInput.current.focus()
                    }
                } catch (err) {
                    console.log(err, Object.entries(err));
                }
            }
        } else {
            console.log("Confirmpay is false", props)

            // unsuccessful message send, do a call back if exists
            if (props.onSend) {
                props.onSend(false)
            }
        }
    }

    useEffect ( () => {
    },[chatHeight])

    useEffect( () => {

        if (!!props.chatHeight) {
            setChatHeight(props.chatHeight + "px")
        }

    },[props])

    return (
        <Wrapper wide={props.wide} height={chatHeight}>
        <TextAreaWrapper style={{flexGrow: "2", width: props.wide ? "90%" : "100%"}}
            onClick={ () => messageInput.current.focus()}>
            <MessageInput 
                wide={props.wide}
                value={messageContent} 
                onChange={onChange} onKeyDown={handleKeyDown}
                ref={messageInput} />
            <div>
                <img src="/images/image-attach.png" style={{width: "15px", height: "auto", marginRight: "20px"}} />
            </div>
        </TextAreaWrapper>
        <MessageButton wide={props.wide} onClick={handleSubmit}>
            {props.wide && <ChatArrowImg src="/images/chat-arrow.png" />}
            {!props.wide && ">"}
        </MessageButton>
        </Wrapper>

    );

    /*
    return (
        <form>
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-11">
                        <MessageInput value={messageContent} onChange={onChange} onKeyDown={handleKeyDown} />
                        
                    </div>
                    <div className="col-1">
                        <MessageButton onClick={handleSubmit}>></MessageButton>
                    </div>
                </div>
            </div>
        </form>
    );*/
}

NewMessageForm.propTypes = {
    threadId: PropTypes.number.isRequired

}

export default NewMessageForm;