import React from 'react'
import styled from 'styled-components'
import { StyledFormFlexContainer } from '../../../styles/profile/Wrappers'
import LocationSearch from '../../../feed/components/LocationSearch';
import { TextArea } from 'lovemire-components';

import { PostContext, PostUpdateContext } from '../EditPost';

export default (props) => {

    const post = React.useContext(PostContext)
    const postUpdate = React.useContext(PostUpdateContext)

    return <StyledFormFlexContainer>
        <StyledFormFlexContainer.Row>
            <li>
                <label>Where were you in this post? </label>
            </li>

            </StyledFormFlexContainer.Row>
            <StyledFormFlexContainer.Row>
            <LocationSearch 
                postRequest={{addressString: post.addressString}}
                onChange={(as, latlng) => {
                    let newPost = {}
                    newPost['addressString'] = as;
                    newPost['coords'] = latlng
                    postUpdate.updatePost(newPost)
                }} />
        </StyledFormFlexContainer.Row>
        <StyledFormFlexContainer.Row>
            <li>
            <label>What's on your mind? </label>
            </li>
        </StyledFormFlexContainer.Row>
        <StyledFormFlexContainer.Row>
            <TextArea onChange={(e) => postUpdate.updatePost({description: e})}>
                {post.description}
            </TextArea>
        </StyledFormFlexContainer.Row>
        <StyledFormFlexContainer.Row>
            {props.children}
        </StyledFormFlexContainer.Row>
    </StyledFormFlexContainer>
}