import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components'
import Settings from '../images/settings.png'

const StyledAccountBar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    align-self: center;
    box-sizing: border-box;
    padding-left: 2px;
    padding-right: 2px;
    cursor: pointer;

    p {
        font-size: 1em;
        color: #707070;
        padding-left: 5px;
        background-color: white;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        text-align: left;
        
        @media (max-width: 450px) {
            font-size:0.7em;
        }
    }

    span {
        display: block;
        font-size: 1.5em;
        margin: auto;
        margin-left: auto;
        color: #707070;

        @media (max-width: 450px) {
            font-size: 1em;
            margin: 0 0 5% 0;
        }
    }

    img {
        display: block;
        height: 20px;
        width: auto;
        margin-right: 2px;
    }
`;

const GoldDiv = styled.div`
    height: 10px;
    width: 14px;
    margin: 0;
    border-radius: 50%;
    display: inline-block;
    border: 0.5px solid #707070;
    background-color: ${props => props.theme?.colors?.gold || 'gold'};

    @media (max-width: 450px) {
        height: 6px; width: 10px;
    }
`

const Ab = (props) => {

    return (
        <StyledAccountBar onMouseEnter={ () => console.log(props.name) } name={props.name}>
                <div style={{display: 'flex',
                    flex: '1 0 75%', alignItems: 'center', justifyContent: 'center'}}>
                    <GoldDiv />
                    <p>{`${props.tokens ? parseInt(props.tokens): 'Loading'}`}</p>
                </div>
                <img src={Settings} />
        </StyledAccountBar>
    )
}

Ab.defaultProps = {
    __TYPE: 'AccountBar'
}

export default Ab;

Ab.propTypes = {
    __TYPE: PropTypes.string,
    tokens: PropTypes.number,
    name: PropTypes.string
}