import React, { createContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import loveMireApi from './lib/loveMireApi';

const ModalOuter = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    align-items: center;
    justify-content: center;
    z-index: 5;
    margin: auto;
    background: rgb(255,255,255,.8);
    text-overflow: "-";
    z-index: 99;
`;

const Modal = styled.div`
    position: fixed;
    display: block;
    z-index: 6;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 80%;
    max-height: 80%;
    background: white;
    border-radius: 5%;
    border: 1px solid black;
    overflow: auto;
`;

const StyledP = styled.p`
    display: block;
    background: white;
    `;

const ButtonWrapper = styled.div`
    display = flex;
    `;

const StyledButton = styled.button`
    margin: 10px 10px;
    background: #FFFFFC;
    border-radius: 10px;
    padding: 25px;
    `;

const Em = (props) => {

    const [sendButtonMessage, setSendButtonMessage] = useState("Send to Devs!")
    const [countDown, setCountDown] = useState(5)
    const [clearButtonMessage, setClearButtonMessage] = useState("Nah, Clear")
    const [buttonIsDisabled, setButtonDisabled] = useState(false)
    const [note, setNote] = useState("")

    let countDownInterval

    const sendReport = () => {

        /*let payload = props.error.map( (e) => {
            JSON.stringify(e)
        })*/

        let payload = {}
        payload['message'] = []

        Object.keys(props.error).forEach ( (e,i) => {
            if (!!props.error[e]) {
                payload['message'].push(`${e}: ${props.error[e].toString()}`);
            }
        })

        console.log(props.error)
        console.log(payload)

        payload['note'] = note === "" ? "Frontend Generated": note;

        setButtonDisabled(true)

        loveMireApi.postJson('errorReport', payload)
            .then ( response => {
                setSendButtonMessage("Success!")
            })
            .catch( e => setSendButtonMessage("Error"));

        setClearButtonMessage("Close (5)")

        countDownInterval = setInterval ( () => {
            setCountDown(c => c-1)
        },1000)

    }

    useEffect ( () => {
        if (countDown < 1) {
            clearInterval(countDownInterval)
            props.clearError()
        } else if (countDown < 5) {
            setClearButtonMessage(`Close (${countDown})`)
        }
    },[countDown])

    useEffect( () => {
        console.log(props)
    }, [])

    const isNetworkError = () => Object.keys(props.error).includes('isAxiosError')

    console.log(props)
    return (
        <ModalOuter onClick={ () => {console.log("click"); props.clearError()} }>
            <Modal onClick={(e) => {e.stopPropagation();} }>
                <h1>Beta Error Report</h1>
                { /* If network error, let user know */
                Object.keys(props.error).includes('isAxiosError') && <><h2>Network Error</h2>
                <p><b>Network Error: </b>Error Report may also fail.</p>
                <p>For beta testing: please inform Nathan (if asked).</p></>}
                {Object.keys(props.error).map ( (e,i) => {
                    if ( !!props.error[e] ) {
                        console.log(e, ":", props.error[e], Object.keys(props.error[e]) );
                    }
                    return (
                        <StyledP key={i}>
                            <b>{e.toString()}</b>
                             {!!props.error[e] ? (Object.keys(props.error[e]).length !== 0 ? JSON.stringify(props.error[e]) : `${props.error[e].toString()}` ): 'undefined'}
                        </StyledP>);
                })}
                {Object.keys(props.error).length === 0 && <StyledP>
                    {props.error.stack}
                    </StyledP>}
                <p>Note:</p>
                <p>(What were you doing?)</p>
                <textarea style={{width: "80%", height: "50px", borderRadius: "5px"}}type="text" onChange={ (e) => setNote(e.target.value)} />
            <ButtonWrapper>
                <StyledButton disabled={buttonIsDisabled} onClick={ () => sendReport() }>{sendButtonMessage}</StyledButton>
                <StyledButton onClick={ () => props.clearError() }> {clearButtonMessage} </StyledButton>
            </ButtonWrapper>
            </Modal>
        </ModalOuter>
    )

}

export default Em;