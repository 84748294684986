
/**
 * Converts a date to a how old string.
 * @param {Date} date 
 */
export const toHowOld = ( dateParam ) => {

  
  let date = new Date();
  if (Array.isArray(dateParam) ) { // most likely from Spring created
    dateParam = dateParam.map( e => e.toString().padStart(2,"0"))
    if (dateParam.length > 3) {
      date = new Date(dateParam.slice(0,3).join("-") + "T" + dateParam.slice(3,6).join(":") + "+00:00")
    } else {
      date = new Date( dateParam.join("-") );
    }
  } else if (dateParam instanceof Date) {
    date = new Date(dateParam.toString() + '+00:00')
  } else if (!! dateParam ) {
    if (dateParam.endsWith('+00:00')) {
      date = new Date(dateParam)
    } else {
      date = new Date( dateParam+'+00:00')    // last try
    }
  }

  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today - DAY_IN_MS);
  const seconds = Math.round((today - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();

  const MONTH_NAMES = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  let minuteString = date.getMinutes()
  
  if (minuteString < 10) {
      // Adding leading zero to minutes
      minuteString = `0${ minuteString }`;
  }

  //console.log(dateParam, seconds)
  //return dateParam.join("-")
  //return Date.parse("2021-08-26T23:1:23Z")
  //return Date.parse(dateParam.slice(0,3).join("-") + "T" + dateParam.slice(3,6).join(":") + "+00:00")
  //return date.toDateString()
  
  if (seconds < 5 && seconds >= 0) {
      return `Just Now`;
  } else if (seconds < 60 && seconds >= 5) {
      return `${ seconds } seconds ago`;
  } else if (seconds < 90 && seconds >= 60) {
      return 'about a minute ago';
  } else if (minutes < 60 && minutes >= 0) {
      return `${ minutes } minutes ago`;
  } else if (isToday) {
      return `Today at ${ hours }:${ minuteString }`; // Today at 10:20
  } else if (isYesterday) {
    return `Yesterday at ${ hours }:${ minuteString }`;
  } else {
    return `${ month } ${ day }, ${ year }`; //at ${ hours }:${ minuteString }`;
  }
  
}


/*
const MONTH_NAMES = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];


function getFormattedDate(date, prefomattedDate = false, hideYear = false) {
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  let minutes = date.getMinutes();

  if (minutes < 10) {
    // Adding leading zero to minutes
    minutes = `0${ minutes }`;
  }

  if (prefomattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    return `${ prefomattedDate } at ${ hours }:${ minutes }`;
  }

  if (hideYear) {
    // 10. January at 10:20
    return `${ day }. ${ month } at ${ hours }:${ minutes }`;
  }

  // 10. January 2017. at 10:20
  return `${ day }. ${ month } ${ year }. at ${ hours }:${ minutes }`;
}


// --- Main function
function timeAgo(dateParam) {
  if (!dateParam) {
    return null;
  }

  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today - DAY_IN_MS);
  const seconds = Math.round((today - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isThisYear = today.getFullYear() === date.getFullYear();


  if (seconds < 5) {
    return 'now';
  } else if (seconds < 60) {
    return `${ seconds } seconds ago`;
  } else if (seconds < 90) {
    return 'about a minute ago';
  } else if (minutes < 60) {
    return `${ minutes } minutes ago`;
  } else if (isToday) {
    return getFormattedDate(date, 'Today'); // Today at 10:20
  } else if (isYesterday) {
    return getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
  } else if (isThisYear) {
    return getFormattedDate(date, false, true); // 10. January at 10:20
  }

  return getFormattedDate(date); // 10. January 2017. at 10:20
}
*/