import loveMireApi from "./loveMireApi";

export const getSettings = () => {

    return loveMireApi.getJson('settings')
}

export const saveSettings = (newSettings)  => {

    return loveMireApi.postJson('settings', newSettings)
}