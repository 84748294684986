import React, {useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types';

const Outer = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(255,255,255,0.3);
`;

const Inner = styled.div`
  display: block;
  position: absolute;
  bottom: ${props => !!props.top ? props.top : `25%`};
  right: ${props => !!props.left ? props.left : `5%`};
  max-width: 80%;
  ${/*bottom: ${props => !!props.bottom ? props.bottom : `0`};
  left: ${props => !!props.left ? props.left : `25%`};
  right: ${props => !!props.right ? props.right : `0`};
  width: ${props => !!props.width ? props.width : `250px`};
  height: ${props => !!props.height ? props.height : `250px`}; */""}
  z-index: 10;
  padding: 5px 5px 5px 5px;
  border-radius: 25px 25px 0 25px;
  border: solid 2px #CAB5B5;
  background: rgba(255,255,255,0.88);
    `;

export const H2 = styled.h2`
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  color: black;
  margin: 0.4em auto 0 auto;
  font-size: 1.1em;
  text-shadow: 1px 1px white;
  `;

export const P = styled.p`
font-family: 'Roboto', serif;
font-size: 0.6em;
color: ${props => props.color ? props.color : '#707070'};
margin: 0.4em 0.8em;
text-align: ${props => props.centered ? 'center' : 'left'};
  `;

export const SPAN = styled.span`
  color: ${props => props.color ? props.color : 'darkorange'};
  font-weight: bolder;
`;

const MediaModal = (props) => {

  /**
   * Hides this modal if the user clicks outside it. Since this state
   * is only saved here, it will be shown again until user clicks 
   * something that will save state
   */
  const [isHidden, setHidden] = useState(false)

  if (isHidden) return (null);
  else return (<Outer onClick={ () => setHidden(true)}>
    <Inner onClick={e => e.stopPropagation()}>
      {props.children}
    </Inner>
  </Outer>)
}

MediaModal.Inner = Inner;
MediaModal.H2 = H2;
MediaModal.P = P;
MediaModal.SPAN = SPAN


export default MediaModal;
