import styled from 'styled-components'

export const StyledListP = styled.p`
  color: black;
  font-family: 'Roboto', sans-serif;
  display: inline-block;
`;

export const StyledFineP = styled.p`
  color: black;
  font-family: 'Roboto', sans-serif;
  display: inline-block;
  font-size: 0.6em;
`;

export const StyledBoldP = styled.p`
  color: black;
  font-family: 'Roboto', sans-serif;
  display: inline-block;
  font-weight: bolder;
  font-size: 1em;
`;
