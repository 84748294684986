import styled from 'styled-components'

const DraftOverLay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

`;

const DraftText = styled.div`
font-size: 2em;
color: white;
font-family: 'Roboto', serif;
-webkit-text-fill-color: transparent;
-webkit-text-stroke-width: 1px;
-webkit-text-stroke-color: white;
transform: rotate(-30deg);
`;

export default (props) => {

    return (
        <DraftOverLay>
            <DraftText>DRAFT</DraftText>
        </DraftOverLay>
        )
}