import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { useDrag} from '@use-gesture/react'

const LikeImage = styled.img.attrs(
    ({grow, selected}) => {
        if (grow !== undefined) {
            return { style: {
                filter: `drop-shadow(10px 5px 3px white) brightness(${grow*60 + 40}%)`
            }}
        } else if (selected !==undefined) {
            return { style: {
                filter: `drop-shadow(10px 5px 3px black) brightness(${selected * 12 + 40}%)`
            }}
        }
    }
)`
    user-drag: none;
    
    width: 100%;
    height: 100%;
    margin: auto;
    max-width: 200px !important;
    max-height: 200px !important;
    
`;



/**
 * Props passes: current, available
 * 
 * current and available are arrays which choices based on what's picked and what can be picked.
 * When no likes are already picked, current is empty and available has all choices.
 * 
 * props.onSelect(removed, added), removed has objects from current that were deselected, added is what
 * was added for available. There will never be objects in both
 * 
 * Will need to add endpoint in LikesController that accepts post and delete at /likes enpoint
 * that receives a body (delete may just have to accept arrays of likeTypes, maybe same with post)
 * 
 * needs disabled prop
 * 
 * @param {Object} props 
 * @returns 
 */
const TG = (props) => {

    const [grow, setGrow] = useState(1)
    const [divGrow, setDivGrow] = useState(1)
    const [initialY, setInitialY] = useState(1)
    const [selectedCount, setSelectedCount] = useState(0)
    const [totalPicks, setTotalPicks] = useState(null)
    const [pick, setPick] = useState(0)
    const [allPicks, setAllPicks] = useState([])
    const [isSending, setSending] = useState(false)

    const bind = useDrag(({xy: [x,y],first,last, tap}) => {
        let newY = y 
        
        if (tap) {
            console.log('click-tap')
            // Always increases
            if(props.available.length > 0) {
                setSending(true)
                props.onSelected([],props.available.slice(0,1))
            }
        }
        else if (first) {
            setInitialY(Math.abs(y))
            
        }
        else if(last) {
            if (y === initialY) {
                console.log("click")
                /*
                Problem here on mobile devices. The click doesn't work, it drags too easily?
                Will need a way to log the y on mobile to debug
                */

                // Always increases
                if(props.available.length > 0) {
                    setSending(true)
                    props.onSelected([],props.available.slice(0,1))
                }
            }
            else {
                // released
                let pick = Math.floor((grow-1)*(totalPicks-1))
                console.log("Grow:", grow, "Pick: ", pick)
                setPick(pick)
                setGrow(1)
                setDivGrow(1)

                // Fire selection to parent
                if (pick > props.current.length -1 ) {
                    // only new
                    let newLikes = props.available.slice(0,pick+1-props.current.length)
                    if (newLikes.length > 0) {
                        setSending(true);
                        props.onSelected([], newLikes)  
                    }
                } else {
                    // deleting some (or same)
                    let newRemovals = props.current.slice(pick+1)
                    if (newRemovals.length > 0) {
                        setSending(true)
                        props.onSelected(newRemovals,[])
                    }
                }

            }
        }
        else {             
            y = Math.abs(y) - initialY
            newY = Math.abs(y) < 200 ? Math.abs((y % 200)/200)+1 : 2  
            let pick = Math.floor((grow-1)*(totalPicks-1))      
            setPick(pick)               
            setGrow(newY);
            setDivGrow(Math.abs(Math.abs(y/100)+1))
         } 
         
        //console.log(first,last,newY,y,divGrow)
        }, {  }
    )

    /**
     * Checks props for new array. Updates selected count for image brightness
     */
    useEffect ( () => {
        //console.log(props)

        setSending(false)

        if (!!props.current) {
            setSelectedCount(props.current.length)
            setTotalPicks(props.current.length + props.available.length)
            setAllPicks(props.current.concat(props.available))
        } else {
            setSelectedCount(0)
            setTotalPicks(5)
        }
    }, [props])

    return (
        <>
        {!isSending && <div style={
            {position: `absolute`,
             transform: `scale(${grow},${grow}) translate(-${grow*15}px,-${grow*15}px)`,
             bottom: '1%', left: '65%',
             background: 'none', 
             width: '100px', touchAction: 'none'}} >
            {grow > 1 && <>
                <LikeImage grow={grow-1} src={props.img ? props.img : "/images/heart.png"} />
                <p style={{position: 'absolute', top: '0', left: '0', marginTop: "-10px"}}>
                    {allPicks[pick].text ? allPicks[pick].text : allPicks[pick].title}
                    <span> {allPicks[pick].cost ? allPicks[pick].cost : ""}</span>
                </p>
                </>
            }
            {grow === 1 && <LikeImage selected={selectedCount} src="/images/heart.png" />}
            <div {...bind()} style={
                {position: `absolute`, 
                 bottom: `0`, 
                 right: `0`,  
                 width: `${divGrow > 1 ? "1500px": "100px"}`, 
                 height: `${divGrow > 1 ? "1500px": "100px"}`, 
                 margin: "auto",
                 touchAction: 'none'}}></div>
        </div>}
        </>
        )
}

export default TG