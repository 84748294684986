import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import HowOldLive from '../components/HowOldLive';
import loveMireApi from '../lib/loveMireApi';
import NewUserWizard from '../wizards/NewUserWizard'
import { SelectButton } from 'lovemire-components';


export const MainWrapper = styled.div`
  margin-top: 150px;

  h1 {
      color: #86888B;
  }
  span {
      color: #CAB5B5;
  }

  h2 {

    color: #86888B;
    margin-bottom: 2px;
    span{
        text-decoration: line-through;
        color: #86888B; 
        transform: rotate(-90deg);
    }

    b {
        color: #CAB5B5;
        font-family: 'Merriweather', serif;
        font-weight: bold;
    }
  }

  p {
      color: #86888B;
      margin: 0;
  }

  `;

export default (props) => {

    const [profile, setProfile] = useState({})

    useEffect( () => {

        loveMireApi.getJson('myProfile')
        .then ( response => {
            setProfile(response)
        })
        .catch( e => console.log(e.response))

    }, [])

    console.log(profile)
    return <MainWrapper>
        {! profile?.verified && <NewUserWizard profile={profile} />}
        {profile?.verified && <><h1>Welcome to Flirvy!</h1>
            <h1><span>[Project LoveMire]</span>!</h1>
            <h2>Online <span>Dating</span> <b>Flirting</b> Site!</h2>
            <p>Development began: <HowOldLive now={new Date(2020,11,2,8,0,0)} /></p></>}
            
        </MainWrapper>
}