import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { useDrag} from '@use-gesture/react'

/**
 * Uses selectedCount to specify starting position for sliding div
 */
const DivSet = {
    0: "-95%",
    1: "-85%",
    2: "-65%",
    3: "-50%",
    4: "-35%",
    5: "0"
}

const LikeImage = styled.img.attrs(
    ({grow, selected}) => {
        if (grow !== undefined) {
            return { style: {
                filter: `drop-shadow(10px 5px 3px white) brightness(${grow*60 + 40}%)`
            }}
        } else if (selected !==undefined) {
            return { style: {
                filter: `drop-shadow(10px 5px 3px black) brightness(${selected * 12 + 40}%)`
            }}
        }
    }
)`
    user-drag: none;
    
    width: 100%;
    height: 100%;
    margin: auto;
    max-width: 200px !important;
    max-height: 200px !important;
    
`;



/**
 * Props passes: current, available
 * 
 * current and available are arrays which choices based on what's picked and what can be picked.
 * When no likes are already picked, current is empty and available has all choices.
 * 
 * props.onSelect(removed, added), removed has objects from current that were deselected, added is what
 * was added for available. There will never be objects in both
 * 
 * Will need to add endpoint in LikesController that accepts post and delete at /likes enpoint
 * that receives a body (delete may just have to accept arrays of likeTypes, maybe same with post)
 * 
 * needs disabled prop
 * 
 * @param {Object} props 
 * @returns 
 */
const TG = (props) => {

    const [grow, setGrow] = useState(1)
    const [divGrow, setDivGrow] = useState(0)
    const [initialX, setInitialX] = useState(1)
    const [selectedCount, setSelectedCount] = useState(0)
    const [totalPicks, setTotalPicks] = useState(null)
    const [pick, setPick] = useState(0)
    const [allPicks, setAllPicks] = useState([])
    const [isSending, setSending] = useState(false)

    /**
     * Uses props.dimensions and selectedCount to determine placement of slider from
     * supplied info
     */
    const findInitialGrow = () => {
        let width = props.dimensions ? props.dimensions[0] : 480
        return width - (width/ (totalPicks ? totalPicks : 0)) * (selectedCount ? selectedCount : 0)
    }

    /**
     * Returns [newGrow, newPick]
     * 
     * newGrow is formed by adding initialgrow with x and adjusting for dimensions
     * @param {float} x 
     */
    const findNewPick = (x) => {

        // get width from props (default to 480)
        let width = props.dimensions ? props.dimensions[0] : 480

        // min and max of the grow bounds. This will be a -/+ number
        // that can be added to initial setting of div to find new pos
        let newGrowBounds = [findInitialGrow()-width,findInitialGrow()]

        // get the new grow (x bounded)
        let newGrow = Math.min(newGrowBounds[1],Math.max(x,newGrowBounds[0]))

        // total initial grow + new grow
        let newGrowTotal = (width-findInitialGrow()) + newGrow

        // used to find the new pick
        let interval = width / (totalPicks ? totalPicks : 1)

        return [newGrow, Math.floor( (newGrowTotal-1)/interval)]

    }

    /**
     * User tapped the like button
     */
    const tapLike = () => {

        // if there is availalbe like, send next like to selected
        if (props.available.length > 0) {
            setSending(true)
            props.onSelected([], props.available.slice(0,1))
        }
    }

    const bind = useDrag(({xy: [x,y],first,last, tap, event}) => {
        console.log(event)
        let newX = x 
        
        if (tap) {
            console.log('click-tap')
            // Always increases
            // TODO: Would like to revisit this someday
            // The problem is the click is being propagated to the onClick handler of the parent div
            // We need that click handler so the empty space to the right of the draggable div will 
            // increase the like by one. It works on PC, but not mobile.
            // For now, PC users will either have to click to the right or drag.
            //setSending(true)
            //tapLike()
        }
        else if (first) {
            setInitialX(x)
            
        }
        else if(last) {
            if (x === initialX) {
                console.log("click")
                /*
                Problem here on mobile devices. The click doesn't work, it drags too easily?
                Will need a way to log the y on mobile to debug
                */

                // Always increases
                if(props.available.length > 0) {
                    setSending(true)
                    props.onSelected([],props.available.slice(0,1))
                }
            }
            else {
                // released
                x = x - initialX;
                console.log("Dragging: ", x, "Grow: ", newX, "DivGrow: ", divGrow,
                 "SelectedCount: ", selectedCount, "Dims: ", props.dimensions, "Pick: ", findNewPick(x)    )
                let pick = findNewPick(x)[1]
                setPick(pick)
                setGrow(0)
                setDivGrow(1)

                // Fire selection to parent
                if (pick > props.current.length -1 ) {
                    // only new
                    let newLikes = props.available.slice(0,pick+1-props.current.length)
                    if (newLikes.length > 0) {
                        setSending(true);
                        props.onSelected([], newLikes)  
                    }
                } else {
                    // deleting some (or same)
                    let newRemovals = props.current.slice(pick)
                    if (newRemovals.length > 0) {
                        setSending(true)
                        props.onSelected(newRemovals,[])
                    }
                }

            }
        }
        else {             
            x = x - initialX
            //newX = Math.abs(x) < 200 ? Math.abs((x % 200)/200)+1 : 2  
            setPick(findNewPick(x)[1])               
            setGrow(Math.floor(findNewPick(x)[0]));
            setDivGrow(findNewPick(x)[0])
            //console.log("Dragging: ", x, "Grow: ", newX, "DivGrow: ", divGrow,
            // "SelectedCount: ", selectedCount, "Dims: ", props.dimensions, "Pick: ", findNewPick(x)    )
         } 
         
        //console.log(first,last,newY,y,divGrow)
        }, {  }
    )

    /**
     * Checks props for new array. Updates selected count for image brightness
     */
    useEffect ( () => {
        //console.log(props)

        setSending(false)

        if (!!props.current) {
            setSelectedCount(props.current.length)
            setTotalPicks(props.current.length + props.available.length)
            setGrow(0)
            
            setAllPicks(props.current.concat(props.available).sort( (a,b) => a.cost - b.cost))
            setPick(props.current.length-1)
        } else {
            setSelectedCount(0)
            setTotalPicks(5)
        }
    }, [props])



    //console.log(allPicks)

    const barHeight = '60px'
    return (
        <div style={{height: barHeight}} onClick={e => {console.log("bar click", e); if(!props.disabled) {tapLike();} } }>

        {/*props.disabled allows secret images to show the bar but not be interactable*/}
        {!isSending && !props.disabled && props.dimensions &&  <div style={
            {position: `relative`,
            left: `-${ findInitialGrow()}px`,
             /*transform: `translate(-${grow*15}px,-${grow*15}px)`,*/
             transform: `translateX(${divGrow-1}px)`,
             background: 'rgba(250, 185, 204, 0.6)', 
             height: barHeight, width: '103%', touchAction: 'none'}} >
            {grow != 0 && allPicks.length > pick && pick > 0 && <>
                <p style={{display: 'block', position: 'absolute', top: '0', right: '0'}}>
                    {allPicks[pick].text ? allPicks[pick].text : allPicks[pick].title}
                    <span> {allPicks[pick].cost ? allPicks[pick].cost : ""}</span>
                    {"db: " + selectedCount + "-" + pick + "-" + grow}
                </p>
                
            </>
        }

        {grow === 1 && <></>/* length of div based on selectedCount, prob not used */}

        
        {!props.disabled && <div {...bind()} style={
            {  
            /*width: `${divGrow > 1 ? "1500px": "100px"}`, 
            height: `${divGrow > 1 ? "1500px": "100px"}`, */
            width: "100%", height: barHeight, display: "inline-block",
            margin: "auto",
            touchAction: 'none', userSelect: 'none'}}>                     
        </div>}
        </div>}

        {props.children}
        
        </div>
        )
}

export default TG