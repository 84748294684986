import React from 'react'
import Modal from '../styled/MediaModal'

export default function one (e) {

    const dems = {

    }
    return <Modal>
        <Modal.H2>Aspect Change!</Modal.H2>
        <Modal.P>Note that posts use the same aspect on each slide.</Modal.P>
        <Modal.P>Your aspect was set based on your first image. Changing now</Modal.P>
        <Modal.P>may require an adjustment on previous images.</Modal.P>

        {e.children}
    </Modal>

}