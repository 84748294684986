import React from 'react'

import { NewUserWizardWrapper } from '../NewUserWizard'

import { SelectButton } from 'lovemire-components'

const Welcome = (props) => {

    return <NewUserWizardWrapper>
    <NewUserWizardWrapper.Col>
        <h2>Welcome to Flirvy!</h2>

        <SelectButton
            noCheckBox
            selected={false}
            onClick={ () => {
                props.startOver()
            }}> Start Over </SelectButton>
        <SelectButton
            
            selected={true}
            onClick={() => {
                props.doFinished()
            }}
        >Ok!</SelectButton>
    </NewUserWizardWrapper.Col>
</NewUserWizardWrapper>

}

export default Welcome