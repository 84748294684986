
const axios = require('axios').default;

const testServer = "staging"

const forceTest = false

const apiUrl = forceTest || process.env.NODE_ENV === "development" ?
    `http://${testServer}:8081` : "https://lovemire.com/api";

const profileUrl = forceTest || process.env.NODE_ENV === "development" ?
    `http://${testServer}:8950/api` : "https://lovemire.com/profileApi/api";

const mediaUrl = forceTest || process.env.NODE_ENV === "development" ?
    `http://${testServer}:8960` : "https://lovemire.com/mediaApi";

const mediaDataUrl = forceTest || process.env.NODE_ENV === "development" ?
    `http://${testServer}:8980` : "https://lovemire.com/mediaDataApi";

const connectionUrl = forceTest || process.env.NODE_ENV === "development" ?
    `http://${testServer}:8931` : "https://lovemire.com/connectionApi";

const notificationsUrl = forceTest || process.env.NODE_ENV === "development" ?
    `http://${testServer}:8083` : "https://lovemire.com/notificationsApi";

const payUrl = forceTest || process.env.NODE_ENV === "development" ?
    `http://${testServer}:8082` : "https://lovemire.com/payApi";

const chatUrl = forceTest || process.env.NODE_ENV === "development" ? 
    `http://${testServer}:9100` : "https://lovemire.com/chatApi";

const homeUrl = forceTest || process.env.NODE_ENV === "development" ? 
    `http://${testServer}:3000` : "https://lovemire.com";

// argument passed to know which url to use
const fullUrl = {

    // Profile
    myProfile: profileUrl + `/myProfile`,
    profiles: profileUrl + `/profile`,

    // Pay 
    account: payUrl + `/MyAccount`,
    gifts: payUrl + `/gifts`,
    receiveGifts: payUrl + `/receiveGift`,
    freeReload: payUrl + `/freeReload`,         // beta endpoint

    // Media
    previewData: mediaUrl + `/previewData`,
    postLike: mediaUrl + `/like`,
    hiddenRequirements: mediaUrl + `/hiddenByDataId`,
    requestHidden: mediaUrl + `/requestHiddenByDataId`,
    postMedia: mediaUrl + `/media`,
    testUpload: mediaUrl + `/testUpload`,

    // Media Data
    mediaData: mediaDataUrl + `/image`,
    hiddenViews: mediaDataUrl + `/hiddenViews`,


    // Posts
    feed: apiUrl + `/postRequest`,
    posts: apiUrl + `/posts`,
    profileThumbs: apiUrl + `/posts`,
    newPost: apiUrl + `/newPost`,
    addPostMedia: apiUrl + `/addMedia`,
    setActivePost: apiUrl + `/setActive`,
    post: apiUrl + `/post`,         // GET / PUT / DELETE
    hide: apiUrl + `/hide`,         // POST / DELETE
    convertCoords: apiUrl + '/convertCoords',

    // Like / Connections
    getConnectionsReduced: connectionUrl + `/like/MyMatchesReduce`,
    mediaLikes: mediaUrl + `/likes`,
    addLike: connectionUrl + `/like`,

    // Notifications
    getAllNotifications: notificationsUrl + `/myNotifications`,
    notifications: notificationsUrl + `/notifications`,
    seenFromUser: notificationsUrl + `/seenNotifications`,
    pushToken: notificationsUrl +  `/push`,
    settings: notificationsUrl + `/settings`,
    myList: notificationsUrl + `/myList`,
    online: notificationsUrl + `/online`,

    // Chat
    getUnseenMessagesPerUser: chatUrl + `/numNewMessagesPerUser`,
    chatDirect: chatUrl + `/direct`,
    chatImage: chatUrl + `/image/22`,
    markSeenByUser: chatUrl + `/seenThreadByUser`,

    // Error Report
    errorReport: mediaDataUrl + `/errorMessage`
    
}

/**
 * Must be used for urls when accessing api.
 * 
 * @param {string} type Must match a key in fullUrl
 * @param {*} pathParam optional, added with '/' to url
 */
const buildUrl = (type, pathParam) => {
    if (type in fullUrl) {
        if (!!pathParam) return fullUrl[type] + '/' + pathParam;
        else return fullUrl[type];
    } else {
        throw new Error('Got url type not in authorized list.')
    }
}

const buildHeaders = () => {

    let bheaders = {}
    let params = new URLSearchParams(window.location.search);

    // token in url always higher priority
    if ( params.has("t")) {
        bheaders['Authorization'] = 'Bearer ' + params.get("t");
    }
    else if (window.localStorage.hasOwnProperty('token')) {
        bheaders['Authorization'] = 'Bearer ' + window.localStorage.getItem("token");
    }

    return bheaders

}

/**
 * Accepts a response object and runs checks that the fetch successed.
 * Returns boolean true / false
 */
const checkResponse =  (response) => {

    if ( response && response.status === 200 )
        return true;
    else return false;
}

/**
 * Maybe this is really easy with Axios
 */
const getPayload = async (response, type) => {

    if ( type === 'json') {

        return response.data;

    } else if ( type === 'blob' ) {
        
        if(response.status===204) {
            throw ({message: "No Content", response: response})
        }
        return response.data;
        
    } else {
        console.log("Error: ", response, type)
        throw new Error("Invalid Parameter: " + type + ` Response: ${response}`) 
    }

}

const loveMireApi = {


    /**
     * Returns the 3 digit code from an axios error.
     * 
     * @param {obj} response 
     */
    checkError: (response) => {
        if (response === undefined) {
            return "Error undefined"
        }
        if (response.status === undefined && !!response.response.status) {
            return response.response.status
        } else if ( !!response.status ) {
            return response.status
        }
        else return "Error status not found"
    },

    setToken: (token) => {
        window.localStorage.setItem('token', token)
    },

    logout: () => {
        window.localStorage.removeItem('token')
        window.history.replaceState({}, document.title, )
    },

    getJson: async (type, pathParam) => {
        try {
            return await loveMireApi.get(type, 'json', pathParam)
        } catch (e) {
            throw e;
        }
    },

    getPic: async (type, pathParam, params) => {
        try {
            return await loveMireApi.get(type, 'blob', pathParam, params)
        } catch (e) {
            throw e;
        }
    },

    get: async (type, responseType, pathParam, params) => {

        if (!type || !responseType) {
            throw new Error("get parameters incomplete.")
        }

        if ( !params ) {
            params = {}
        }

        try {
            let url = buildUrl(type, pathParam)

            let response = await axios.get(url, {headers: buildHeaders(), responseType: responseType, params: params } )

            return await getPayload(response,responseType)

        } catch (e) {
            throw e
        }

    },    

    post: async (type, data, responseType, pathParam, method='post') => {

        if (!type || !responseType) {
            throw new Error("post parameters incomplete")
        }

        let axiosCall = axios.put
        if (method === 'post') {
            axiosCall = axios.post
        } else if (method === 'put') {
            axiosCall = axios.put
        } else {
            throw Error("method not supported")
        }

        try {
            let url = buildUrl(type, pathParam)

            let response = await axiosCall(url, data, {headers: buildHeaders(), responseType: responseType} )

            return await getPayload(response, responseType)

        } catch (e) {
            console.log(e)
            throw e
        }
    },

    postJson: async (type, data, pathParam) => {
        try {
            return await loveMireApi.post(type, data, 'json', pathParam)
        } catch (e) { throw e; }
    },

    putJson: async (type, data, pathParam) => {
        try {
            return await loveMireApi.post(type, data, 'json', pathParam, 'put')
        } catch (e) { throw e; }
    },

    postForm: async (type, formData, pathParam, responseType = "json") => {

        if (!type || !formData) {
            throw new Error ("post parameters incomplete")
        }

        try {
            let url = buildUrl(type, pathParam)

            let response = await axios.post(url, formData, {headers: buildHeaders(), responseType: responseType})

            return await getPayload(response,responseType)
        } catch (e) {
            console.log(e.response); throw e;
        }
        
    },

    // identical to postform except for axios.put (dry?)
    putForm: async (type, formData, pathParam) => {

        if (!type || !formData) {
            throw new Error ("post parameters incomplete")
        }

        try {
            let url = buildUrl(type, pathParam)

            let response = await axios.put(url, formData, {headers: buildHeaders()})

            return await getPayload(response,"json")
        } catch (e) {
            console.log(e.response); throw e;
        }
    },

    delete: async (type, responseType, pathParam) => {

        if (!type || !responseType) {
            throw new Error("post parameters incomplete")
        }

        try {
            let url = buildUrl(type, pathParam)

            let response = await axios.delete(url, {headers: buildHeaders(), responseType: responseType} )

            return await getPayload(response, responseType)

        } catch (e) {
            console.log(e)
            throw e
        }
    },

    deleteJson: async (type, data, responseType, pathParam) => {

        if (!type || !responseType) {
            throw new Error("post parameters incomplete")
        }

        try {
            let url = buildUrl(type, pathParam)

            let response = await axios.delete(url, data, {headers: buildHeaders(), responseType: responseType} )

            return await getPayload(response, responseType)

        } catch (e) {
            console.log(e)
            throw e
        }
    },

    deletePath: async (type, pathParam) => {
        try {
            return await loveMireApi.delete(type, 'json', pathParam)
        } catch (e) { throw e; }
    }
    
}

export default loveMireApi;