import styled from 'styled-components'

export const SelectButton = styled.button`
    outline: none;
    border-radius: 5px;
    border: ${props => props.theme.borders.button};
    padding: 5px;
    margin: 0px 3px;
    font-size: 0.6em;
    font-family: 'Roboto', serif;
    background-color: ${props => props.selected ? props.theme.colors.buttonSelected : props.theme.colors.button};
    color: black;


    @media (max-width: 600px) {
        font-size: 0.8em;
    }

    p {
        font-size: 1em;
        font-family: 'Roboto', serif;
        margin: 1vh;
    }

    span {
        font-size: 0.8em;
        padding: 2px;
    }

`

export const NavSelectButton = styled.div`
    outline: none;
    width: 100%;
    border-radius: 0;
    border: none;
    padding: 8px;
    font-size: 0.8em;
    font-family: 'Roboto', serif;
    background-color: white;
    box-shadow: inset 0 -2px 5px #CAB5B5;
    color: #707070;
    text-align: center;

    @media (max-width: 600px) {
        font-size: 0.8em;
    }

    p {
        font-size: 1em;
        font-family: 'Roboto', serif;
        margin: 1vh;
    }

`

export const NavSelectedButton = styled.div`
    outline: none;
    width: 80%;
    border-radius: 20px;
    border: none;
    padding: 5px;
    margin: auto;
    font-size: 0.8em;
    font-family: 'Roboto', serif;
    background-color: #CAB5B5;
    box-shadow: inset 0 2px 6px rgba(0,0,0,0.4);
    color: #707070;
    text-align: center;

    @media (max-width: 600px) {
        font-size: 0.8em;
    }

    p {
        font-size: 1em;
        font-family: 'Roboto', serif;
        margin: 1vh;
    }

`

export const StyledButtonRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledButtonRowTopFixed = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
`;

export const BigButton = styled.button`
     width: 80%;
     border-radius: 10px;
     padding: 28px 8px;
     outline: none;
     border: 1px solid black;
     margin: 5px;
     color: #CAB5B5;
     font-family: 'Roboto', serif;
     font-size: 1.7em;
     text-shadow: 1px 1px 3px #FFF;
     background-color: rgba(221, 221, 221, 0.90);
`

export const HideButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;    
    outline: none;
    border: none;
    border-radius: 25%;    
    padding:  calc(1vmax*0.9) calc(1vmax*0.8);
    background-color: rgba(87, 30, 30, 0.79);
    border: 2px #909090 solid;
    font-size: calc(1.2vmax);
    color: #C28383;
    z-index: 4;
    &:after {content: "✘";}
`;

export const EditButton = styled.button`
    position: absolute;
    outline: none;
    top: 10px;
    right: 60px;    
    border-radius: 25%;
    padding: 5px 8px;
    background-color: rgba(221, 221, 221, 0.90);
    border: 1px #86888B solid;
    font-size: 0.8em;
    color: gray;
    &:after {content: "✎";}
`;

export const SettingsButton = styled.button`
    position: absolute;
    outline: none;
    top: 10px;
    right: 60px;  
    border-radius: 25%;
    padding: 5px 8px;
    background-color: rgba(221, 221, 221, 0.90);
    border: 1px #86888B solid;
    font-size: 0.8em;
    color: gray;
    &:after {content: "s";}
`;

export const SettingsButtonFlex = styled.button`
    outline: none;
    border-radius: 5px;
    padding: 5px;
    margin: 0px 3px;
    background-color: #CAB5B5;
    font-size: 1em;
    font-family: 'Roboto', serif;
    &:after {content: "\\2699";}
`;

export const ProfileButton = styled.button`
    position: absolute;
    outline: none;
    top: 10px;
    right: 110px;    
    border-radius: 25%;
    padding: 6px 6px;
    background-color: rgba(221, 221, 221, 0.90);
    border: 1px #86888B solid;
    font-size: 0.8em;
    color: gray;
    &:after {content: "🙋";}
`;


let CA = styled.div`
    flex: 1;
    justify-content: center;
`;

let CB = styled.button`
    content: '✓';
    color: black;
    font-size: 1.2em;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    outline: none;
    border: black 1px solid;
    background-color: green;
`

export const CheckMarkButton = (props) => {

    return (<CA><CB onClick={() => props.onClick()}>✓</CB></CA>)
}

let XA = styled.div`
    flex: 1;
    justify-content: center;
`;

let XB = styled.button`
    content: 'D7';
    color: black;
    font-size: 1.2em;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    outline: none;
    border: black 1px solid;
    background-color: darkred;

`
export const XMarkButton = (props) => {
    return(<XA><XB onClick={() => props.onClick()}>X</XB></XA>)
}

let ArrowUp = styled.div`
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    
    border-bottom: 10px solid #CAB5B5;
  `;
  
let ArrowDown = styled.div`
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    cursor: pointer;
    
    border-top: 10px solid #CAB5B5;
  `;
  
let ArrowRight = styled.div`
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    
    border-left: 10px solid #CAB5B5;
`;
  
let ArrowLeft = styled.div`
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    cursor: pointer;
    
    border-right:10px solid #CAB5B5; 
`;


export const PlusButton = styled.button`
    outline: none;
    border: none;
    padding: 8px 13px;
    background-color: #e0e0e0;
    border: 2px #C28383 solid;
    height: 2em;
    font-size: 1.8em;
    color: #C28383;
    z-index: 4;
    &:after {content: "+";}
`;

export const LeftButton = styled.button`
    outline: none;
    border: none;
    vertical-align: top;
    display: inline-block;
    background-color: #e0e0e0;
    border: 1px #C28383 solid;
    padding: calc(1vmax/2) calc(1vmin*1);
    max-width: 50%;
    font-size: calc(1vmax*1.8);
    color: #C28383;
    z-index: 4;
    transform: rotate(180deg);
    &:after {content: "►";  }

    @media (max-width: 475px) {
        padding: calc(1vmax/2) calc(1vmin*2);
    }
`;

export const RightButton = styled.button`
    margin: 0;
    outline: none;
    border: none;
    vertical-align: top;
    display: inline-block;
    background-color: #e0e0e0;
    border: 1px #C28383 solid;
    padding: calc(1vmax/2) calc(1vmin*1);    
    max-width: 50%;
    font-size: calc(1vmax*1.8);
    color: #C28383;
    z-index: 4;
    &:after {content: "►";}

    @media (max-width: 475px) {
        padding: calc(1vmax/2) calc(1vmin*2);
    }
`;

export const RightArrowButton = (props) => {
    return <button onClick={(e) => props.onClick(e)}><ArrowRight /></button>
}

export const DownArrowButton = (props) => {
    return <button  style={{border: "none", background: "none"}}
         onClick={(e) => props.onClick(e)}><ArrowDown /></button>
}

export const LeftArrowButton = (props) => {
    return <button style={{border: "none", background: "none"}}
        onClick={(e) => props.onClick(e)}><ArrowLeft /></button>
}

export const HideBar = (props) => <div style={{width: '100%',
    height: '50px', position: 'relative', flex: "0 0 100%"}}>
    <HideButton onClick={props.onClick} />
</div>