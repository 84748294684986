import styled, { keyframes } from 'styled-components'
import FlirvySpinnerPng from '../../images/flirvy-spinner.png'

export const StyledMediaImg = styled.img`
    width: ${props => props.dimensions[0]}px;
    height: ${props => props.dimensions[1]}px;;
    padding: 0;
    margin: 0;
    max-width: ${props => props.dimensions[0]}px;
    max-height: ${props => props.dimensions[1]}px;
    object-fit: cover;
    user-drag: none;
`

export const StyledLoadingImg = styled.div`
    width: ${props => props.dimensions[0]}px;
    height: ${props => props.dimensions[0]}px;
    padding: 0;
    margin: 0;
    max-width: ${props => props.dimensions[0]}px;
    max-height: ${props => props.dimensions[1]}px;
    object-fit: cover;
    user-drag: none;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const StyledFlirvySpinner = styled.div`

  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  z-index: 99;

  position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

  border-top: 2px solid ${props => props.theme.colors.primary};
  border-right: 2px solid ${props => props.theme.colors.primary};
  border-bottom: 2px solid ${props => props.theme.colors.primary};
  border-left: 4px solid ${props => props.theme.colors.primary};
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;

`

export const MediaImg = (props) => {

    if (props.src) {
        return <StyledMediaImg {...props} />
    } else {
        return <StyledLoadingImg {...props}><Spinner /></StyledLoadingImg>
    }
}

export const FlirvySpinner = (props) => {
  return <StyledFlirvySpinner>
    <img style={{width: '24px', height: '24px'}} src={FlirvySpinnerPng} />
    </StyledFlirvySpinner>
}