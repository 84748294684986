import styled from 'styled-components'

export const ModalOuter = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    align-items: center;
    justify-content: center;
    z-index: 10;
    margin: auto;
    background: rgb(255,255,255,.8);
    text-overflow: "-";
`;

export const ModalInner = styled.div`
    position: fixed;
    display: inline-block;
    z-index: 11;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    padding: 0;
    background: white;
    border-radius: 1%;
    border: 1px solid black;
    overflow-x: hidden;
    overflow-y: auto;

    @media (max-width: 400px) {
        max-width: 95%;
    }
`;

const FullModal = (props) => <ModalOuter onClick={() => props.onClick()}>
    <ModalInner onClick={(e) => e.stopPropagation()}>
        {props.children}
    </ModalInner>
</ModalOuter>

export default FullModal