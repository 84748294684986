import { props } from 'bluebird';
import React from 'react'
import styled from 'styled-components'

/**
 * Covers entire parent with "Loading..." message
 */
export const Cover = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(255,255,255,0.7);
    color: black;
    &:after {content: "Loading...";}
    justify-content: center;
`;

const Cs = (props) => {

    //return <div style={{position: 'relative'}}>{props.children}</div>
    return (
        <div style={{position: 'relative'}}>            
            {props.children}
            {props && props.show && <Cover />}
        </div>
    )
}

export default Cs
