import React, {useState, useEffect, useReducer} from 'react'
import styled from 'styled-components'
import { RadioSelection, SelectButton } from 'lovemire-components'

import { MainWrapper } from '../Main/Main'

import GenderSelection from './newUserWizard/GenderSelection'
import NameSelection from './newUserWizard/NameSelection'
import ImageSelection from './newUserWizard/ImageSelection'
import DescriptionSelection from './newUserWizard/DescriptionSelection'
import Welcome from './newUserWizard/Welcome'

import WithProfileManagement from './newUserWizard/WithProfileManagement'
import loveMireApi from '../lib/loveMireApi'

export const NewUserWizardWrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: center;
`

NewUserWizardWrapper.Col = styled.div`
  display:flex;
  flex-direction: column;
`

const NewUserWizard = (props) => {

    const [profile, setProfile] = useReducer((prev,update) => {
        return {...prev,...update}
    },{})

    useEffect( () => {
        console.log(props)
        setProfile(props.profile)
    },[props])

    useEffect( () => {
        console.log(profile)
    },[profile])

    /**
     * Save Profile
     */
    const doFinished = () => {

        console.log("Saving...", profile)

        let submitProfile = {...profile}

        loveMireApi.postJson('myProfile', submitProfile)
            .then ( response => {
                console.log("Successful Profile update: ", response)
                setProfile(response)
            })
            .catch ( e => { console.log(e.message); /*do verification checks */})

    }

    //Slides
    const NameComponent = () => WithProfileManagement(NameSelection, 
        {profile:profile, autoSlide: false,
            onChange: (p) => setProfile(p), 
            pReducer: ({title,name}) => ({title,name}), 
            finishedTest: (p) => (p.title && p.title.length !== 0 && p.title !== p.name)} )

    const GenderComponent = () => WithProfileManagement(GenderSelection,
        {profile: profile, autoSlide: false,
            onChange: (p) => setProfile(p),
            pReducer: ({gender, interestedIn, birthday}) => ({gender, interestedIn, birthday}),
            finishedTest: (p) => (p.gender > -1 && p.interestedIn > -1 && p.birthday)} )

    const ImageComponent = () => WithProfileManagement(ImageSelection,
        { profile: profile, autoSlide: false,
            onChange: (p) => setProfile(p),
            pReducer: ({profileImageId, profileImageMediaId}) => ({profileImageId, profileImageMediaId}),
            finishedTest: (p) => (p.profileImageId && p.profileImageMediaId)} )

    const DescriptionComponent = () => WithProfileManagement(DescriptionSelection,
        { profile: profile, autoSlide: false,
            onChange: (p) => setProfile(p),
            pReducer: ({aboutMe, lookingFor, addressString}) => ({aboutMe, lookingFor, addressString}),
            finishedTest: (p) => true})


    const pagePicker = () => {

        return (profile.gender === -1 || profile.interestedIn === -1) ?
                <GenderComponent />:
            (!profile.title || profile.title.length === 0 || profile.title === profile.name) ?
                <NameComponent /> :
            (!profile.profileImageId) ?
                <ImageComponent /> :
            (!profile.aboutMe || !profile.lookingFor || !profile.addressString) ?
                <DescriptionComponent /> :
                <Welcome startOver={ () => setProfile(props.profile) }
                    doFinished={ () => doFinished() } />
    }

    return <MainWrapper>
            <NewUserWizardWrapper>
                <NewUserWizardWrapper.Col>
                    <h1>Welcome to Flirvy! (Project: Lovemire)</h1>
                    {pagePicker()}
                </NewUserWizardWrapper.Col>
            </NewUserWizardWrapper>
        </MainWrapper>
}

export default NewUserWizard