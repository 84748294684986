import React, {useState, useEffect } from 'react';
import {getAll, getAllGifts, receiveAllGifts, markSeenFromUser} from '../lib/NotificationsApi'
import PropTypes from 'prop-types';
import NotificationLine from './components/NotificationLine';
import NotificationTypes from './data/NotificationTypes'
import ProfileBar from '../Profile/ProfileBar';
import { ScrollCard } from '../styles/Cards';
import { FeedWrapper, NotificationWrapper } from '../styles/matches/Wrappers';
import FullModal from '../styles/Modals';
import PostMediaWithFind from './components/PostMediaWithFind'
import {HideButton, SelectButton} from '../styles/Buttons'
import {NewMessagesDiv, NewChatMessagesDiv, ProfileNewMessagesDiv, ProfileNewChatMessagesDiv} from '../styles/Overlays'
import ChatApp from '../Chat/App'
import ConnectionChoice from './components/ConnectionChoice'
import loveMireApi from '../lib/loveMireApi';
import { MatchesContext, DataContext, StateContext } from '../App';

/**
 * Unfotunately, going to need a complete rewrite for a lot of reasons.
 * 
 * For some users, the notifications would get large. We only need a list of users to render the MyMatches Page.
 * 
 * The notifications and chat would then be handled by the component that was expanded. Can probably use the
 * matches object and store the notifications the same way. But instead of loading them all at the start, just
 * load each individual user.
 * 
 * @param {obj} props 
 * @returns 
 */
const Mm = (props) => {

    const matches = React.useContext(MatchesContext)
    const {profiles, connectionsList, onlineUsers, myMatches} = React.useContext(StateContext)
    const [account,appSettings] = React.useContext(DataContext)

    const [notifications, setNotifications] = useState([])
    const [hiarchyNotifications, setHiarchyNotifications] = useState({})
    //const [connectionsList, setConnectionsList] = useState([])
    const [giftsWaiting, setGiftsWaiting] = useState([])  // Array of users that have sent a gift and not yet claimed
    const [contentType, setContentType] = useState({})  // If user exists in this object, uses string value to deliver content

    const [numNewMessagesPerUser, setNewMessagesPerUser] = useState({})

    // post modal state
    const [showPostMediaModal, setShowPostMediaModal] = useState(true)
    const [modalMediaId, setModalMediaId] = useState(null)
    const [modalPostId, setModalPostId] = useState(null)


    /**
     * Returns array of arrays sorted under each match
     * 
     * *** moved to matches and flirt
     * 
     * @param {string} match 
     */
    const notificationsSortedByMatch = (match) => {

        /*if (hiarchyNotifications && match in hiarchyNotifications) {
            console.log( hiarchyNotifications[match]['168'].map(e => e.created),
            Object.values(hiarchyNotifications[match]).sort ( (a,b) => {
                return a.map(e => e.created).reduce( (ea,eb) => ea > eb ? ea : eb) >
                 b.map(ee => ee.created).reduce ( (eea,eeb) => eea > eeb ? eea : eeb)
            }))}*/
        if (!!hiarchyNotifications && match in hiarchyNotifications) {
            return Object.values(hiarchyNotifications[match]).sort ( (a,b) => {
                let aDate = a.map(e => Date.parse(e.created)).reduce( (ea,eb) => ea > eb ? ea : eb)
                let bDate = b.map(ee => Date.parse(ee.created)).reduce ( (eea,eeb) => eea > eeb ? eea : eeb)
                
                return bDate - aDate
            })
        } else return {}
    }

    const findUser = (match) => !!matches.findUser ? matches.findUser(match) : {title: "not found"}

    /**
     * Makes an api call to pay service to check for unclaimed gifts
     * Not to be confused with "receiveGifts" that actually claims them
     */
    const collectGifts = () => {
        getAllGifts()
            .then ( response => {
                let giftsWaitingSet = new Set()
                response.map( gift => {
                    giftsWaitingSet.add(gift.owner)
                })
                setGiftsWaiting(Array.from(giftsWaitingSet))
            })
            .catch(e => appSettings.error(e))
    }

    /**
     * Receives all gifts for the user from {user}
     * 
     * @param {strong} user 
     */
    const receiveGifts = (user) => {

        receiveAllGifts(user)
            .then ( response => {
                console.log(response)
                collectGifts()
                matches.apiGetAccount()
            })
            .catch(e => appSettings.error(e))
    }

    /**
     * constructNotification and constructProfileHead
     * 
     * These are not really used anymore except for gifts, which need a new
     * mechanism.
     * 
     * @param {*} user 
     * @returns 
     */
    const constructNotificationChildren = (user) => {
        
        let children = []

        // last online
        if (giftsWaiting.includes(user)) {
            children.push(<SelectButton key="gifts" onClick={() => receiveGifts(user)}>
                Claim Gift!</SelectButton>)
        }

        /*  deprecated. Was Message button to connections
        if (matches.findMatches(user, myMatches) === "CONNECTION") {
            children.push(<SelectButton 
                key={`chat-${user}`}
                onClick={() => setContentType( prev => {
                    let newContent = {...prev}
                    newContent[user] = "CHAT"
                    return newContent
                })}
                >Message!</SelectButton>)
        }*/

        //let unseenNotifications = getNotificationCount(user)

        //if (Object.keys(numNewMessagesPerUser).includes(user)) {
        //    children.push([<ProfileNewChatMessagesDiv key={`chat-${user}`}><p>{numNewMessagesPerUser[user]}</p></ProfileNewChatMessagesDiv>])
        //}

        //if ( unseenNotifications > 0) {
        //    children.push([<ProfileNewMessagesDiv key={`waiting-${user}`}><p>{`${unseenNotifications}`}</p></ProfileNewMessagesDiv>])
        //}
        
        return [<>
            {children}
        </>]
    }

    /**
     * Likely to remain unused. Logic moved to profile bar.
     * No need to show notifications only on matches
     * @param {*} user 
     * @returns 
     */
    const constructProfileHeadChildren = (user) => {

        let children = []

        /* notifications moved to profile bar

        TODO: Ultimately, this logic should be moved to ProfileBar so notifications will be seen on feed as well as matches

        let unseenNotifications = getNotificationCount(user)
        if ( unseenNotifications > 0) {
            children.push([<NewMessagesDiv key={`waiting-${user}`}><p>{`${unseenNotifications}`}</p></NewMessagesDiv>])
        }

        if (Object.keys(numNewMessagesPerUser).includes(user)) {
            children.push([<NewChatMessagesDiv key={`chat-${user}`}><p>{numNewMessagesPerUser[user]}</p></NewChatMessagesDiv>])
        }
        */

        return [<>
            {children}
        </>]
    }

    /**
     * Returns an integer with number of notifications unseen.
     * 
     * ** has been moved to matches.js
     * 
     * match - hiarchyNotifications[match]
     * visible - whether to show only visible notifications (likeType.visible)
     * @param {string} match 
     * @param {boolean} visible 
     */
    const getNotificationCount = (match, visible = true, useConnectionsList = true) => {
        let count = 0;

        if (useConnectionsList) {

            count = connectionsList.find( e => e.name === match).unseen

        } else {
            // deprecated (useConnectionsList set to false)
            if (!!hiarchyNotifications && !!hiarchyNotifications[match]) {
            const type = (t) => Object.values(NotificationTypes).find(e => e.name === t)

            Object.values(hiarchyNotifications[match]).forEach( list =>
            count += list.reduce ( (a,notification) => {
                if(!notification.seen && 
                    notification.fromId === match && 
                    (!visible || type(notification.type).visible===visible)) {
                    return 1        // returns 1 or 0 for each reference id
                } else return a
            },0))
            }
        }

        return count;
    }

    /**
     * Gets list of new messages waiting from chat.
     * 
     * ** has been moved to matches
     * 
     */
    const getUnseenMessagesPerUser = () => {

        if(connectionsList.length > 0 ) {
            let userList = connectionsList
                .filter( e => matches.findMatches(e.name, myMatches) === "CONNECTION")
                .map(e => e.name)
                .join(",")
            console.log(myMatches, userList)
            loveMireApi.getJson("getUnseenMessagesPerUser", userList)
                .then ( response => {
                    setNewMessagesPerUser(response)
                })
            .catch(e => appSettings.error(e))
        }

    }


    /**
     * USE EFFECTS
     */

    useEffect( () => {

        //matches.registerConnectionsCallBack ( (r) => setMyMatches(r), "MyMatches" )
        
        // handle setting Matches callbacks
        
        /*
         *** moved to matches and context
         *** now calling the api methods in matches just tells it to refresh
         *** the data. No longer stored in each component's state
         *** 
        
        matches.registerNotificationsCallBack ( (r, l) => {
            //setHiarchyNotifications( prev => {
            //    let newNot = {...r}
            //    return newNot
            //})
            setConnectionsList( prev => {
                let newList = [...l]
                return newList
            })
        }, "MyMatches") */

        // online users
        // moved to context
        //matches.registerOnlineCallBack ( (r) => setOnlineUsers(r), "MyMatches")
        

        /*getAll()
            .then(response => {
                setNotifications(response)
            })  */
        
        // with 

        //matches.apiGetNotifications() // too big, should work
        matches.apiGetNotificationsList()
            
        return () => {
            //matches.unRegisterConnectionsCallBack("MyMatches")
            //matches.unRegisterNotificationsCallBack("MyMatches")
        }
         
    }, [])

    useEffect( () => {

        console.log("%c Connections List: ", "color: purple", connectionsList)

        // ran after module is loaded and api finished getting notifications for user
        matches.setProfileListFromNotificationList(notifications,true)
        matches.apiGetProfiles()

        //setHiarchyNotifications(arrangeNotifications(notifications))

        // Update connection information for passing to profile bar (and later for gifts and such)
        matches.apiGetConnections()
        
        //console.log("hiarchy notifications updated", hiarchyNotifications)

    }, [connectionsList])

    /**
     * Update notifications.
     * Once myMatches is updated, main apis are done
     * updated by matches.apiGetConnections in useEffect([notifications])
     */
    useEffect( () => {

        // Collect Gifts from Pay server (may not line up with notifications if user never collects)
        collectGifts()

        // Collect new messages waiting from chat server
        matches.apiGetNewMessagesList()

    }, [myMatches])

    /**
     * When stateContext.profiles is updated, need a fresh online users list
     */
    useEffect( () => {
        
        console.log("Profiles updated, getting online users")
        matches.apiGetOnlineUsers()

    }, [profiles])

    useEffect( () => {
        console.log("Online Users: ", onlineUsers)
    },[onlineUsers])

    /**
     * Start RENDER
     */
    //console.log(matches.connections)
    //if (!!hiarchyNotifications && hiarchyNotifications["Melani"]) {
    //    console.log(notificationsSortedByMatch("Melani"), Object.values(hiarchyNotifications["Melani"]))
    //}
    if ( hiarchyNotifications.length <  1 || connectionsList.length < 1) {
        return(<div>Working on it...</div>)
    } else {
        return (
            <FeedWrapper>
                {showPostMediaModal && (modalMediaId || modalPostId) &&
                    <FullModal onClick={() => {setShowPostMediaModal(false);}}>
                        <PostMediaWithFind 
                            postId={modalPostId}
                            mediaId={modalMediaId}/>
                        <HideButton onClick={() => setShowPostMediaModal(false)} />
                    </FullModal>}
                <div style={{width: '100%', display: 'relative'}}>
                {connectionsList.map( (match, i) => 
                    { if (!!findUser(match.name)) {
                        return <ProfileBar key={`${match.name + '-' + i}`}
                                expandable /* Shows arrow */
                                expandToProfile /* Arrow expands to profile, not content */
                                profile={findUser(match.name)}
                                dimensions={[]}
                                defaultSelection="flirt"
                                match={ (({...m}) => ({...m, 
                                    newMessages: numNewMessagesPerUser[match.name] ? numNewMessagesPerUser[match.name] : 0}))(match)}

                                notificationChildren={constructNotificationChildren(match.name)}
                                profileHeadChildren={constructProfileHeadChildren(match.name)}
                                onMediaClick={(id) => {setShowPostMediaModal(true); setModalPostId(id);}}                                  
                                 
                                myMatches={matches.findMatches(match.name, myMatches)}
                                profiles={profiles}>
                            {/*props.matches.findMatches(match, myMatches) === "CONNECTION" ? 
                            <ConnectionChoice 
                                interactionsChildren={ <ScrollCard> {notificationsSortedByMatch(match).map( e => <NotificationLine
                                    key={e[0].referenceId + e.length}
                                    onMediaClick={(id) => {setShowPostMediaModal(true); setModalMediaId(id);}}                              
                                    showThumb 
                                    referenceId={match} 
                                    events={e} 
                                    user={props.user}
                                    matchUser={findUser(match)}>
                                        {e.type}                                
                                    </NotificationLine>)}</ScrollCard>}
                                chatChildren={<ChatApp user={props.matches.getAccount()}
                                    profiles={props.matches.getProfiles()}
                                    matches={props.matches}
                                    singleThread={match} /> }/>
                            : notificationsSortedByMatch(match).map( e => <NotificationLine
                                key={e[0].referenceId + e.length}
                                onMediaClick={(id) => {setShowPostMediaModal(true); setModalMediaId(id);}}                              
                                showThumb 
                                referenceId={match} 
                                events={e} 
                                user={props.user}
                                matchUser={findUser(match)}>
                                    {e.type}                                
                        </NotificationLine>)/*}
                            {/*(!contentType[match] || contentType[match] === "NOTIFICATIONS") && <ScrollCard>
                           {/*Object.entries(hiarchyNotifications[match]).map( ([k,n]) => 
                                <NotificationLine 
                                    key={k}
                                    onMediaClick={(id) => {setShowPostMediaModal(true); setModalMediaId(id);}}                              
                                    showThumb 
                                    referenceId={k} 
                                    events={n} 
                                    user={props.user}
                                    matchUser={findUser(match)}>
                                        {n.type}
                           </NotificationLine>)*/}
                            {/*notificationsSortedByMatch(match).map( e => <NotificationLine
                                    key={e[0].referenceId + e.length}
                                    onMediaClick={(id) => {setShowPostMediaModal(true); setModalMediaId(id);}}                              
                                    showThumb 
                                    referenceId={match} 
                                    events={e} 
                                    user={props.user}
                                    matchUser={findUser(match)}>
                                        {e.type}                                
                            </NotificationLine>)*/}
                            
                            {/*</ScrollCard>*/}
                            
                            {contentType[match] && contentType[match] === "CHAT" && 
                                <ChatApp user={matches.getAccount()}
                                    profiles={matches.getProfiles()}
                                    matches={matches}
                                    singleThread={match} />}
                        </ProfileBar>
                        }
                    else {
                        //console.log("USER NOT FOUND: ", match)
                        return <NotificationWrapper key={`${match.name + '-' + i}`}>Loading...</NotificationWrapper>
                    }
                    })}
                </div>
            </FeedWrapper>
        )
    }
}

Mm.propTypes = {
}

export default Mm;