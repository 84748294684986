import React, {useState, useEffect } from 'react';
import styled from 'styled-components'
import { toHowOld } from '../../lib/TextLib';
import PropTypes from 'prop-types';

const GreenDot = styled.div`
    height: 10px;
    width: 10px;
    background-color: #159419;
    border-radius: 50%;
    margin: auto 1em;
`

const LastOnlineStyle = styled.div`
    color: #707070;
    font-size: 0.8em;
    margin: auto 1em;

`

const Os = (props) => {

    if(props.onlineNow) {
        return <GreenDot />
    }

    if (props.showLastOnline && props.lastOnline) {
        return <LastOnlineStyle>{props.lastOnline ? "Online " + toHowOld(props.lastOnline) : ""}</LastOnlineStyle>
    }

    return <></>
}

export default Os;

Os.propTypes = {
    showLastOnline: PropTypes.bool
}