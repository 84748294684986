import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Field, SelectButton } from 'lovemire-components'

import { NewUserWizardWrapper } from '../NewUserWizard'

const NameSelection = (props) => {

    return <NewUserWizardWrapper>
        <NewUserWizardWrapper.Col>
            <h2>Your first name:  </h2>
            <Field select={(e) => props.onChange({title: e})} 
                value={props.profile.title === props.profile.name ? "" : props.profile.title } />
            {props.finished && <SelectButton
                selected={true}
                onClick={() => {
                    props.doFinished()
                }}
            >Ok!</SelectButton> }
        </NewUserWizardWrapper.Col>
    </NewUserWizardWrapper>


}

export default NameSelection