import styled from 'styled-components'

export const SquareThumbnail = styled.img`
    width: ${props => props.width ? props.width : '80'}px;
    height: ${props => props.height ? props.height : '80'}px;
    margin: 0;
    padding: 0;
    object-fit: fill;
    object-position: 50% 50%;
    display: inline-block;
    vertical-align: top;
`;