import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import stackBlurImage from '../../../lib/StackBlurImage'
import Cropper from '../../editpost/CropImage'
import styled from 'styled-components'
import { StyledButtonRow, SelectButton, EditButton, HideButton } from '../../../styles/Buttons'
import { SquareThumbnail } from '../../../styles/Image'
import {StyledFlexRow} from '../../../styles/RelativeWrapper'
import {StyledFineP} from '../../../styles/Text'
import Slider from '@material-ui/core/Slider'

const CropWrapper = styled.div`
    width: ${props => props.width ? props.width : '200'}px;
    position: relative;
    margin: 0 auto;
`;

const Pimage = (props) => {

    const [isUsingCanvas, setUsingCanvas] = useState(true)
    const [isEditingPreview, setEditingPreview] = useState(false)
    const previewCanvas = useRef(null)
    const [blurValue, setBlurValue] = useState(10);

    const getAspect = (which) => {
        let base = props.aspect > 1 ? 150 : 200
        if (which === 'w' || which === 'width') {
            return Math.round(base*props.aspect)
        } else return base
    }

    const getCanvasImage = (ref) => {

        console.log(ref)
        if (!!ref) {

            let context = ref.current.getContext("2d")
            //return context.getImageData(0,0,Math.round(200*props.aspect), 200)
            return ref.current.toDataURL("image/png")
        }
    }

    const blurCanvasImage = () => {
        if (props.mediaData && previewCanvas) {
            stackBlurImage(props.mediaData,previewCanvas.current, blurValue, getAspect('w'), getAspect('h'))
        }
    }

    const saveHidden = (o) => {
        if (isUsingCanvas) {
            props.update(getCanvasImage(previewCanvas))
            setEditingPreview(false)
        } else if (o) {
            props.update(o)
            setEditingPreview(false)
        } else {
            props.matches.error("Tried to update preivew image without an image.")
        }
    }

    /*
    *  On load, we always need to blur the mediaData and update it
    */
    useEffect ( () => {
        console.log("Preview Image loaded:")
        if (isEditingPreview && isUsingCanvas) blurCanvasImage() 
        if (!props.previewData || props.previewData == null) {
            setEditingPreview(true)
            setUsingCanvas(true)


        }
    }, [])

    useEffect( () => {
        console.log("PreviewImage: Received new props")
    }, [props])

    useEffect ( () => {
        console.log("isediting useeffect")
       
        if (isEditingPreview && isUsingCanvas) {
            blurCanvasImage() 
             /* This causes blank to be saved
            props.update(getCanvasImage(previewCanvas))
            */
            if (props.previewData === null) {
                // If preview Data is null, this is a new secret
                //console.log("PreviewData is null")
                setTimeout( () => saveHidden(), 500);
            }
        }
    }, [isUsingCanvas, isEditingPreview, blurValue])

    return (
        <>
            {!isEditingPreview && <>
                <p>Preview / Teaser</p>
                <StyledFlexRow>
                    <div>
                        <EditButton onClick={() => setEditingPreview(true)} />
                        <img src={props.previewData} />
                    </div>
                </StyledFlexRow>
                </>}
            {isEditingPreview && <>
            <StyledButtonRow>
                <SelectButton selected={isUsingCanvas} onClick={() => setUsingCanvas(true)}>Blur</SelectButton>
                <SelectButton selected={!isUsingCanvas} onClick={() => setUsingCanvas(false)}>Custom</SelectButton>
            </StyledButtonRow>
            <div>            
            {isUsingCanvas && <><canvas ref={previewCanvas} 
                style={{width: `${getAspect('w')}px`, height: `${getAspect('h')}px`}} />
                <Slider 
                    value={blurValue}
                    min={0} max={35} step={1}
                    onChange={ (e, b) => setBlurValue(b) } />
                <SelectButton onClick={saveHidden}>Save Secret Info</SelectButton></>
                }
                </div>
            {!isUsingCanvas && <CropWrapper>
                <Cropper aspect={Number(props.aspect)}
                    mediaData={props.previewData}
                    saveMessage={'Save Secret Info'}
                    onMediaLoaded= { (e) => console.log("Preview Media Loaded: ", e)}
                    updateMedia={(o) => saveHidden(o)} /> 
                </CropWrapper>}
            
            </>}
        </>
    )

}

Pimage.propTypes = {
    update: PropTypes.func.isRequired,
    mediaData: PropTypes.object.isRequired,
    aspect: PropTypes.number.isRequired
}

export default Pimage;